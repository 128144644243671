import { axios } from "../../../lib/axios";

export const deactiveCategorys = async (categoryId: any) => {
  try {
    const formData = new FormData();
    formData.append("status", "deactivated");
    // Assuming categoryData has other fields you want to append

    const response = await axios.patch(`/category/${categoryId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    // Handle error here
    throw new Error("Error deactivating category: " + error);
  }
};
