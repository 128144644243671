import React from "react";
import Home from "../components/notificationCard";

export const NotificationWrapper = () => {
  return (
    <div className="m-4 flex">
      {/* <div className="block w-full p-4 sm:flex h-screen mb-4">
        <div className="sm:w-3/4 h-full w-full sm:mt-0">
          <div>3/4</div>
        </div>
        <div className="sm:w-1/4 w-full rounded-md mr-4 h-[calc(100%+1rem)]">
          <div className="h-full">
            <div>1/4</div>
          </div>
        </div>
      </div> */}
      <Home />
    </div>
  );
};
