import { axios } from "../../../lib/axios";
import { AdminResponse, RoleResponse } from "../types/adminsType";

export const GetAdmins = async (filter?: any): Promise<AdminResponse> => {
  try {
    const roleResponse = await axios.get<RoleResponse>("/role");
    const userRole = roleResponse.data.array.find(
      (role: any) => role.roleName === "admin"
    );
    // console.log(userRole);

    if (userRole) {
      let url = `/user?roleId=${userRole.id}`;

      const response = await axios.get<AdminResponse>(url);
      // console.log(response);

      return response.data;
    } else {
      throw new Error("Admin role not found");
    }
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Error fetching users: " + error);
  }
};
