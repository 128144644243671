import { useParams } from "react-router-dom";
import { CategorysProfile } from "../components/CategorysProfile";
import { CategorysTypes } from "../components/CategorysTypes";
import { CategorysTransaction } from "../components/CategorysTransaction";
import { useState } from "react";

export const SingleCategoryWrapper = () => {
  const { CategorysId } = useParams();
  const [isCategoryExits, setIsCategoryExits] = useState(false);

  return (
    <div className="gb-color2">
      <CategorysProfile
        CategorysId={CategorysId}
        isCategoryExits={isCategoryExits}
        setIsCategoryExits={setIsCategoryExits}
      />
      {isCategoryExits ? (
        <div>
          <CategorysTypes CategorysId={CategorysId} />
        </div>
      ) : (
        ""
      )}
      {/* <CategorysTransaction CategorysId={CategorysId} /> */}
    </div>
  );
};
