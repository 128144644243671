import { axios } from "../../../lib/axios";

export const AcceptUser = async (id: any): Promise<any> => {
  const data = {
    status: "active",
  };
  const response = await axios.patch(`User/Status/${id}`, data);

  return response.data;
};
