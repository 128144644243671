import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

import { getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { axios } from "../lib/axios";
import storage from "../utils/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAKH4vbMUV9oToo_IYlIdXBSaiVyo7DgzM",
  authDomain: "al-thiqa.firebaseapp.com",
  projectId: "al-thiqa",
  storageBucket: "al-thiqa.appspot.com",
  messagingSenderId: "88455603708",
  appId: "1:88455603708:web:ed0743f971082276947e79",
  measurementId: "G-VZ544KN3KN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export const getTokens = async () => {
  // console.log(app);
  // console.log(messaging);

  function requestPermission() {
    // console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // console.log("Notification permission granted.");
      } else {
        // console.log("noo");
      }
    });
  }
  requestPermission();
  // Register the service worker from the public directory
  // const registration = await navigator.serviceWorker.register(
  //   "/firebase-messaging-sw.js"
  // );
  // console.log(registration);

  const token = await getToken(messaging, {
    vapidKey:
      "BA_P4V_A5QkA2Oe7g7YLiGKWdx3wNcp8abfsCOEpOjnoIYzWAg9fbozaEk0gCVQhGeRchdtIbJpf50tJUJmXCEY",
  })
    .then(async (currentToken) => {
      // console.log("currentToken", currentToken);
      let firebaseTempToken = storage.getFirebaseToken();

      if (firebaseTempToken != currentToken) {
        storage.setFirebaseToken(currentToken);

        if (currentToken) {
          // console.log("currentTokencurrentTokencurrentToken", currentToken);
          const response = await axios.post("/notifications/register", {
            notificationToken: currentToken,
          });
          // console.log(response);

          if (response) {
            return currentToken;
          }
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          return null;
        }
      }
    })
    .catch((err) => {
      console.error(err);
    });
  // console.log("sajfdlkds;fjf", token);

  return token;
};

export const onMessageListener = (callback: (payload: any) => void) => {
  return onMessage(messaging, (payload) => {
    console.log("Message received: ", payload);
    callback(payload);
  });
};
