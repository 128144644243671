import { Avatar, Button, Input, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { GetDashboardProfileInfo } from "../api/GetDashboardProfileInfo";
import { Loading } from "../../../components/elements/loading";
import { NotificationPlacement } from "antd/es/notification/interface";
import { Card } from "@mui/material";
import { GetTransactionsType } from "../../transactions/api/getTransactionsType";
import { EditTransferFee } from "../api/EditTransferFee";
import { GetTransferTransaction } from "../api/GetTransferTransaction";

export const DashboardProfileHeader = () => {
  const [api, contextHolder] = notification.useNotification();
  const [TaxInput, setTaxInput] = useState(false);
  const [TaxValue, setTaxValue] = useState<any>(0);
  const [NewTaxValue, setNewTaxValue] = useState<any>(0);
  const [UserData, setUserData] = useState<any>();
  const [transferId, setTransferId] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const [transactionType, setTransactionType] = useState([]);
  const [IsFeeSubmitting, setIsFeeSubmitting] = useState<boolean>(false);
  const [dashboardTransactionFees, setDashboardTransactionFees] =
    useState<any>();
  const colors = [
    "#FC726E",
    "#4BC190",
    "#3D5AFE",
    "#FFB300",
    "#8E24AA",
    "#00ACC1",
    "#FF6F00",
    "#FFD600",
    "#43A047",
  ];

  const openEditNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم التعديل بنجاح",
      description: "تم التعديل بنجاح",
      placement,
      duration: 3,
    });
  };

  const fetchUserData = async () => {
    const response = await GetDashboardProfileInfo();
    if (response) {
      setUserData(response);
      setIsLoading(false);
    }
  };

  const showChangeTaxInput = async () => {
    fetchTransactionsType();
    setTaxInput(true);
  };
  const handleCancelClick = async () => {
    fetchTransactionsType();
    setTaxInput(false);
  };
  const fetchTransactionsType = async () => {
    try {
      const response = await GetTransactionsType();
      // console.log(response);

      response.array.forEach((element: any) => {
        if (element.type === "transfer") {
          setTaxValue(element.tax);
          setTransferId(element.id);
          setNewTaxValue(element.tax);
        }
      });

      setTransactionType(response.array);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    }
  };
  const handleSaveClick = async () => {
    setIsFeeSubmitting(true);
    try {
      // console.log(NewTaxValue);
      const response = await EditTransferFee(transferId, NewTaxValue);
      if (response) {
        fetchUserData();
        fetchTransactionsType();
        setTaxInput(false);
        openEditNotification("topRight");
      }
    } catch (error) {
      console.error("Error editing transfer fee:", error);
      // Optionally handle error states or notifications here
    } finally {
      setIsFeeSubmitting(false);
    }
  };
  const fetchTransferTransaction = async () => {
    const response = await GetTransferTransaction();
    let total = 0;
    response.map((item: any, index: number) => {
      console.log(
        "hi item",
        parseFloat(item.tax),
        item.amount,
        parseFloat(item.tax) * item.amount
      );
      total = total + parseFloat(item.tax) * item.amount;
    });
    setDashboardTransactionFees(total);
  };
  useEffect(() => {
    fetchUserData();
    fetchTransactionsType();
    fetchTransferTransaction();
  }, []);

  // console.log(transactionType);

  const handleTaxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    // Regular expression to match only numbers and decimal values
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      // If the input value is not numeric or decimal, don't update the state
      return;
    }

    setTaxValue(inputValue);
    setNewTaxValue(inputValue);
  };

  const linearGradientId = `linear-gradient-${Math.random()}`;
  const patternId = `pattern-${Math.random()}`;
  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <Modal
        // title="اضافة مبلغ للمستخدم"
        // open={isModalOpen}
        // onCancel={handleCancel}
        footer={null}
        destroyOnClose>
        <div className="text-lg mb-4">اضافة مبلغ للمستخدم</div>
        {/* <form onSubmit={handleSubmit} className="mt-10">
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-2">قيمة المبلغ</div>
              </div>
              <Input
                value={formData.price}
                onChange={handleChange}
                name="price"
                // placeholder="اسم النوع"
                className="w-full"
              />
            </div>
            {errors.price && (
              <div className="text-red-500 mb-4 text-sm">{errors.price}</div>
            )}
          </div>
          <div className="flex justify-end">
            <Button htmlType="submit" className="text-color1">
              اضافة
            </Button>
          </div>
        </form> */}
      </Modal>

      <div className="w-full p-4 rounded-t-md">
        <Card className="bg-[white] rounded-t-md">
          <div className={`flex items-center flex-col relative`}>
            <svg
              className="w-full opacity-70 rounded-t-md"
              xmlns="http://www.w3.org/2000/svg">
              <rect fill="#ffffff"></rect>
              <defs>
                <linearGradient
                  id={linearGradientId}
                  gradientUnits="userSpaceOnUse"
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="100%"
                  gradientTransform="rotate(222,648,379)">
                  <stop offset="0" stopColor="#ffffff"></stop>
                  <stop
                    offset="1"
                    stopColor={colors[UserData.id % colors.length]}></stop>
                </linearGradient>
                <pattern
                  patternUnits="userSpaceOnUse"
                  id={patternId}
                  width="300"
                  height="250"
                  x="0"
                  y="0"
                  viewBox="0 0 1080 900">
                  <g fillOpacity="0.5">
                    <polygon
                      fill="#444"
                      points="90 150 0 300 180 300"></polygon>
                    <polygon points="90 150 180 0 0 0"></polygon>
                    <polygon fill="#AAA" points="270 150 360 0 180 0"></polygon>
                    <polygon
                      fill="#DDD"
                      points="450 150 360 300 540 300"></polygon>
                    <polygon fill="#999" points="450 150 540 0 360 0"></polygon>
                    <polygon points="630 150 540 300 720 300"></polygon>
                    <polygon fill="#DDD" points="630 150 720 0 540 0"></polygon>
                    <polygon
                      fill="#444"
                      points="810 150 720 300 900 300"></polygon>
                    <polygon fill="#FFF" points="810 150 900 0 720 0"></polygon>
                    <polygon
                      fill="#DDD"
                      points="990 150 900 300 1080 300"></polygon>
                    <polygon
                      fill="#444"
                      points="990 150 1080 0 900 0"></polygon>
                    <polygon
                      fill="#DDD"
                      points="90 450 0 600 180 600"></polygon>
                    <polygon points="90 450 180 300 0 300"></polygon>
                    <polygon
                      fill="#666"
                      points="270 450 180 600 360 600"></polygon>
                    <polygon
                      fill="#AAA"
                      points="270 450 360 300 180 300"></polygon>
                    <polygon
                      fill="#DDD"
                      points="450 450 360 600 540 600"></polygon>
                    <polygon
                      fill="#999"
                      points="450 450 540 300 360 300"></polygon>
                    <polygon
                      fill="#999"
                      points="630 450 540 600 720 600"></polygon>
                    <polygon
                      fill="#FFF"
                      points="630 450 720 300 540 300"></polygon>
                    <polygon points="810 450 720 600 900 600"></polygon>
                    <polygon
                      fill="#DDD"
                      points="810 450 900 300 720 300"></polygon>
                    <polygon
                      fill="#AAA"
                      points="990 450 900 600 1080 600"></polygon>
                    <polygon
                      fill="#444"
                      points="990 450 1080 300 900 300"></polygon>
                    <polygon
                      fill="#222"
                      points="90 750 0 900 180 900"></polygon>
                    <polygon points="270 750 180 900 360 900"></polygon>
                    <polygon
                      fill="#DDD"
                      points="270 750 360 600 180 600"></polygon>
                    <polygon points="450 750 540 600 360 600"></polygon>
                    <polygon points="630 750 540 900 720 900"></polygon>
                    <polygon
                      fill="#444"
                      points="630 750 720 600 540 600"></polygon>
                    <polygon
                      fill="#AAA"
                      points="810 750 720 900 900 900"></polygon>
                    <polygon
                      fill="#666"
                      points="810 750 900 600 720 600"></polygon>
                    <polygon
                      fill="#999"
                      points="990 750 900 900 1080 900"></polygon>
                    <polygon
                      fill="#999"
                      points="180 0 90 150 270 150"></polygon>
                    <polygon
                      fill="#444"
                      points="360 0 270 150 450 150"></polygon>
                    <polygon
                      fill="#FFF"
                      points="540 0 450 150 630 150"></polygon>
                    <polygon points="900 0 810 150 990 150"></polygon>
                    <polygon
                      fill="#222"
                      points="0 300 -90 450 90 450"></polygon>
                    <polygon
                      fill="#FFF"
                      points="0 300 90 150 -90 150"></polygon>
                    <polygon
                      fill="#FFF"
                      points="180 300 90 450 270 450"></polygon>
                    <polygon
                      fill="#666"
                      points="180 300 270 150 90 150"></polygon>
                    <polygon
                      fill="#222"
                      points="360 300 270 450 450 450"></polygon>
                    <polygon
                      fill="#FFF"
                      points="360 300 450 150 270 150"></polygon>
                    <polygon
                      fill="#444"
                      points="540 300 450 450 630 450"></polygon>
                    <polygon
                      fill="#222"
                      points="540 300 630 150 450 150"></polygon>
                    <polygon
                      fill="#AAA"
                      points="720 300 630 450 810 450"></polygon>
                    <polygon
                      fill="#666"
                      points="720 300 810 150 630 150"></polygon>
                    <polygon
                      fill="#FFF"
                      points="900 300 810 450 990 450"></polygon>
                    <polygon
                      fill="#999"
                      points="900 300 990 150 810 150"></polygon>
                    <polygon points="0 600 -90 750 90 750"></polygon>
                    <polygon
                      fill="#666"
                      points="0 600 90 450 -90 450"></polygon>
                    <polygon
                      fill="#AAA"
                      points="180 600 90 750 270 750"></polygon>
                    <polygon
                      fill="#444"
                      points="180 600 270 450 90 450"></polygon>
                    <polygon
                      fill="#444"
                      points="360 600 270 750 450 750"></polygon>
                    <polygon
                      fill="#999"
                      points="360 600 450 450 270 450"></polygon>
                    <polygon
                      fill="#666"
                      points="540 600 630 450 450 450"></polygon>
                    <polygon
                      fill="#222"
                      points="720 600 630 750 810 750"></polygon>
                    <polygon
                      fill="#FFF"
                      points="900 600 810 750 990 750"></polygon>
                    <polygon
                      fill="#222"
                      points="900 600 990 450 810 450"></polygon>
                    <polygon
                      fill="#DDD"
                      points="0 900 90 750 -90 750"></polygon>
                    <polygon
                      fill="#444"
                      points="180 900 270 750 90 750"></polygon>
                    <polygon
                      fill="#FFF"
                      points="360 900 450 750 270 750"></polygon>
                    <polygon
                      fill="#AAA"
                      points="540 900 630 750 450 750"></polygon>
                    <polygon
                      fill="#FFF"
                      points="720 900 810 750 630 750"></polygon>
                    <polygon
                      fill="#222"
                      points="900 900 990 750 810 750"></polygon>
                    <polygon
                      fill="#222"
                      points="1080 300 990 450 1170 450"></polygon>
                    <polygon
                      fill="#FFF"
                      points="1080 300 1170 150 990 150"></polygon>
                    <polygon points="1080 600 990 750 1170 750"></polygon>
                    <polygon
                      fill="#666"
                      points="1080 600 1170 450 990 450"></polygon>
                    <polygon
                      fill="#DDD"
                      points="1080 900 1170 750 990 750"></polygon>
                  </g>
                </pattern>
              </defs>
              <rect
                x="0"
                y="0"
                fill={`url(#${linearGradientId})`}
                width="100%"
                height="100%"></rect>
              <rect
                x="0"
                y="0"
                fill={`url(#${patternId})`}
                width="100%"
                height="100%"></rect>
            </svg>
            <Avatar
              className="card__avatar sm:top-20 top-20"
              size="large"
              style={{ backgroundColor: colors[UserData.id % colors.length] }}>
              <div className="text-4xl">{UserData.fullName.charAt(0)}</div>
            </Avatar>
          </div>
          <div className="flex items-center justify-center flex-col sm:mt-14 mt-24">
            <div className="sm:flex block items-center justify-between w-full">
              {/* <div className="p-4 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">الرصيد</div>
                <div className="text-lg">
                  {formatNumberWithCommas(SingleUserData.wallet.amount)}
                </div>
              </div> */}
              <div className="p-4 w-1/3 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">الاسم الكامل</div>
                <div className="text-lg">{UserData.fullName}</div>
              </div>
              <div className="p-4 w-1/3 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">الايميل</div>
                <div className="text-lg">{UserData.email}</div>
              </div>
              <div className="p-4 w-1/3 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">رقم الهاتف</div>
                <div className="text-lg">{UserData.phoneNumber}</div>
              </div>
              {/* {SingleUserData.account && (
              <div className="p-4 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">اسم المكتب</div>
                <div className="text-lg">
                  {SingleUserData.account.name
                    ? SingleUserData.account.name
                    : "غير متاح"}
                </div>
              </div>
            )} */}
            </div>
          </div>
          <div className="flex">
            {" "}
            <div className="flex-col w-full p-4 items-center">
              <div className="p-4 flex-grow items-center flex flex-col justify-center">
                <div className="font-semibold text-xl p-2">
                  النسبة المئوية لرسوم التحويل بين الحسابات
                </div>
                {TaxInput ? (
                  <div className="text-lg flex items-center text-center">
                    <Input
                      defaultValue={TaxValue}
                      value={NewTaxValue}
                      onChange={handleTaxChange}
                      // Centering the text
                      className="text-center" // Ensuring full width and centered text
                    />

                    <div className="pl-2">%</div>
                  </div>
                ) : (
                  <div className="text-lg flex items-center text-center">
                    {/* Adding w-full and text-center */}
                    {transactionType.map((element: any, index: number) => (
                      <div key={index}>
                        {element.type === "transfer" ? element.tax : null}
                      </div>
                    ))}
                    <div className="pl-2">%</div>
                  </div>
                )}
              </div>
              <div className="w-full flex justify-center">
                {TaxInput ? (
                  <div>
                    <Button
                      size="large"
                      type="primary"
                      className="m-2"
                      loading={IsFeeSubmitting}
                      onClick={() => handleSaveClick()}>
                      حفظ
                    </Button>
                    <Button
                      size="large"
                      type="default"
                      danger
                      className="m-2"
                      onClick={() => handleCancelClick()}>
                      الغاء
                    </Button>
                  </div>
                ) : (
                  <Button
                    size="large"
                    className="m-2"
                    type="primary"
                    onClick={showChangeTaxInput}>
                    تغيير رسوم التحويل
                  </Button>
                )}
              </div>

              {/* <div>
            {SingleUserData.status == "pending" ||
            SingleUserData.status == "deactivated" ? (
              <Popconfirm
                title="تفعيل المستخدم"
                description="هل انت متاكد من تفعيل هذا المستخدم"
                onConfirm={UserAcceptConfirm}
                onCancel={UserAcceptCancel}
                okText="نعم"
                cancelText="لا">
                <Button
                  type="primary"
                  className="m-5"
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
                 
                  }}>
                  تفعيل
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="الغاء تفعيل المستخدم"
                description="هل انت متاكد من الغاء تفعيل هذا المستخدم"
                onConfirm={UserDeactivateConfirm}
                onCancel={UserDeactivateCancel}
                okText="نعم"
                cancelText="لا">
                <Button
                  danger
                  size="large"
                  className="m-5"
                  onClick={(e) => {
                    e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
                  
                  }}>
                  تعطيل
                </Button>
              </Popconfirm>
            )}
          </div>
          {SingleUserData.status === "pending" &&
          SingleUserData.wallet &&
          SingleUserData.wallet?.amount === "0" ? (
            <Button
              size="large"
              className="m-2"
              type="default"
              danger
              onClick={() => handleDeleteUser()}>
              حذف المستخدم
            </Button>
          ) : (
            ""
          )} */}
            </div>{" "}
            <div className="flex-col w-full p-4 items-center">
              <div className="p-4 flex-grow items-center flex flex-col justify-center">
                <div className="font-semibold text-xl p-2">
                  مبلغ الربح من التحويل بين الحسابات
                </div>
                <div className="text-lg flex items-center text-center">
                  {dashboardTransactionFees}
                </div>
              </div>
              <div className="w-full flex justify-center"></div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
