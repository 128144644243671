import React, { useState, useEffect } from "react";
import { Loading } from "../../../components/elements/loading";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GetTransactionsData } from "../api/GetTransactionsData";
import { useNavigate } from "react-router-dom";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { Collapse } from "@mui/material";
import { API_URL } from "../../../config";
import { AutoComplete, Image, Input, Pagination, Select } from "antd";
import Menu from "@mui/material/Menu";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import IconButton from "@mui/material/IconButton";
import { GetTransactionsType } from "../api/getTransactionsType";
import { GetTransactionsCard } from "../api/GetTransactionsCard";

export const TransactionsTable = () => {
  const navigate = useNavigate();
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [transactionsData, setTransactionsData] = useState([]);
  const [totalTransactionsCount, setTotalTransactionsCount] =
    useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0); // State to hold the total number of pages
  const [filterAnchorEl, setFilterAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(filterAnchorEl);
  const [selectedIsLoading, setSelectedIsLoading] = useState(false);
  const [autoCompleteIsLoading, setAutoCompleteIsLoading] = useState(false);

  const [checked, setChecked] = React.useState(true);

  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0); // Start from 0
  const rowsPerPage = 15;

  const [transactionType, setTransactionType] = useState([]);
  const [transactionCard, setTransactionCard] = useState([]);
  const [transactionTypeFilter, setTransactionTypeFilter] = useState();
  const [transactionCardFilter, setTransactionCardFilter] = useState([]);

  const handleClick = (name: any, userId: any) => {
    navigate(`/${name}/${userId}`); // Assuming the path to view a user is '/user/:userId'
  };

  const toggleRow = (rowId: string) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [rowId]: !prevOpenRows[rowId],
    }));
  };
  const handleCollapseChange = () => {
    setChecked((prev) => !prev);
  };
  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // setFilterAnchorEl(event.currentTarget);
    handleCollapseChange();
    // console.log(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const fetchTransactionsData = async (
    page: number,
    typeFilter?: any,
    cardFilter?: any
  ) => {
    // console.log(cardFilter);

    try {
      // setIsLoading(true);
      const offset = page * rowsPerPage; // Calculate offset based on the current page

      const response: any = await GetTransactionsData(
        offset,
        rowsPerPage,
        typeFilter,
        cardFilter
      );
      setTransactionsData(response.array);
      setTotalTransactionsCount(response.count);
      setTotalTransactions(response.total); // Assuming the total count is provided in the response
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    } finally {
      setIsLoading(false);
      setSelectedIsLoading(false);
      setAutoCompleteIsLoading(false);
    }
  };

  const fetchTransactionsType = async () => {
    try {
      const response = await GetTransactionsType();
      // console.log(response);

      setTransactionType(response.array);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    }
  };
  const fetchTransactionsCard = async (name: any) => {
    // console.log(name);
    if (name == "") {
      fetchTransactionsData(currentPage, transactionTypeFilter, null);
    }
    try {
      const response = await GetTransactionsCard(name);
      // console.log(response.array);
      if (response.array) {
        setTransactionCard(
          response.array.map((row: any) => ({
            value: row.id,
            label: row.name,
          }))
        );
      }
      // setTransactionCard(response.array);
      // setTransactionCardFilter(response.array);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    }
  };

  useEffect(() => {
    // Calculate the total number of pages
    const pages = Math.ceil(totalTransactionsCount / rowsPerPage);
    // console.log(pages);

    if (pages === 0) {
      setTotalPages(1);
    } else {
      setTotalPages(pages);
    }
  }, [totalTransactionsCount]); // Update total pages when totalTransactionsCount changes

  useEffect(() => {
    fetchTransactionsData(
      currentPage,
      transactionTypeFilter,
      transactionCardFilter
    ); // Fetch data for the initial page load
    fetchTransactionsType();
  }, [currentPage]);
  // useEffect(() => {
  //   fetchTransactionsType();
  //   fetchTransactionsCard();
  // }, []);
  const handlePageChange = (page: any) => {
    // No need to adjust the page value as Ant Design Pagination is 1-based
    if (transactionsData.length >= rowsPerPage || page - 1 < currentPage) {
      setCurrentPage(page - 1); // Adjust to 0-based for your internal state
    }
  };

  const typeFilter = (value: any) => {
    setSelectedIsLoading(true);
    setTransactionTypeFilter(value);
    fetchTransactionsData(0, value, transactionCardFilter);
  };

  const CardFilter = (value: any, option: any) => {
    // console.log(option.key); // Should log the value (row.id) of the selected option
    setAutoCompleteIsLoading(true);
    setTransactionCardFilter(option.key); // Set transactionCardFilter to the label for display (if needed)
    fetchTransactionsData(0, transactionTypeFilter, option.key); // Assuming fetchTransactionsData requires the value as an argument
  };
  const handleChange = (value: string) => {
    if (!value) {
      // console.log("AutoComplete cleared");
      setTransactionCardFilter([]);
    }
  };
  // console.log(transactionsData);

  function convertUTCDateToLocalDate(date: Date): Date {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <div className="w-full flex rounded-md justify-center">
      <div className="flex flex-col bg-color2 rounded-md w-full">
        <TableContainer sx={{ width: "100%" }} component={Paper}>
          <div className="flex items-center">
            {/* <Menu
            id="basic-menu"
            anchorEl={filterAnchorEl}
            open={open}
            // className="z-20"
            style={{ zIndex: "10" }}
            onClose={handleFilterClose}
            MenuListProps={
              {
                // "aria-labelledby": "basic-button",
              }
            }
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}> */}
            <div className="w-full">
              <Collapse in={checked}>
                <div className="w-full flex h-full justify-end items-center">
                  {/* <div className="p-2 flex items-center">
                    <div>
                      <Select
                        // className="z-10"
                        // defaultValue="lucy"
                        style={{ width: 120, zIndex: "20" }}
                        allowClear
                        options={[
                          { value: "jack", label: "Jack" },
                          { value: "lucy", label: "Lucy" },
                          { value: "Yiminghe", label: "yiminghe" },
                          { value: "disabled", label: "Disabled" },
                        ]}
                      />
                    </div>
                    <div className="pl-4">البطاقات</div>
                  </div> */}
                  <div className="p-2 flex items-center">
                    <div>
                      {transactionType != undefined ? (
                        <Select
                          loading={selectedIsLoading ? true : false}
                          placeholder="اختر نوع العملية"
                          // className="z-10"
                          // defaultValue="lucy"
                          style={{ width: 120, zIndex: "20" }}
                          allowClear
                          options={transactionType.map((row: any) => ({
                            value: row.type,
                            label:
                              row.type == "buy"
                                ? "شراء"
                                : row.type == "charge"
                                ? "شحن"
                                : " تحويل",
                          }))}
                          onChange={typeFilter}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="pl-4 w-full">نوع العملية</div>
                  </div>
                  <div className="p-4 flex items-center">
                    <div className="w-full">
                      {transactionCard != undefined ? (
                        <AutoComplete
                          options={transactionCard.map((option: any) => ({
                            key: option.value,
                            value: option.label,
                          }))}
                          disabled={autoCompleteIsLoading ? true : false}
                          onChange={handleChange}
                          placeholder="اختر الكرت للبحث"
                          allowClear
                          style={{ width: 200 }}
                          onSearch={fetchTransactionsCard}
                          onSelect={CardFilter}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="pl-4 w-full">الكرتات</div>
                  </div>
                </div>
              </Collapse>
            </div>
            {/* </Menu> */}
            <div className="flex w-min justify-end p-6 items-center">
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleFilterClick}>
                <FilterAltIcon />
              </IconButton>
            </div>
          </div>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">التاريخ والوقت</TableCell>
                <TableCell align="center">الحالة</TableCell>
                <TableCell align="center">رقم هاتف المستخدم المستلم</TableCell>
                <TableCell align="center">المستخدم المستلم</TableCell>
                <TableCell align="center">رقم هاتف المستخدم المرسل</TableCell>
                <TableCell align="center" className="">
                  المستخدم المرسل
                </TableCell>
                <TableCell align="center">الكمية</TableCell>
                <TableCell align="center">المبلغ</TableCell>
                <TableCell align="center">رقم البطاقة / رقم التسلسل</TableCell>
                <TableCell align="center">البطاقة</TableCell>
                <TableCell align="center">نوع العملية</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionsData.map((row: any) => (
                <React.Fragment key={row.id}>
                  <TableRow>
                    <TableCell align="center" className="w-max">
                      <div className="h-full w-max flex justify-center flex-col items-center">
                        {(() => {
                          // Parse the createdAt date as UTC by appending "Z" or converting it
                          const createdAtDate = new Date(row.createdAt); // Assuming row.createdAt is in UTC
                          const today = new Date();

                          const isToday =
                            createdAtDate.getFullYear() ===
                              today.getFullYear() &&
                            createdAtDate.getMonth() === today.getMonth() &&
                            createdAtDate.getDate() === today.getDate();

                          return isToday
                            ? "اليوم"
                            : createdAtDate.toLocaleDateString(); // Local time zone for the date
                        })()}
                        <div className="w-max">
                          {/* Convert the time to local browser time */}

                          {convertUTCDateToLocalDate(
                            new Date(row.createdAt)
                          ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row.status === "approved" ? "تم الموافقة" : "تم الرفض"}
                    </TableCell>
                    <TableCell align="center">
                      {row.userToID != null ? row.userTo.phoneNumber : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row.userToID != null ? (
                        <div
                          className="cursor-pointer w-full"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                          onClick={() => handleClick("users", row.userToID)}>
                          <div style={{ width: "max-content" }}>
                            {row.userToID != null ? row.userTo.fullName : "-"}
                          </div>
                        </div>
                      ) : (
                        <div className="w-full">
                          {row.userToID != null ? row.userTo.fullName : "-"}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.userFromID != null ? row.userFrom.phoneNumber : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row.userFromID != null ? (
                        <div
                          className="cursor-pointer w-full flex justify-center"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                          onClick={() => handleClick("users", row.userFromID)}>
                          <div style={{ width: "max-content" }}>
                            {row.userFromID != null
                              ? row.userFrom.fullName
                              : "-"}
                          </div>
                        </div>
                      ) : (
                        <div className="w-full">
                          {row.userFromID != null ? row.userFrom.fullName : "-"}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.order != null ? (
                        <div>{row.order.orderDetails.length}</div>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {formatNumberWithCommas(row.amount)}
                    </TableCell>
                    <TableCell align="center">
                      {row.order != null ? (
                        <div className="cursor-pointer">
                          <div onClick={() => toggleRow(row.id)}>
                            {openRows[row.id] ? "عرض أقل" : "عرض المزيد"}
                          </div>
                        </div>
                      ) : (
                        <div className="cursor-pointer">
                          <div onClick={() => toggleRow(row.id)}>
                            {openRows[row.id] ? "عرض أقل" : "عرض المزيد"}
                          </div>
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.order != null ? (
                        <div className="">
                          {row.order != null
                            ? row.order.orderDetails[0].cardName
                            : "-"}
                        </div>
                      ) : (
                        <div>
                          {row.order != null
                            ? row.order.orderDetails[0].cardName
                            : "-"}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.transactionType.type === "buy"
                        ? "شراء"
                        : row.transactionType.type === "charge"
                        ? "شحن"
                        : "تحويل"}
                    </TableCell>
                  </TableRow>
                  {row.order != null ? (
                    <TableRow className="m-4">
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}>
                        <Collapse
                          in={openRows[row.id] || false}
                          timeout="auto"
                          className="m-10"
                          unmountOnExit>
                          <TableContainer
                            component={Paper}
                            sx={{ width: "100%" }}>
                            <Table aria-label="details table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    رقم التسلسل
                                  </TableCell>
                                  <TableCell align="center">
                                    رقم البطاقة
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.order != null
                                  ? row.order.orderDetails.map(
                                      (detail: any, index: number) => (
                                        <TableRow key={index}>
                                          <TableCell align="center">
                                            {detail.serialNumber}
                                          </TableCell>
                                          <TableCell align="center">
                                            {detail.code}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  ) : row.charge != null ? (
                    <TableRow className="m-4">
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}>
                        <Collapse
                          in={openRows[row.id] || false}
                          timeout="auto"
                          className="m-10"
                          unmountOnExit>
                          <TableContainer
                            component={Paper}
                            sx={{ width: "100%" }}>
                            <Table aria-label="details table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    رقم الحوالة
                                  </TableCell>
                                  <TableCell align="center">
                                    صورة الحوالة
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.charge != null ? (
                                  <TableRow>
                                    <TableCell align="center">
                                      {row.charge.transactionNumber}
                                    </TableCell>
                                    <TableCell align="center">
                                      <div className="w-full flex justify-center items-center">
                                        {" "}
                                        <div className="h-52 w-52 overflow-hidden flex justify-center">
                                          <Image
                                            src={`${API_URL}/cards/download?path=${row.charge.imageUrl}`}
                                            height={"100%"}
                                            width={"100%"}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  ""
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  ) : row.transactionType.type === "charge" ? (
                    <TableRow className="m-4">
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}>
                        <Collapse
                          in={openRows[row.id] || false}
                          timeout="auto"
                          className="m-10 flex justify-center w-full"
                          unmountOnExit>
                          <div className="flex justify-center">
                            تمت الاضافة من قبل الادمن
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow className="m-4">
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}>
                        <Collapse
                          in={openRows[row.id] || false}
                          timeout="auto"
                          className="m-10 flex justify-center w-full"
                          unmountOnExit>
                          <div className="flex justify-center">
                            لايوجد معلومات لعرضها
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {transactionsData.length === 0 && (
            <div className="w-full flex justify-center items-center p-4">
              <div className="flex w-full justify-center">لايوجد تحويلات</div>
            </div>
          )}
        </TableContainer>
        <div className="flex justify-end p-8 items-center">
          <div className="ml-4 text-lg flex">
            {/* <div>{totalPages}</div>
            <div>&nbsp;من&nbsp;</div>
            <div>{currentPage + 1}</div>
            <div>&nbsp;الصفحة</div> */}
          </div>
          <div className="w-full">
            {/* <Pagination
              count={Math.ceil(totalTransactions / rowsPerPage)}
              page={currentPage + 1} // Material UI Pagination is 1-based, adjust to 1-based
              onChange={handlePageChange}
              sx={{ ".MuiPaginationItem-root": { fontSize: "1.2rem" } }}
              disabled={transactionsData.length === 0 && currentPage === 0}
            /> */}
            <Pagination
              total={totalPages * rowsPerPage}
              current={currentPage + 1} // Convert to 1-based for Ant Design
              pageSize={rowsPerPage}
              onChange={handlePageChange}
              style={{ fontSize: "1.2rem" }}
              showSizeChanger={false}
              // current={current}
              // onChange={onChange}
              // total={50}
              // disabled={transactionsData.length === 0 && currentPage === 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
