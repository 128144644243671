import { axios } from "../../../lib/axios";

export const getCharge = async (filter?: any) => {
  let url = `charge?relations[]=user&relations[]=chargeSource`;

  if (filter) {
    if (filter.filter === "approved") {
      url += `&status=approved`;
    } else if (filter.filter === "pending") {
      url += `&status=pending`;
    } else if (filter.filter === "cancelled") {
      url += `&status=cancelled`;
    }
  }
  const response = await axios.get(url);
  // console.log(response.data);

  return response.data;
};

export const GetUsers = async (filter?: any) => {
  try {
    const roleResponse = await axios.get("/role");
    const userRole = roleResponse.data.array.find(
      (role: any) => role.roleName === "user"
    );
    // console.log(userRole);

    if (userRole) {
      let url = `/user?roleId=${userRole.id}`;

      if (filter.filter === "Verified" || filter.filter === "NotVerified") {
        url += `&status=${filter.filter === "Verified" ? "active" : "pending"}`;
      } else if (filter.filter === "User" || filter.filter === "Agent") {
        url += `&isOffice=${filter.filter === "User" ? "false" : "true"}`;
      }

      const response = await axios.get(url);
      return response.data;
    } else {
      throw new Error("User role not found");
    }
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Error fetching users: " + error);
  }
};
