import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Pagination } from "antd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
import { Card } from "@mui/material";
import { Loading } from "../../../components/elements/loading";
import { GetSingleUserTransactions } from "../api/getSingleUserTransactions";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";

export const UserHistory = (UserId: any) => {
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [UserTransactionsData, setUserTransactionsData] = useState([]);
  const [totalPages, setTotalPages] = useState<number>(0); // State to hold the total number of pages
  const [currentPage, setCurrentPage] = useState<number>(0); // Start from 0
  const [totalUserTransactionsCount, setTotalUserTransactionsCount] =
    useState<number>(0);
  const rowsPerPage = 10;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const fetchTransactionsData = async (page: number) => {
    try {
      const offset = page * rowsPerPage;
      const response: any = await GetSingleUserTransactions(
        UserId,
        offset,
        rowsPerPage
      );
      setUserTransactionsData(response.array);
      // console.log(response);

      setTotalUserTransactionsCount(response.count);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };
  useEffect(() => {
    // Calculate the total number of pages
    const pages = Math.ceil(totalUserTransactionsCount / rowsPerPage);
    // console.log(pages);

    if (pages === 0) {
      setTotalPages(1);
    } else {
      setTotalPages(pages);
    }
  }, [totalUserTransactionsCount]); // Update total pages when totalTransactionsCount changes

  useEffect(() => {
    fetchTransactionsData(currentPage); // Fetch data for the initial page load
  }, [currentPage]);
  // useEffect(() => {
  //   fetchTransactionsData(); // Call fetchCardsData inside useEffect
  //   setIsLoading(false);
  // }, []); // Include id in the dependency array
  // console.log(UserTransactionsData);
  const handlePageChange = (page: any) => {
    // No need to adjust the page value as Ant Design Pagination is 1-based
    if (UserTransactionsData.length >= rowsPerPage || page - 1 < currentPage) {
      setCurrentPage(page - 1); // Adjust to 0-based for your internal state
    }
  };

  // console.log(UserTransactionsData);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="w-full p-4 min-h-full flex-grow">
      <Card sx={{ height: "100%" }}>
        <div className="w-full bg-color2 rounded-md h-full flex flex-col justify-end">
          <div className="flex w-full justify-between">
            <div
              className="flex m-10 items-center"
              onClick={(e) => {
                e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
              }}>
              <Button
                type="text"
                icon={
                  expanded ? (
                    <ExpandLessIcon className="text-color1" />
                  ) : (
                    <ExpandMoreIcon className="text-color1" />
                  )
                }
                onClick={handleExpandClick}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
            </div>

            <div className="flex flex-col" style={{ direction: "rtl" }}>
              <div className="text-3xl p-4 font-semibold flex justify-center items-center h-full">
                <div className="p-4">التحويلات</div>
              </div>
            </div>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Card sx={{ height: "100%" }}>
              <div className="bg-color2 rounded-md">
                {" "}
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">التاريخ والوقت</TableCell>
                        <TableCell align="center">المبلغ</TableCell>
                        <TableCell align="center">الحالة</TableCell>
                        <TableCell align="center">نوع العملية</TableCell>
                      </TableRow>
                    </TableHead>
                    {UserTransactionsData.length > 0 ? (
                      <TableBody>
                        {UserTransactionsData.map((row: any) => (
                          <TableRow key={row.id}>
                            {/* <TableCell align="center"></TableCell> */}
                            <TableCell
                              align="center"
                              className="flex justify-center ">
                              {/* {new Date(row.createdAt).toLocaleDateString()} */}
                              <div className="w-full flex justify-center flex-col items-center">
                                <div className="w-min">
                                  {new Date(row.createdAt).toLocaleDateString()}
                                </div>
                                <div className="w-max">
                                  {new Date(row.createdAt).toLocaleTimeString()}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              {formatNumberWithCommas(row.amount)}
                            </TableCell>{" "}
                            <TableCell align="center">
                              {row.status == "approved"
                                ? "تم الموافقة"
                                : "تم الرفض"}
                            </TableCell>
                            <TableCell align="center">
                              {row.transactionType.type == "buy"
                                ? "شراء"
                                : "شحن"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      ""
                    )}
                  </Table>
                  {UserTransactionsData.length === 0 ? (
                    <div className="w-full flex justify-center items-center p-4">
                      <div className="flex w-full justify-center">
                        لايوجد تحويلات
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </TableContainer>
              </div>
              <div className="p-10">
                {" "}
                <Pagination
                  total={totalPages * rowsPerPage}
                  current={currentPage + 1} // Convert to 1-based for Ant Design
                  pageSize={rowsPerPage}
                  onChange={handlePageChange}
                  style={{ fontSize: "1.2rem" }}
                  showSizeChanger={false}
                  // current={current}
                  // onChange={onChange}
                  // total={50}
                  // disabled={transactionsData.length === 0 && currentPage === 0}
                />
              </div>
            </Card>
          </Collapse>
        </div>
      </Card>
    </div>
  );
};
