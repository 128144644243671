import { axios } from "../../../lib/axios";

export const ChangePassword = async (
  user: any,
  // oldPassword: any,
  newPassword: any,
  adminId?: any
) => {
  // const { user } = useAuth();
  console.log(user);
  console.log(adminId);

  if (user?.role.roleName == "admin") {
    const passwordData = {
      // oldPassword,
      newPassword,
    };

    try {
      const response = await axios.patch(
        `/auth/change-password/admin`,
        passwordData
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      } else {
        throw new Error("حدث خطأ ما");
      }
    }
  } else {
    if (adminId) {
      const passwordData = {
        // oldPassword,
        newPassword,
      };
      console.log(adminId);

      try {
        const response = await axios.patch(
          `auth/superadmin/change-admin-password/${adminId}`,
          passwordData
        );
        return response.data;
      } catch (error: any) {
        if (error.response && error.response.data) {
          throw error.response.data;
        } else {
          throw new Error("حدث خطأ ما");
        }
      }
    }
  }
};
