import { axios } from "../../../lib/axios";

export const deleteSingleCategory = async (categoryId: any) => {
  try {
    const response = await axios.delete(`/category/${categoryId}`);

    return response;
  } catch (error) {
    // Handle error here
    throw new Error("Error deactivating category: " + error);
  }
};
