import React from "react";
import { DashboardUsersList } from "../components/DashboardUsersList";
import { DashboardCategorysList } from "../components/DashboardCategorysList";
import DashboardChartSecound from "../components/DashboardChartSecound";

export const DashboardWrapper = () => {
  return (
    <div className="block w-full p-4 sm:flex h-screen mb-4">
      <div className="sm:w-1/4 w-full bg-color2 rounded-md mr-4 h-[calc(100%+1rem)]">
        <div className="h-full">
          <DashboardUsersList />
        </div>
      </div>
      <div className="sm:w-3/4 h-full w-full sm:mt-0">
        <div className="bg-color2 rounded-md h-1/4">
          <DashboardCategorysList />
        </div>

        <div className="bg-color2 rounded-md h-[calc(75%)] mt-4 p-4">
          <DashboardChartSecound />
        </div>
      </div>
    </div>
  );
};
