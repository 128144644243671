import { axios } from "../../../lib/axios";

export const AddSingleCard = async (data: any) => {
  // Create a new object to hold the data to be sent
  const requestData: any = {
    code: data.code,
    cardId: data.cardId,
  };

  // Conditionally add the serialNumber field if it exists
  if (data.serialNumber) {
    requestData.serialNumber = data.serialNumber;
  }

  // Send the request
  const response = await axios.post(`/items`, requestData);
  // console.log(response);

  return response;
};
