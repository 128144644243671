import { axios } from "../../../lib/axios";
// import { UserListResponse } from "../types";

export const DeleteUser = async (UserId: any): Promise<any> => {
  const data = {
    status: "delete",
  };
  const response = await axios.patch(`User/Status/${UserId}`, data);

  return response.status;
};
