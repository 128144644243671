import { axios } from "../../../lib/axios";

export const GetTransactionsData = async (
  offset: number,
  limit: number,
  typeFilter?: any,
  cardFilter?: any
): Promise<any> => {
  let query = `/transactions?orderDirection=DESC&orderBy=createdAt&relations[]=category&offset=${offset}&limit=${limit}`;

  if (typeFilter) {
    query += `&transactionType=${typeFilter}`;
  }
  if (cardFilter) {
    query += `&cardId=${cardFilter}`;
  }

  const response = await axios.get(query);
  return response.data;
};
