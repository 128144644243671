import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { DashboardWrapper } from "./DashboardWrapper";

export const DashboardRouters = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Routes>
      <Route index element={<DashboardWrapper />} />
    </Routes>
  );
};
