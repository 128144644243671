import { axios } from "../../../lib/axios";
import { SingleCategory } from "../types";

export const UploadFullCard = async (
  data: any[],
  cardId: any,
  setUploadError: any
): Promise<SingleCategory> => {
  try {
    // Send the JSON data in the POST request
    const response = await axios.post(`/items/bulk/${cardId}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("UploadFullCard - Successful response:", response.data);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      console.error("UploadFullCard - Conflict:", error.response.data);
      const errorMessage: string =
        error.response.data.message || "Conflict error occurred";
      const errorCodes: number[] = parseErrorCodes(errorMessage);
      setUploadError(errorCodes); // Set the uploadError state here

      throw new Error(errorMessage);
    } else {
      console.error("UploadFullCard - Error:", error);
      throw error;
    }
  }
};

// Helper function to parse error message into array of numbers
const parseErrorCodes = (errorMessage: string): number[] => {
  // Extract numbers from the error message, assuming the format is consistent
  const regex = /(\d+)/g;
  const matches = errorMessage.match(regex);
  if (matches) {
    return matches.map((match) => Number(match));
  }
  return [];
};
