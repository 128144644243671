import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Popconfirm,
  PopconfirmProps,
  notification,
} from "antd";
import { UserResponse } from "../types";
import { GetSingleUsers } from "../api/getSingleUser";
import { Loading } from "../../../components/elements/loading";
import { Card } from "@mui/material";
import { Input, Modal } from "antd";
import { AcceptUser } from "../api/AcceptUser";
import { CreateUserCharge } from "../../users/api/createUserCharge";
import { NotificationPlacement } from "antd/es/notification/interface";
import { DeactiveUser } from "../api/DeactivateUser";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { DeleteUser } from "../api/DeleteUser";
import { useNavigate } from "react-router-dom";
import { ChangeUserType } from "../api/changeUserType";

export const UserHeader = ({ UserId, isUserExits, setIsUserExits }: any) => {
  const colors = [
    "#FC726E",
    "#4BC190",
    "#3D5AFE",
    "#FFB300",
    "#8E24AA",
    "#00ACC1",
    "#FF6F00",
    "#FFD600",
    "#43A047",
  ];
  const navigator = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [SingleUserData, setSingleUserData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isChangeTypeToOfficeModalOpen, setIsChangeTypeToOfficeModalOpen] =
    useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    price: "",
  });
  const [errors, setErrors] = useState<any>({
    price: "",
  });

  const [changeTypeToOfficeFormData, setChangeTypeToOfficeFormData] = useState({
    name: "",
  });

  const [changeTypeToOfficeErrors, setChangeTypeToOfficeErrors] = useState({
    name: "",
  });

  const [api, contextHolder] = notification.useNotification();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [isChangeTypeToUserSubmitting, setIsChangeTypeToUserSubmitting] =
    useState<boolean>(false);

  const [isChangeTypeToOfficeSubmitting, setIsChangeTypeToOfficeSubmitting] =
    useState<boolean>(false);

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم اضافة المبلغ بنجاح",
      description: "تم اضافة المبلغ بنجاح",
      placement,
      duration: 3,
    });
  };

  const openChangeToOfficeNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم تحويل الحساب الى مكتب",
      description: "تم تحويل الحساب الى مكتب",
      placement,
      duration: 3,
    });
  };

  const openChangeToUserNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم تحويل المكتب الى مستخدم",
      description: "تم تحويل المكتب الى مستخدم",
      placement,
      duration: 3,
    });
  };

  const openNotification2 = (placement: NotificationPlacement) => {
    api.success({
      message: "تم تفعيل المستخدم بنجاح",
      description: "تم تفعيل المستخدم بنجاح",
      placement,
      duration: 3,
    });
  };
  const openDeactiveUserNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم الغاء تفعيل المستخدم بنجاح",
      description: "تم الغاء تفعيل المستخدم بنجاح",
      placement,
      duration: 3,
    });
  };

  const fetchSingleUserData = async () => {
    // setIsLoading(true);
    try {
      const response: UserResponse = await GetSingleUsers(UserId);
      // const MediaResponse = await GetSingleUserMedia(UserId);
      // console.log(MediaResponse);
      if (response) {
        setIsUserExits(true);
      }
      setSingleUserData(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
      setIsLoading(false);
    }
  };
  const handleChargeSingleUser = async () => {
    try {
      // const response = await ChargeAUser();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchSingleUserData();
    handleChargeSingleUser();
  }, []);

  const showModal = () => {
    setFormData({
      price: "",
    });
    setErrors({
      price: "",
    });
    setIsModalOpen(true);
  };

  const showChangeTypeToOfficeModal = () => {
    setIsChangeTypeToOfficeModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const changeToOfficeOk = () => {
    setIsChangeTypeToOfficeModalOpen(false);
  };

  const handleChangeTypeToOfficeCancel = () => {
    setIsChangeTypeToOfficeModalOpen(false);
    setChangeTypeToOfficeFormData({
      name: "",
    });
    setChangeTypeToOfficeErrors({
      name: "",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const UserAcceptConfirm: PopconfirmProps["onConfirm"] = (e) => {
    // message.success("Click on Yes");
    const Accept = async () => {
      const response = await AcceptUser(UserId);
      // console.log(response);
      if (response) {
        openNotification2("topRight");
        fetchSingleUserData();
      }
    };
    Accept();
  };

  // console.log(UserId);
  const UserAcceptCancel: PopconfirmProps["onCancel"] = (e) => {
    // console.log(e);
    // message.error("Click on No");
  };

  const UserDeactivateConfirm: PopconfirmProps["onCancel"] = (e) => {
    const Deactive = async () => {
      const response = await DeactiveUser(UserId);
      // console.log(response);
      if (response) {
        openDeactiveUserNotification("topRight");
        fetchSingleUserData();
      }
    };
    Deactive();
  };
  const UserDeactivateCancel: PopconfirmProps["onCancel"] = (e) => {
    // console.log(e);
    // message.error("Click on No");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!/^[-]?\d*\.?\d*$/.test(inputValue)) {
      // If the input value is not numeric (including negative numbers), don't update the state
      return;
    }

    // Update the state with the numeric value
    setFormData((prevData: any) => ({
      ...prevData,
      price: inputValue,
    }));
  };

  const handleToOfficeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Update the state with the numeric value
    setChangeTypeToOfficeFormData((prevData: any) => ({
      ...prevData,
      name: inputValue,
    }));
  };

  const handleChangeToOfficeSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    // console.log(formData);

    if (!changeTypeToOfficeFormData.name) {
      setChangeTypeToOfficeErrors((prevErrors: any) => ({
        ...prevErrors,
        name: "يرجى ادخال قيمة",
      }));
      return;
    }
    setIsChangeTypeToOfficeSubmitting(true);
    try {
      // Prepare category data from FormData
      const officeData: any = {
        accountName: changeTypeToOfficeFormData.name,
      };

      const response = await ChangeUserType(UserId, "toOffice", officeData);
      setChangeTypeToOfficeFormData({
        name: "",
      });
      setChangeTypeToOfficeErrors({
        name: "",
      });
      // console.log("response", response);

      if (response) {
        // console.log("hiiiii");

        fetchSingleUserData();
        openChangeToOfficeNotification("topRight");
      }
      // // Close the modal or perform any other necessary actions
      changeToOfficeOk();
    } catch (error) {
      console.error("Error creating category:", error);
      // Handle error here
    } finally {
      setIsChangeTypeToOfficeSubmitting(false);
    }
  };

  const handleChangeToUserSubmit = async () => {
    try {
      setIsChangeTypeToUserSubmitting(true);
      const response = await ChangeUserType(UserId, "toUser");
      if (response) {
        fetchSingleUserData();
        openChangeToUserNotification("topRight");
      }
    } catch (error) {
      console.error("Error creating category:", error);
      // Handle error here
    } finally {
      setIsChangeTypeToUserSubmitting(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(formData);

    if (!formData.price) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        price: "يرجى ادخال قيمة",
      }));
      return;
    }
    setIsSubmitting(true);
    try {
      // Prepare category data from FormData
      const categoryData: any = {
        price: formData.price,
      };
      const response = await CreateUserCharge(UserId, categoryData);
      setFormData({
        price: "",
      });
      setErrors({
        price: "",
      });
      if (response) {
        fetchSingleUserData();
        openNotification("topRight");
      }
      // Close the modal or perform any other necessary actions
      handleOk();
    } catch (error) {
      console.error("Error creating category:", error);
      // Handle error here
    } finally {
      setIsSubmitting(false);
    }
  };
  const linearGradientId = `linear-gradient-${Math.random()}`;
  const patternId = `pattern-${Math.random()}`;
  const handleDeleteUser = async () => {
    const response = await DeleteUser(UserId);
    console.log(response);

    if (response == 200) {
      navigator("/users");
    }
    // console.log(response);
  };

  // console.log(SingleUserData);
  const handlePopconfirmConfirm = (e: any) => {
    // Call handleSubmit and handle any Promise if needed
    handleSubmit(e);
  };
  return isLoading ? (
    <Loading />
  ) : isUserExits ? (
    <>
      {contextHolder}
      <Modal
        // title="اضافة مبلغ للمستخدم"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose>
        <div className="text-lg mb-4">اضافة مبلغ للمستخدم</div>

        <div className="mb-4">
          <div className="mb-1 flex flex-col items-center justify-between">
            <div>عند كتابة اشارة - قبل الرقم سيتم خصم المبلغ من المستخدم</div>
            <div className="w-full text-lg flex justify-end items-end">
              <div className="p-2">قيمة المبلغ</div>
            </div>
            <Input
              value={formData.price}
              onChange={handleChange}
              name="price"
              className="w-full"
            />
          </div>
          {errors.price && (
            <div className="text-red-500 mb-4 text-sm">{errors.price}</div>
          )}
        </div>
        <div className="flex justify-end">
          <Popconfirm
            title="اضافة مبلغ"
            description="هل أنت متأكد من اضافة المبلغ"
            onConfirm={handlePopconfirmConfirm} // Use the wrapper function
            okText="نعم"
            cancelText="لا"
            disabled={isSubmitting}>
            <Button
              htmlType="submit"
              className="text-color1"
              loading={isSubmitting}>
              اضافة
            </Button>
          </Popconfirm>
        </div>
      </Modal>
      <Modal
        // title="اضافة مبلغ للمستخدم"
        open={isChangeTypeToOfficeModalOpen}
        onCancel={handleChangeTypeToOfficeCancel}
        footer={null}
        destroyOnClose>
        <div className="text-lg mb-4">تحويل الى مكتب</div>
        <form onSubmit={handleChangeToOfficeSubmit} className="mt-10">
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-2">اسم المكتب</div>
              </div>
              <Input
                value={changeTypeToOfficeFormData.name}
                onChange={handleToOfficeChange}
                name="name"
                style={{ direction: "rtl" }}
                // placeholder="اسم النوع"
                className="w-full"
              />
            </div>
            {changeTypeToOfficeErrors.name && (
              <div className="text-red-500 mb-4 text-sm">
                {changeTypeToOfficeErrors.name}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <Button
              htmlType="submit"
              className="text-color1"
              loading={isChangeTypeToOfficeSubmitting}>
              تحويل
            </Button>
          </div>
        </form>
      </Modal>

      <div className="w-full p-4 rounded-t-md">
        <Card className="bg-[white] rounded-t-md">
          <div className={`flex items-center flex-col relative`}>
            <svg
              className="w-full opacity-70 rounded-t-md"
              xmlns="http://www.w3.org/2000/svg">
              <rect fill="#ffffff"></rect>
              <defs>
                <linearGradient
                  id={linearGradientId}
                  gradientUnits="userSpaceOnUse"
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="100%"
                  gradientTransform="rotate(222,648,379)">
                  <stop offset="0" stopColor="#ffffff"></stop>
                  <stop
                    offset="1"
                    stopColor={colors[UserId % colors.length]}></stop>
                </linearGradient>
                <pattern
                  patternUnits="userSpaceOnUse"
                  id={patternId}
                  width="300"
                  height="250"
                  x="0"
                  y="0"
                  viewBox="0 0 1080 900">
                  <g fillOpacity="0.5">
                    <polygon
                      fill="#444"
                      points="90 150 0 300 180 300"></polygon>
                    <polygon points="90 150 180 0 0 0"></polygon>
                    <polygon fill="#AAA" points="270 150 360 0 180 0"></polygon>
                    <polygon
                      fill="#DDD"
                      points="450 150 360 300 540 300"></polygon>
                    <polygon fill="#999" points="450 150 540 0 360 0"></polygon>
                    <polygon points="630 150 540 300 720 300"></polygon>
                    <polygon fill="#DDD" points="630 150 720 0 540 0"></polygon>
                    <polygon
                      fill="#444"
                      points="810 150 720 300 900 300"></polygon>
                    <polygon fill="#FFF" points="810 150 900 0 720 0"></polygon>
                    <polygon
                      fill="#DDD"
                      points="990 150 900 300 1080 300"></polygon>
                    <polygon
                      fill="#444"
                      points="990 150 1080 0 900 0"></polygon>
                    <polygon
                      fill="#DDD"
                      points="90 450 0 600 180 600"></polygon>
                    <polygon points="90 450 180 300 0 300"></polygon>
                    <polygon
                      fill="#666"
                      points="270 450 180 600 360 600"></polygon>
                    <polygon
                      fill="#AAA"
                      points="270 450 360 300 180 300"></polygon>
                    <polygon
                      fill="#DDD"
                      points="450 450 360 600 540 600"></polygon>
                    <polygon
                      fill="#999"
                      points="450 450 540 300 360 300"></polygon>
                    <polygon
                      fill="#999"
                      points="630 450 540 600 720 600"></polygon>
                    <polygon
                      fill="#FFF"
                      points="630 450 720 300 540 300"></polygon>
                    <polygon points="810 450 720 600 900 600"></polygon>
                    <polygon
                      fill="#DDD"
                      points="810 450 900 300 720 300"></polygon>
                    <polygon
                      fill="#AAA"
                      points="990 450 900 600 1080 600"></polygon>
                    <polygon
                      fill="#444"
                      points="990 450 1080 300 900 300"></polygon>
                    <polygon
                      fill="#222"
                      points="90 750 0 900 180 900"></polygon>
                    <polygon points="270 750 180 900 360 900"></polygon>
                    <polygon
                      fill="#DDD"
                      points="270 750 360 600 180 600"></polygon>
                    <polygon points="450 750 540 600 360 600"></polygon>
                    <polygon points="630 750 540 900 720 900"></polygon>
                    <polygon
                      fill="#444"
                      points="630 750 720 600 540 600"></polygon>
                    <polygon
                      fill="#AAA"
                      points="810 750 720 900 900 900"></polygon>
                    <polygon
                      fill="#666"
                      points="810 750 900 600 720 600"></polygon>
                    <polygon
                      fill="#999"
                      points="990 750 900 900 1080 900"></polygon>
                    <polygon
                      fill="#999"
                      points="180 0 90 150 270 150"></polygon>
                    <polygon
                      fill="#444"
                      points="360 0 270 150 450 150"></polygon>
                    <polygon
                      fill="#FFF"
                      points="540 0 450 150 630 150"></polygon>
                    <polygon points="900 0 810 150 990 150"></polygon>
                    <polygon
                      fill="#222"
                      points="0 300 -90 450 90 450"></polygon>
                    <polygon
                      fill="#FFF"
                      points="0 300 90 150 -90 150"></polygon>
                    <polygon
                      fill="#FFF"
                      points="180 300 90 450 270 450"></polygon>
                    <polygon
                      fill="#666"
                      points="180 300 270 150 90 150"></polygon>
                    <polygon
                      fill="#222"
                      points="360 300 270 450 450 450"></polygon>
                    <polygon
                      fill="#FFF"
                      points="360 300 450 150 270 150"></polygon>
                    <polygon
                      fill="#444"
                      points="540 300 450 450 630 450"></polygon>
                    <polygon
                      fill="#222"
                      points="540 300 630 150 450 150"></polygon>
                    <polygon
                      fill="#AAA"
                      points="720 300 630 450 810 450"></polygon>
                    <polygon
                      fill="#666"
                      points="720 300 810 150 630 150"></polygon>
                    <polygon
                      fill="#FFF"
                      points="900 300 810 450 990 450"></polygon>
                    <polygon
                      fill="#999"
                      points="900 300 990 150 810 150"></polygon>
                    <polygon points="0 600 -90 750 90 750"></polygon>
                    <polygon
                      fill="#666"
                      points="0 600 90 450 -90 450"></polygon>
                    <polygon
                      fill="#AAA"
                      points="180 600 90 750 270 750"></polygon>
                    <polygon
                      fill="#444"
                      points="180 600 270 450 90 450"></polygon>
                    <polygon
                      fill="#444"
                      points="360 600 270 750 450 750"></polygon>
                    <polygon
                      fill="#999"
                      points="360 600 450 450 270 450"></polygon>
                    <polygon
                      fill="#666"
                      points="540 600 630 450 450 450"></polygon>
                    <polygon
                      fill="#222"
                      points="720 600 630 750 810 750"></polygon>
                    <polygon
                      fill="#FFF"
                      points="900 600 810 750 990 750"></polygon>
                    <polygon
                      fill="#222"
                      points="900 600 990 450 810 450"></polygon>
                    <polygon
                      fill="#DDD"
                      points="0 900 90 750 -90 750"></polygon>
                    <polygon
                      fill="#444"
                      points="180 900 270 750 90 750"></polygon>
                    <polygon
                      fill="#FFF"
                      points="360 900 450 750 270 750"></polygon>
                    <polygon
                      fill="#AAA"
                      points="540 900 630 750 450 750"></polygon>
                    <polygon
                      fill="#FFF"
                      points="720 900 810 750 630 750"></polygon>
                    <polygon
                      fill="#222"
                      points="900 900 990 750 810 750"></polygon>
                    <polygon
                      fill="#222"
                      points="1080 300 990 450 1170 450"></polygon>
                    <polygon
                      fill="#FFF"
                      points="1080 300 1170 150 990 150"></polygon>
                    <polygon points="1080 600 990 750 1170 750"></polygon>
                    <polygon
                      fill="#666"
                      points="1080 600 1170 450 990 450"></polygon>
                    <polygon
                      fill="#DDD"
                      points="1080 900 1170 750 990 750"></polygon>
                  </g>
                </pattern>
              </defs>
              <rect
                x="0"
                y="0"
                fill={`url(#${linearGradientId})`}
                width="100%"
                height="100%"></rect>
              <rect
                x="0"
                y="0"
                fill={`url(#${patternId})`}
                width="100%"
                height="100%"></rect>
            </svg>
            <Avatar
              className="card__avatar sm:top-20 top-20"
              size="large"
              style={{ backgroundColor: colors[UserId % colors.length] }}>
              <div className="text-4xl">
                {SingleUserData.fullName.charAt(0)}
              </div>
            </Avatar>
          </div>
          <div className="flex items-center justify-center flex-col sm:mt-14 mt-24">
            <div className="flex flex-col justify-cetner items-center">
              <div className="p-1 text-2xl">{SingleUserData.fullName}</div>
              <div className="p-1 text-xl">{SingleUserData.email}</div>
            </div>
            <div className="sm:flex block items-center justify-between w-full">
              <div className="p-4 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">الرصيد</div>
                <div className="text-lg">
                  {SingleUserData.wallet?.amount
                    ? formatNumberWithCommas(SingleUserData.wallet.amount)
                    : null}
                </div>
              </div>
              <div className="p-4 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">النوع</div>
                <div className="text-lg">
                  {SingleUserData.account &&
                  SingleUserData.account.isOffice !== undefined
                    ? SingleUserData.account.isOffice === false
                      ? "زبون"
                      : "مكتب"
                    : "غير متاح"}
                </div>
              </div>
              <div className="p-4 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">رقم الهاتف</div>
                <div className="text-lg">{SingleUserData.phoneNumber}</div>
              </div>{" "}
              {SingleUserData.account.isOffice == true && (
                <div className="p-4 flex-grow items-center flex flex-col">
                  <div className="font-semibold text-xl p-2">اسم المكتب</div>
                  <div className="text-lg">
                    {SingleUserData.account.name
                      ? SingleUserData.account.name
                      : "غير متاح"}
                  </div>
                </div>
              )}
              <div className="p-4 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">المحافظة</div>
                <div className="text-lg">
                  {SingleUserData.account && SingleUserData.account.address
                    ? SingleUserData.account.address
                    : "غير متاح"}
                </div>
              </div>
              <div className="p-4 flex-grow items-center flex flex-col">
                <div className="font-semibold text-xl p-2">الحالة</div>
                <div className="text-lg">
                  {SingleUserData.status === "pending"
                    ? "قيد الانتظار"
                    : "مفعل"}
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center p-4 items-center">
            <div>
              {SingleUserData.account.isOffice == true ? (
                <Popconfirm
                  title="تحويل المكتب الى مستخدم"
                  description="هل انت متاكد من تحويل هذا المكتب الى مستخدم"
                  onConfirm={handleChangeToUserSubmit}
                  onCancel={UserDeactivateCancel}
                  okText="نعم"
                  disabled={isChangeTypeToUserSubmitting}
                  cancelText="لا">
                  <Button
                    size="large"
                    onClick={(e) => {
                      e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
                      // console.log("hi");
                    }}
                    className="m-2"
                    loading={isChangeTypeToUserSubmitting}
                    type="primary">
                    تحويل الى مستخدم
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  size="large"
                  className="m-2"
                  type="primary"
                  onClick={showChangeTypeToOfficeModal}>
                  تحويل الى مكتب
                </Button>
              )}
            </div>
            <div>
              <Button
                size="large"
                className="m-2"
                type="primary"
                onClick={showModal}>
                اضافة رصيد
              </Button>
            </div>
            <div>
              {SingleUserData.status == "pending" ||
              SingleUserData.status == "deactivated" ? (
                <Popconfirm
                  title="تفعيل المستخدم"
                  description="هل انت متاكد من تفعيل هذا المستخدم"
                  onConfirm={UserAcceptConfirm}
                  onCancel={UserAcceptCancel}
                  okText="نعم"
                  cancelText="لا">
                  <Button
                    type="primary"
                    className="m-5"
                    size="large"
                    onClick={(e) => {
                      e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
                      // console.log("hi");
                    }}>
                    تفعيل
                  </Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="الغاء تفعيل المستخدم"
                  description="هل انت متاكد من الغاء تفعيل هذا المستخدم"
                  onConfirm={UserDeactivateConfirm}
                  onCancel={UserDeactivateCancel}
                  okText="نعم"
                  cancelText="لا">
                  <Button
                    danger
                    size="large"
                    className="m-5"
                    onClick={(e) => {
                      e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
                      // console.log("hi");
                    }}>
                    تعطيل
                  </Button>
                </Popconfirm>
              )}
            </div>
            {SingleUserData.status === "pending" &&
            SingleUserData.wallet &&
            SingleUserData.wallet?.amount === "0" ? (
              <Button
                size="large"
                className="m-2"
                type="default"
                danger
                onClick={() => handleDeleteUser()}>
                حذف المستخدم
              </Button>
            ) : (
              ""
            )}
          </div>
        </Card>
      </div>
    </>
  ) : (
    <div className="w-full flex flex-col items-center pt-10 text-2xl">
      لم نتمكن من العثور على هذا المستخدم
      <Button onClick={() => navigator("/users")} className="mt-4">
        الرجوع الى صفحة المستخدمين
      </Button>
    </div>
  );
};
