import React from "react";
import { DashboardProfileHeader } from "../components/DashboardProfileHeader";

export const DashboardWrapper = () => {
  return (
    <div className="gb-color2">
      <DashboardProfileHeader />
    </div>
  );
};
