import { axios } from "../../../lib/axios";

export const DeactiveUserCharge = async (id: any) => {
  try {
    const response = await axios.patch(`/charge/deactivate/${id}`);

    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error accepting user charge:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};
