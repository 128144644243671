import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// import { Spinner } from "@/components/Elements";
// import { MainLayout } from "@/components/Layout";
// import { lazyImport } from "@/utils/lazyImport";

// const { DiscussionsRoutes } = lazyImport(
//   () => import("@/features/discussions"),
//   "DiscussionsRoutes"
// );
// const { Dashboard } = lazyImport(() => import("@/features/misc"), "Dashboard");
// const { Profile } = lazyImport(() => import("@/features/users"), "Profile");
// const { Users } = lazyImport(() => import("@/features/users"), "Users");
import { MainLayout } from "../components/Layout/MainLayout";
import { UserRouters } from "../features/users/routers";
import { CardsRouters } from "../features/categorys/routers";
import { DashboardRouters } from "../features/dashboard/routes";
import { ChargeRouters } from "../features/charge/routers";
import { AdminRouters } from "../features/admin/routers";
import { TransactionsRouter } from "../features/transactions/routes";
import { ChargeSourceRouters } from "../features/charge-source/routers";
import { NotificationRouters } from "../features/notification/routes";
import { DashboardProfileWRouters } from "../features/Dashboardprofile/routes";
import { WhatsAppQrCodeRouters } from "../features/whatsAppQrCode/routes";
const App = () => {
  return (
    <>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  );
};
// const App = () => {
//   return (
//     <Suspense
//       fallback={
//         <div className="h-full w-full flex items-center justify-center">
//           {/* <Spinner size="xl" /> */}
//         </div>
//       }>
//       <Outlet />
//     </Suspense>
//   );
// };

// export const protectedRoutes = [
//   {
//     path: "/Dashboard",
//     element: <App />,
//     children: [
//       { path: "users", element: <p>users</p> },
//       { path: "profile", element: <p>profile</p> },
//       { path: "", element: <p>/</p> },
//       { path: "*", element: <Navigate to="/Dashboard" /> },
//     ],
//   },
// ];
export const protectedSuperAdminRoutes = [
  {
    path: "/dashboard/*",
    element: <App />,
    children: [{ path: "*", element: <DashboardRouters /> }],
  },
  // {
  //   path: "/notification/*",
  //   element: <App />,
  //   children: [{ path: "*", element: <NotificationRouters /> }],
  // },
  {
    path: "/users/*",
    element: <App />,
    children: [{ path: "*", element: <UserRouters /> }],
  },
  {
    path: "/categorys/*",
    element: <App />,
    children: [{ path: "*", element: <CardsRouters /> }],
  },
  {
    path: "/users-payment/*",
    element: <App />,
    children: [{ path: "*", element: <ChargeRouters /> }],
  },
  {
    path: "/transactions/*",
    element: <App />,
    children: [{ path: "*", element: <TransactionsRouter /> }],
  },
  // {
  //   path: "/transfer/*",
  //   element: <App />,
  //   children: [{ path: "*", element: <TransferRouter /> }],
  // },
  {
    path: "/admins/*",
    element: <App />,
    children: [{ path: "*", element: <AdminRouters /> }],
  },
  {
    path: "/charge-source/*",
    element: <App />,
    children: [{ path: "*", element: <ChargeSourceRouters /> }],
  },
  // {
  //   path: "/WhatsAppQrCode/*",
  //   element: <App />,
  //   children: [{ path: "*", element: <WhatsAppQrCodeRouters /> }],
  // },
  {
    path: "/me/*",
    element: <App />,
    children: [{ path: "*", element: <DashboardProfileWRouters /> }],
  },
  // {
  //   path: "/me/*", // Direct path for teachers
  //   element: <App />,
  //   children: [
  //     {
  //       // Nested route for profile (e.g., /me/12)
  //       path: ":encryptedProfileId/*",
  //       element: <ProfileRouters />,
  //     },
  //     {
  //       // Redirect /me to /dashboard
  //       path: "*",
  //       element: <Navigate to="/dashboard" />,
  //     },
  //   ],
  // },

  {
    path: "*", // Redirect all other routes to the dashboard
    element: <Navigate to="/dashboard" />,
  },
];

export const protectedAdminRoutes = [
  {
    path: "/dashboard/*",
    element: <App />,
    children: [{ path: "*", element: <DashboardRouters /> }],
  },
  {
    path: "/notification/*",
    element: <App />,
    children: [{ path: "*", element: <NotificationRouters /> }],
  },
  {
    path: "/users/*",
    element: <App />,
    children: [{ path: "*", element: <UserRouters /> }],
  },
  {
    path: "/categorys/*",
    element: <App />,
    children: [{ path: "*", element: <CardsRouters /> }],
  },
  {
    path: "/users-payment/*",
    element: <App />,
    children: [{ path: "*", element: <ChargeRouters /> }],
  },
  {
    path: "/admins/*",
    element: <App />,
    children: [{ path: "*", element: <AdminRouters /> }],
  },
  {
    path: "/transactions/*",
    element: <App />,
    children: [{ path: "*", element: <TransactionsRouter /> }],
  },
  // {
  //   path: "/transfer/*",
  //   element: <App />,
  //   children: [{ path: "*", element: <TransferRouter /> }],
  // },
  {
    path: "/charge-source/*",
    element: <App />,
    children: [{ path: "*", element: <ChargeSourceRouters /> }],
  },
  {
    path: "/me/*",
    element: <App />,
    children: [{ path: "*", element: <DashboardProfileWRouters /> }],
  },
  // {
  //   path: "/WhatsAppQrCode/*",
  //   element: <App />,
  //   children: [{ path: "*", element: <WhatsAppQrCodeRouters /> }],
  // },
  // {
  //   path: "/me/*", // Direct path for teachers
  //   element: <App />,
  //   children: [
  //     {
  //       // Nested route for profile (e.g., /me/12)
  //       path: ":encryptedProfileId/*",
  //       element: <ProfileRouters />,
  //     },
  //     {
  //       // Redirect /me to /dashboard
  //       path: "*",
  //       element: <Navigate to="/dashboard" />,
  //     },
  //   ],
  // },

  {
    path: "*", // Redirect all other routes to the dashboard
    element: <Navigate to="/dashboard" />,
  },
];
