import { axios } from "../../../lib/axios";

export const DeleteAdmin = async (adminId: number) => {
  try {
    // Transform the data to the required structure

    const response = await axios.delete(`/user/${adminId}`);
    return response;
  } catch (error) {
    // Handle error here
    throw new Error("Error creating chargeSource: " + error);
  }
};
