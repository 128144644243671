import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { GetChartData } from "../api/getChartData";
import { GetCategory } from "../../categorys/api/getCategorys";
import { ApexOptions } from "apexcharts";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

interface CardData {
  name: string;
  data: number[];
}

interface TooltipData {
  date: string;
  values: { [key: string]: number };
}

export const DashboardChartSecond: React.FC = () => {
  const [chartLoaded, setChartLoaded] = useState(false);
  const [categoryData, setCategoryData] = useState<{
    [key: string]: { dates: string[]; sales: number[] };
  }>({});

  const [dateArray, setDateArray] = useState<string[]>([]);
  const [series, setSeries] = useState<CardData[]>([]);

  const chartRef = useRef<any>(null);

  const fetchData = async (startDate: string, endDate: string) => {
    try {
      const orderResponse = await GetChartData(
        startDate,
        dayjs(endDate).add(1, "day").format("YYYY-MM-DD")
      );
      const categoryResponse = await GetCategory();

      const categoryNames = categoryResponse.array.map(
        (category: any) => category.name
      );

      const categoryData: {
        [key: string]: { dates: string[]; sales: number[] };
      } = {};

      const totalSalesData: { dates: string[]; sales: number[] } = {
        dates: [],
        sales: [],
      };

      const allDates: string[] = [];
      let currentDate = dayjs(startDate);
      const endDateDayjs = dayjs(endDate);
      while (currentDate.isBefore(endDateDayjs.add(1, "day"))) {
        const date = currentDate.format("YYYY-MM-DD");
        allDates.push(date);

        categoryNames.forEach((name) => {
          categoryData[name] = {
            dates: [...allDates],
            sales: Array(allDates.length).fill(0),
          };
        });

        totalSalesData.dates.push(date);
        totalSalesData.sales.push(0);

        currentDate = currentDate.add(1, "day");
      }

      orderResponse.forEach((order: any) => {
        const date = order.date.split("T")[0];
        categoryNames.forEach((name) => {
          if (order[name] !== undefined) {
            const dateIndex = allDates.indexOf(date);
            if (dateIndex !== -1) {
              categoryData[name].sales[dateIndex] += order[name];
              totalSalesData.sales[dateIndex] += order[name];
            }
          }
        });
      });

      // console.log("Total Sales Data:", totalSalesData);

      setSeries([{ name: "Total Sales", data: totalSalesData.sales }]);
      setCategoryData(categoryData);
      setChartLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const endDate = dayjs().format("YYYY-MM-DD");
    const startDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
    const newDateRange: string[] = [];
    for (let i = 0; i <= 7; i++) {
      const date = dayjs(startDate).add(i, "day").format("YYYY-MM-DD");
      newDateRange.push(date);
    }
    setDateArray(newDateRange);
    fetchData(startDate, endDate);
  }, []);

  const handleDateRangeChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const startDate = start.format("YYYY-MM-DD");
      const endDate = end.format("YYYY-MM-DD");
      fetchData(startDate, endDate);

      const newDateRange: string[] = [];
      let currentDate = dayjs(startDate);
      const endDateDayjs = dayjs(endDate);
      while (currentDate.isBefore(endDateDayjs.add(1, "day"))) {
        const date = currentDate.format("YYYY-MM-DD");
        newDateRange.push(date);
        currentDate = currentDate.add(1, "day");
      }
      setDateArray(newDateRange);
    }
  };

  const options: ApexOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: true,
        type: "x", // or 'y' or 'xy'
        autoScaleYaxis: true,
      },
      toolbar: {
        tools: {
          pan: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: dateArray,
    },
    yaxis: {},
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const date = w.globals.categoryLabels[dataPointIndex];
        let tooltipContent = `<div class="apexcharts-tooltip-title p-2">${date}</div>`;

        Object.entries(categoryData).forEach(([category, data]) => {
          const value = data.sales[dataPointIndex];
          if (value !== 0) {
            tooltipContent += `<div class="p-2">${category} : ${value}</div>`;
          }
        });

        return tooltipContent;
      },
    },
  };

  return (
    <div
      className="chart-container w-full h-[calc(100%-2rem)] object-cover"
      ref={chartRef}>
      {chartLoaded && (
        <>
          <RangePicker
            defaultValue={[dayjs().subtract(7, "day"), dayjs()]}
            onChange={handleDateRangeChange}
          />
          <Chart options={options} series={series} type="area" height="100%" />
        </>
      )}
    </div>
  );
};

export default DashboardChartSecond;
