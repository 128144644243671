import { axios } from "../../../lib/axios";

export const GetUsersForDashboard = async () => {
  const roleResponse = await axios.get("/role");
  const userRole = roleResponse.data.array.find(
    (role: any) => role.roleName === "user"
  );
  // console.log(userRole);

  if (userRole) {
    let url = `/user?roleId=${userRole.id}`;
    const response = await axios.get(url);
    // console.log(response.data);

    return response.data;
  }
};
