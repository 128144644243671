import React, { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Avatar from "@mui/material/Avatar";
import { Layout, Button, Badge } from "antd";
import { useAuthorization } from "../../lib/authorization";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import { Squash as Hamburger } from "hamburger-react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAuth } from "../../lib/auth";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TransformIcon from "@mui/icons-material/Transform";
// import socket from "../../lib/socket"; // Import the socket instance
import CropFreeIcon from "@mui/icons-material/CropFree";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
// import { requestPermission } from "../../lib/firebase-init";
import { getTokens, onMessageListener } from "../../firebase/firebase";
import { axios } from "../../lib/axios";
import { notification } from "antd";
import storage from "../../utils/storage";

const { Header, Sider } = Layout;

type MainLayoutProps = {
  children: React.ReactNode;
};

type NavBarItem = {
  name: string;
  to: string;
  icon: JSX.Element;
};

const NavBar: React.FC<{ collapsed: boolean; setOpen: any }> = ({
  collapsed,
  setOpen,
}) => {
  // Accept collapsed prop
  const { checkAccess } = useAuthorization();
  const location = useLocation();
  // const { user } = useAuth()
  const navigation: NavBarItem[] = [
    {
      name: "الصفحة الرئيسية",
      to: "/dashboard",
      icon: <DashboardIcon style={{ fontSize: 24 }} />,
    },

    checkAccess({ allowedRoles: [] }) && {
      name: "المستخدمين",
      to: "/users",
      icon: <PersonOutlineOutlinedIcon style={{ fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [] }) && {
      name: "البطاقات",
      to: "/categorys",
      icon: <PaymentIcon style={{ fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [] }) && {
      name: "المدفوعات",
      to: "/users-payment",
      icon: <PaymentsIcon style={{ fontSize: 24 }} />,
    },
    checkAccess({ allowedRoles: [] }) && {
      name: "المعاملات",
      to: "/transactions",
      icon: <ReceiptIcon style={{ fontSize: 24 }} />,
    },
    // checkAccess({ allowedRoles: [] }) && {
    //   name: "التحويلات",
    //   to: "/transfer",
    //   icon: <TransformIcon style={{ fontSize: 24 }} />,
    // },
    checkAccess({ allowedRoles: [] }) && {
      name: "مصدر الحوالة",
      to: "/charge-source",
      icon: <ReceiptLongIcon style={{ fontSize: 24 }} />,
    },
    // {
    //   name: "الاشعارات",
    //   to: "/notification",
    //   icon: <NotificationsIcon style={{ fontSize: 24 }} />,
    // },
    // checkAccess({ allowedRoles: [] }) && {
    //   name: "رمز الواتساب",
    //   to: "/WhatsAppQrCode",
    //   icon: <CropFreeIcon style={{ fontSize: 24 }} />,
    // },
    // checkAccess({ allowedRoles: [] }) && {
    //   name: "اضافة كرت",
    //   to: "/hello",
    //   icon: <PaymentIcon style={{ fontSize: 24 }} />,
    // },
  ].filter(Boolean) as NavBarItem[];
  // Helper function to check if the current path belongs to either "users" or "users-payment"

  // Badge count state for notifications
  const [notificationCount, setNotificationCount] = useState(2);

  // useEffect hook to listen for changes in notification count
  // useEffect(() => {
  //   // Subscribe to the socket event for notification count updates
  //   socket.on("notificationCount", (count: number) => {
  //     // Update the notification count state
  //     setNotificationCount(count);
  //   });

  //   // Unsubscribe from the socket event on component unmount
  //   return () => {
  //     socket.off("notificationCount");
  //   };
  // }, []);

  return (
    <>
      <div className="bg-color2 h-screen flex flex-col">
        <div className="flex flex-col items-center bg-white">
          <Link
            to="/dashboard"
            className=""
            style={{
              color: "inherit", // Inherit color from parent
            }}>
            <div className="flex items-center w-full justify-center cursor-pointer">
              {!collapsed && (
                <div className="font-default-font text-lg pr-6 text-color1">
                  الثقة
                </div>
              )}
              <img
                className="w-8 h-auto my-3"
                src={require("../../assets/Logo.png")}
                alt=""
              />
            </div>
          </Link>
          <div className="mx-4 mt-[2px] border-color1 border-x-0 border-t-0 border-2 w-4/5 rounded-md opacity-60"></div>
        </div>
        <div className="p-2 pt-4">
          {navigation.map((item, index) => (
            <Link
              key={index}
              to={item.to}
              className=""
              style={{
                color: "inherit", // Inherit color from parent
              }}>
              <div
                className={`flex justify-end p-4 items-center font-default-font rounded-md ${
                  location.pathname === item.to ||
                  location.pathname.includes(`${item.to}/`)
                    ? "bg-color1 text-white"
                    : "hover:bg-white text-color1"
                }`}
                onClick={() => setOpen(false)}
                style={{
                  cursor: "pointer", // Add pointer cursor to indicate clickable element
                  textDecoration: "none", // Remove underline
                }}>
                {!collapsed ? (
                  <div className="flex justify-center pr-4">{item.name}</div>
                ) : null}
                {/* {item.name === "الاشعارات" ? (
                  <Badge
                    count={notificationCount}
                    className={`${
                      location.pathname === item.to ||
                      location.pathname.includes(`${item.to}/`)
                        ? "bg-color1 text-white"
                        : "hover:bg-white text-color1"
                    }`}>
                    <div className="pr-1">{item.icon}</div>
                  </Badge>
                ) : (
                  <div className="pr-1">{item.icon}</div>
                )} */}
                <div className="pr-1">{item.icon}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
// const notificationsData = [
//   {
//     id: 1,
//     user: "Sulastri Silami",
//     action: "requests permission to change Project",
//     project: "Nganter App",
//     time: "5 min ago",
//     seen: false,
//   },
//   {
//     id: 2,
//     user: "Michael Dandi",
//     action: "requests permission to change Project",
//     project: "Andromeda Website",
//     time: "21 min ago",
//     seen: false,
//   },
//   {
//     id: 3,
//     user: "Suminah",
//     action: "has added new project",
//     project: "Pakir App",
//     time: "24 min ago",
//     seen: false,
//   },
//   {
//     id: 4,
//     user: "Tri Utomo",
//     action: "has just added a new employee",
//     project: "",
//     time: "30 min ago",
//     seen: false,
//   },
//   {
//     id: 5,
//     user: "Bambang S.",
//     action: "has added a new vendor and changed the client",
//     project: "",
//     time: "1 hour ago",
//     seen: false,
//   },
//   {
//     id: 6,
//     user: "Bambang S.",
//     action: "has added a new client",
//     project: "",
//     time: "2 hours ago",
//     seen: false,
//   },
// ];

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { user, logout } = useAuth();
  const { checkAccess } = useAuthorization();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationList, setNotificationList] = useState<any>([]);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [permissionStatus, setPermissionStatus] =
    useState<NotificationPermission | null>(null);

  const [notificationCount, setNotificationCount] = useState(0);
  const open = Boolean(anchorEl);
  const notificationOpen = Boolean(notificationAnchorEl);
  const location = useLocation();
  const smallNavigator: any = [
    {
      name: "الصفحة الشخصية",
      to: "/me",
      icon: <AssignmentIndIcon style={{ fontSize: 24 }} />,
    },
    {
      name: "المشرفين",
      to: "/admins",
      icon: <AdminPanelSettingsIcon style={{ fontSize: 24 }} />,
    },
  ];
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  // useEffect(() => {
  //   const initializeFirebase = async () => {
  //     const token = await requestPermission();
  //     if (token) {
  //       // Optionally, you can use the token for further actions here
  //       console.log("Received FCM Token:", token);
  //     }
  //   };
  //   initializeFirebase();
  // }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   const initializeFirebase = async () => {
  //     const token = await requestPermission();
  //     if (token) {
  //       await subscribeToTopic(token, "your-topic");
  //     }
  //   };
  //   initializeFirebase();
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 639) {
        setOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleSmallMenuClick = async () => {
    setOpen(false);
    handleClose();
  };
  // const handleAdmin = () => {
  //   navigate(`/admins`);
  //   handleClose();
  // };
  const handleNotificationShow = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const initializeFirebase = async () => {
    let firebaseTempToken = storage.getFirebaseToken();
    const firebaseToken: any = await getTokens();
    // console.log(firebaseTempToken);
    // console.log(firebaseToken);

    if (firebaseTempToken != firebaseToken) {
      storage.setFirebaseToken(firebaseToken);
    }
    // console.log("tokentokentoken", firebaseToken);

    if (firebaseTempToken) {
      try {
        const response = await axios.get(`/notifications/${firebaseTempToken}`);
        // console.log(response.data);

        // Reverse the data array
        const reversedData = response.data.reverse();

        // Count unseen notifications
        let unseenCount = 0;
        reversedData.forEach((notification: any) => {
          if (!notification.seen) {
            unseenCount++;
          }
        });

        // Set notification list and count
        setNotificationList(reversedData);
        setNotificationCount(unseenCount);
      } catch (error) {
        console.error("Error sending token to server:", error);
      }
    }
  };
  useEffect(() => {
    const notifyMe = async () => {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notifications.");
        alert("This browser does not support desktop notifications.");
      } else {
        const permission = Notification.permission;
        setPermissionStatus(permission);

        if (permission === "granted") {
          // console.log("Notification permission already granted.");
        } else if (permission === "denied") {
          // console.log("Notification permission already denied.");
          alert("لقد قمت برفض الإشعارات. يرجى تمكينها في إعدادات المتصفح.");
        } else {
          // Request permission
          console.log("Requesting notification permission...");
          try {
            const newPermission = await Notification.requestPermission();
            setPermissionStatus(newPermission);
            if (newPermission === "granted") {
              // console.log("Notification permission granted.");
            } else {
              // console.log("Notification permission denied.");
            }
          } catch (error) {
            console.error("Error requesting notification permission:", error);
          }
        }
      }
    };

    // Call the function when the component mounts
    notifyMe();
  }, []);
  useEffect(() => {
    const handleMessage = (payload: any) => {
      // console.log("Message payload:", payload);

      // Increment the notification count
      setNotificationCount((prevCount) => prevCount + 1);

      // Close the notification
      handleNotificationClose();

      // console.log("payloadpayloadpayloadpayload", payload);
      let bodyMessage: any;
      if (payload.notification.title === "طلب انشاء مستخدم") {
        const bodyData = JSON.parse(payload.notification.body);
        if (bodyData && bodyData.user) {
          bodyMessage = `المستخدم ${bodyData.user.fullName} قد قدم وثائقه وينتظر الموافقة`;
        }
      } else {
        const bodyData = JSON.parse(payload.notification.body);
        if (bodyData && bodyData.user) {
          bodyMessage = `المستخدم ${bodyData.user.fullName} قد أرسل شحن وينتظر موافقتك`;
        }
      }

      // Show the notification
      notification.open({
        message: payload.notification.title,
        description: bodyMessage,
      });

      // console.log("hi");
    };

    const unsubscribe = onMessageListener(handleMessage);

    initializeFirebase();

    return () => {
      unsubscribe(); // Clean up the subscription on component unmount
    };
  }, []); // The dependency array should remain empty to ensure this effect runs only once
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // User has returned to the tab
        initializeFirebase();
        // console.log("User returned to the website!");
        // Place your logic here
      }
    };

    // Add event listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  function convertUTCDateToLocalDate(date: Date): Date {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  // console.log("notificationList", notificationList);
  const formatTimestamp = (timestamp: any) => {
    const date = convertUTCDateToLocalDate(new Date(timestamp));
    const now = new Date();

    // Calculate the time difference
    const diffMilliseconds = now.getTime() - date.getTime();
    const diffSeconds = Math.floor(diffMilliseconds / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays === 0) {
      if (diffHours === 0) {
        if (diffMinutes === 0) {
          return "Just now";
        } else {
          return `${diffMinutes} min ago`;
        }
      } else {
        return `${diffHours} hours ago`;
      }
    } else {
      // Format date as MMM DD, YYYY
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    }
  };

  const handleNotificationSeen = async (id: any) => {
    try {
      const response = await axios.patch(`notifications/${id}/seen`);
      initializeFirebase();
    } catch (error) {
      console.error(error);
    } finally {
      handleNotificationClose();
    }
  };
  const handleNavigateToNewUser = async (userId: any) => {
    // console.log(userId);
    if (userId) {
      handleNotificationClose();
      navigate(`/users/${userId}`);
    }
  };

  const handleNavigateToCharge = async () => {
    handleNotificationClose();
    navigate(`/users-payment`);
  };

  // console.log(notificationList);

  return (
    <Layout className="h-screen snap-none overflow-hidden">
      <Layout>
        <div className="bg-color2">
          <Header
            className={`bg-color2 flex items-center justify-between`}
            style={{ padding: 0 }}>
            <div className="flex h-full items-center ">
              <div>
                <Avatar
                  alt={user?.fullName}
                  className="m-3"
                  sx={{ width: 40, height: 40 }}
                  onClick={handleClick}>
                  {user?.fullName?.charAt(0)}
                </Avatar>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <div className="flex flex-col items-center justify-center p-2">
                    <div className="p-4 w-full flex flex-col justify-center items-center">
                      <div className="text-color1 font-default-font">
                        اسم المستخدم
                      </div>
                      <div>{user?.fullName}</div>
                    </div>
                    <div className="p-4 w-full flex flex-col justify-center items-center">
                      <div className="text-color1 font-default-font">
                        رقم الهاتف
                      </div>
                      <div>{user?.phoneNumber}</div>
                    </div>

                    {smallNavigator.map((item: any, index: any) => (
                      <div className="w-full m-2" key={index}>
                        <Link
                          to={item.to}
                          className=""
                          style={{
                            color: "inherit", // Inherit color from parent
                          }}>
                          <div
                            className={`flex justify-end p-4 items-center font-default-font rounded-md ${
                              location.pathname === item.to ||
                              location.pathname.includes(`${item.to}/`)
                                ? "bg-color1 text-white"
                                : "hover:bg-white text-color1"
                            }`}
                            onClick={() => handleSmallMenuClick()}
                            style={{
                              cursor: "pointer", // Add pointer cursor to indicate clickable element
                              textDecoration: "none", // Remove underline
                            }}>
                            {!collapsed ? (
                              <div className="flex justify-center pr-4">
                                {item.name}
                              </div>
                            ) : null}

                            <div className="pr-1">{item.icon}</div>
                          </div>
                        </Link>
                      </div>
                    ))}
                    {checkAccess({ allowedRoles: ["SUPERADMIN"] }) && (
                      // <div
                      //   className="p-4 w-full cursor-pointer flex justify-center"
                      //   onClick={() => handleAdmin()}>
                      //   <div>المشرفين</div>
                      // </div>
                      <div className="w-full m-2">
                        <Link
                          to=""
                          className=""
                          style={{
                            color: "inherit", // Inherit color from parent
                          }}>
                          <div
                            className={`flex justify-end p-4 items-center font-default-font rounded-md hover:bg-white text-color1`}
                            onClick={handleLogout}
                            style={{
                              cursor: "pointer", // Add pointer cursor to indicate clickable element
                              textDecoration: "none", // Remove underline
                            }}>
                            {!collapsed ? (
                              <div className="flex justify-center pr-4">
                                {/* {item.name} */}
                                تسجيل الخروج
                              </div>
                            ) : null}

                            <div className="pr-1">
                              {/* {item.icon} */}
                              <LogoutIcon />
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                </Menu>
              </div>
              <div className="m-4">
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={(e: any) => handleNotificationShow(e)}>
                  <Badge count={notificationCount}>
                    <div className="">
                      <NotificationsIcon
                        style={{ fontSize: 24 }}
                        className="text-color1"
                      />
                    </div>
                  </Badge>
                </IconButton>
                <Menu
                  id="notification-menu"
                  anchorEl={notificationAnchorEl}
                  open={notificationOpen}
                  onClose={handleNotificationClose}
                  className="notification-menu"
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <div className="p-4 w-full flex justify-end font-bold">
                    الاشعارات
                  </div>
                  <div className="h-px bg-black" aria-hidden="true" />
                  <div className="notification-list overflow-y-auto max-h-[400px]">
                    {notificationList.length === 0 ? (
                      <div className="text-center p-4 cursor-pointer relative w-full px-20 text-gray-500">
                        لا توجد إشعارات
                      </div>
                    ) : (
                      notificationList.map((notification: any) => {
                        let bodyMessage = notification.body;
                        let userId: any;

                        if (notification.title === "طلب انشاء مستخدم") {
                          const bodyData = JSON.parse(notification.body);
                          if (bodyData && bodyData.user) {
                            bodyMessage = `المستخدم ${bodyData.user.fullName} قد قدم وثائقه وينتظر الموافقة`;
                            userId = bodyData.account.userId;
                          }
                        } else if (notification.title === "طلب شحن") {
                          const bodyData = JSON.parse(notification.body);
                          if (bodyData && bodyData.user) {
                            bodyMessage = `المستخدم ${bodyData.user.fullName} قد أرسل شحن وينتظر موافقتك`;
                            userId = bodyData.userId;
                          }
                        }

                        return (
                          <div
                            key={notification.id}
                            onClick={() => {
                              handleNotificationSeen(notification.id);
                              if (notification.title === "طلب انشاء مستخدم") {
                                handleNavigateToNewUser(userId);
                              } else {
                                handleNavigateToCharge();
                              }
                            }}
                            className={`cursor-pointer relative w-full pt-4 ${
                              notification.seen
                                ? "bg-white text-black"
                                : "bg-gray-200 text-black"
                            }`}>
                            <div className="mr-32 pl-4 w-full">
                              <div key={notification.id}>
                                <div className="font-bold w-full flex justify-end pr-4">
                                  {notification.title === "طلب انشاء مستخدم"
                                    ? "طلب انشاء مستخدم"
                                    : "طلب شحن"}
                                </div>
                                <div className="flex justify-end w-full flex-col items-end pr-4">
                                  {bodyMessage
                                    .split("\n")
                                    .map((line: any, index: any) => (
                                      <div
                                        key={index}
                                        className="text-sm text-gray-700 py-1">
                                        {line}
                                      </div>
                                    ))}
                                </div>
                                <div className="text-xs flex w-full justify-start pb-2">
                                  {formatTimestamp(notification.createdAt)}
                                </div>
                              </div>
                            </div>
                            <div
                              className="absolute bottom-0 left-0 right-0 h-px bg-slate-300"
                              aria-hidden="true"
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                </Menu>
              </div>
            </div>
            <div style={{ float: "right" }}>
              <div className="hidden sm:flex">
                <Button
                  type="text"
                  icon={
                    collapsed ? (
                      <MenuFoldOutlined className="text-color1" />
                    ) : (
                      <MenuUnfoldOutlined className="text-color1" />
                    )
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                  }}
                />
              </div>
              <div
                className="flex sm:hidden justify-center items-center h-full w-full"
                style={{ width: 64, height: 64 }}
                onClick={() => setCollapsed(false)}>
                <Hamburger
                  color="#29667E"
                  size={20}
                  toggled={isOpen}
                  toggle={setOpen}
                />
              </div>
            </div>
          </Header>
          {isOpen ? (
            <div className="p-2 pt-4">
              <NavBar collapsed={collapsed} setOpen={setOpen} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="overflow-y-auto">{children}</div>
      </Layout>
      <div className="h-screen  hidden sm:flex">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <NavBar collapsed={collapsed} setOpen={setOpen} />
        </Sider>
      </div>
    </Layout>
  );
};
