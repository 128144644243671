import React, { useState } from "react";
import { CategorysMenu } from "./CategorysMenu";
import { Loading } from "../../../components/elements/loading";
import { Categorys } from "./Categorys";

import { Card } from "@mui/material";

export const CategorysList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [CategorysData, setCategorysData] = useState<any>([]);
  // console.log(CategorysData);

  return (
    <>
      <CategorysMenu
        setCategorysData={setCategorysData}
        setIsLoading={setIsLoading}
      />
      <div>
        <div className="mx-4 mb-4 flex flex-col">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="w-full">
              <div className="mt-4"></div>
              {CategorysData.length === 0 ? (
                <div className=" flex justify-center items-center">
                  <div className="mt-4">No cards available.</div>
                </div>
              ) : (
                CategorysData.map(
                  (data: any, index: React.Key | null | undefined) => (
                    <Card key={index} className="mt-4 w-full">
                      <Categorys data={data} />
                    </Card>
                  )
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
