import { axios } from "../../../lib/axios";
import { Category } from "../types"; // Assuming you have a Category type defined in your types.ts file

export const createCategory = async (categoryData: Category) => {
  try {
    const response = await axios.post(`/category`, categoryData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Handle error here
    throw new Error("Error creating category: " + error);
  }
};
