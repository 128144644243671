// chargeSourceService.ts
import { axios } from "../../../lib/axios";
import { DataStructure } from "../types/getChargeSource"; // Adjust the import path as necessary

export const getChargeSource = async (): Promise<DataStructure> => {
  try {
    const response = await axios.get("/chargeSource");
    // console.log(response.data);
    return response.data as DataStructure; // Ensure the response matches the expected type
  } catch (error) {
    console.error("Error fetching charge source:", error);
    throw new Error("Error fetching charge source: " + error);
  }
};
