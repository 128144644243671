import React from "react";
import { Button } from "antd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import { CardsList } from "./CardsList";
import { API_URL } from "../../../config";

export const Categorys = ({ data }: any) => {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleUserClick = (CategoryId: number) => {
    // Navigate to the user details page using the user ID
    navigate(`/categorys/${CategoryId}`); // Assuming the route for user details is '/users/:userId'
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // console.log(`url(${API_URL}/cards/download?path=${data.imageUrl})`);
  // console.log(data);

  return (
    <div className="w-full bg-color2 rounded-md h-full flex flex-col justify-end">
      <div
        className="flex justify-end items-center cursor-pointer"
        onClick={() => handleUserClick(data.id)}>
        <div className="flex w-full justify-between">
          <div
            className="flex m-10 items-center"
            onClick={(e) => {
              e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
            }}>
            <Button
              type="text"
              icon={
                expanded ? (
                  <ExpandLessIcon className="text-color1" />
                ) : (
                  <ExpandMoreIcon className="text-color1" />
                )
              }
              onClick={handleExpandClick}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </div>
          <div
            className="flex flex-col justify-center"
            style={{ direction: "rtl" }}>
            <div className="text-3xl font-semibold flex">
              <div className="w-4"></div>
              <div>{data.name}</div>
            </div>
            <div className="flex items-center">
              <div className="w-10 flex justify-center">
                <FiberManualRecordIcon
                  sx={{ color: "#29667E" }}
                  fontSize="small"
                />
              </div>
              <div className="pr-2 text-color1">
                عدد الكروت : {data.countCards}
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-image bg-cover bg-center h-28 w-56 m-2 rounded-r-md"
          style={{
            backgroundImage: `url(${API_URL}/cards/download?path=${data.imageUrl})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}></div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className="flex justify-center pt-6 w-[100%]">
          <CardsList id={data.id} />
        </div>
      </Collapse>
    </div>
  );
};
