import { axios } from "../../../lib/axios";
import { SingleCategory } from "../types";

export const GetSingleCategoryCard = async (
  CategorysId: any,
  name: string = ""
): Promise<any> => {
  const url = `cards?categoryId=${CategorysId}${name ? `&name=${name}` : ""}`;
  const response = await axios.get(url);

  // console.log(response.data);

  return response.data;
};
