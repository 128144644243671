import React from "react";
import { Route, Routes } from "react-router-dom";
import { CategorysWrapper } from "./CategorysWrapper";
import { SingleCategoryWrapper } from "../../singleCategory/routes/SingleCategoryWrapper";

export const CardsRouters = () => {
  return (
    <Routes>
      <Route index element={<CategorysWrapper />} />
      <Route path="/:CategorysId" element={<SingleCategoryWrapper />} />
    </Routes>
  );
};
