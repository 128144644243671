import { axios } from "../../../lib/axios";
// import { UserListResponse } from "../types";

export const DeactiveUser = async (id: any): Promise<any> => {
  const data = {
    status: "deactivated",
  };
  const response = await axios.patch(`User/Status/${id}`, data);

  return response.data;
};
