import React, { useState } from "react";
import { UserCard } from "./UserCard";
import { Loading } from "../../../components/elements/loading";
import { User } from "../types";
import { useNavigate } from "react-router-dom";
import { UserMenu } from "./UserMenu";

export const UserList = () => {
  const colors = [
    "#FC726E",
    "#4BC190",
    "#3D5AFE",
    "#FFB300",
    "#8E24AA",
    "#00ACC1",
    "#FF6F00",
    "#FFD600",
    "#43A047",
  ];
  const navigate = useNavigate(); // Initialize useNavigate

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [UserData, setUserData] = useState<User[]>([]);
  const handleUserClick = (userId: number) => {
    // Navigate to the user details page using the user ID
    navigate(`/users/${userId}`); // Assuming the route for user details is '/users/:userId'
  };
  // console.log(UserData);

  return (
    <>
      <UserMenu setUserData={setUserData} setIsLoading={setIsLoading} />
      <div className="pb-4">
        <div className="flex flex-wrap justify-around">
          {isLoading ? (
            <Loading />
          ) : UserData.length === 0 ? (
            <div className="flex flex-wrap justify-center">
              <div className="mt-4">No users available.</div>
            </div>
          ) : (
            <div className="flex flex-wrap justify-around w-full">
              {UserData.map((user, index) => (
                <div
                  key={index}
                  className="mt-4 p-2 w-fit cursor-pointer rounded-md"
                  onClick={() => handleUserClick(user.id)}>
                  <UserCard
                    user={user}
                    gradientColor={colors[user.id % colors.length]}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
