import { axios } from "../../../lib/axios";
import { SingleCategory } from "../types";

interface FormData {
  name: string;
  amount: number;
  price: number;
  status: string;
  specialPrice: string;
  purchasedPrice: any;
  categoryId: number;
  image: File | null;
}

export const CreateSingleCategoryCard = async (
  formData: FormData
): Promise<SingleCategory> => {
  const {
    name,
    amount,
    price,
    specialPrice,
    categoryId,
    image,
    status,
    purchasedPrice,
  } = formData;

  const formDataToSend: any = {
    name: name,
    amount: amount.toString(),
    price: price.toString(),
    specialPrice: specialPrice,
    categoryId: categoryId.toString(),
    purchasedPrice: purchasedPrice,
    status: status,
    image: image!,
  };
  // console.log(formDataToSend);

  const response = await axios.post(`/cards`, formDataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};
