import React from "react";
import { DashboardWrapper } from "./DashboardWrapper";

export const DashboardProfileWRouters = () => {
  return (
    <>
      <DashboardWrapper />
    </>
  );
};
