import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { NotificationWrapper } from "./notificationWrapper";

export const NotificationRouters = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Routes>
      <Route index element={<NotificationWrapper />} />
    </Routes>
  );
};
