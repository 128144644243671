import { axios } from "../../../lib/axios";
// import { UserListResponse } from "../types";

export const ChangeUserType = async (
  id: any,
  type: any,
  name?: any
): Promise<any> => {
  let data: any;
  // console.log("type", type);

  if (type == "toOffice") {
    data = {
      isAccountOffice: true,
      accountName: name.accountName,
    };
  } else if (type == "toUser") {
    data = {
      isAccountOffice: false,
    };
  }
  // console.log("data", data);

  const response = await axios.patch(`/user/admin/${id}`, data);

  return response.data;
};
