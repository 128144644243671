import { axios } from "../../../lib/axios";
import { ChargeSourceData } from "../types/createChargeSource";

export const createChargeSource = async (
  chargeSourceData: ChargeSourceData
) => {
  try {
    // Transform the data to the required structure
    const transformedData = new FormData();
    transformedData.append("name", chargeSourceData.sourceName);
    transformedData.append("status", "active");
    transformedData.append("description", chargeSourceData.description);
    if (chargeSourceData.image) {
      transformedData.append("image", chargeSourceData.image);
    }

    const response = await axios.post(`/chargeSource`, transformedData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Handle error here
    throw new Error("Error creating chargeSource: " + error);
  }
};
