import { Card, Collapse } from "@mui/material";
import React, { useState } from "react";
import { Button, Modal, Input, Upload } from "antd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CategorysTable } from "./CategorysTable";
import AddIcon from "@mui/icons-material/Add";
import { CreateSingleCategoryCard } from "../api/CreateSingleCategoryCard";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { GetSingleCategoryCard } from "../api/GetSingleCategoryCard";
import { SingleCategory } from "../types";

interface FormData {
  cardName: string;
  price: string;
  specialPrice: any;
  purchasedPrice: any;
  image: File | null;
}

interface Errors {
  cardName: string;
  price: string;
  specialPrice: any;
  purchasedPrice: any;
  image: string;
}

export const CategorysTypes = ({ CategorysId }: any) => {
  const [expanded, setExpanded] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [CardsListData, setCardsListData] = useState<any>([]);
  const [formData, setFormData] = useState<FormData>({
    cardName: "",
    price: "",
    specialPrice: "",
    purchasedPrice: "",
    image: null,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({
    cardName: "",
    price: "",
    specialPrice: "",
    purchasedPrice: "",
    image: "",
  });
  const fetchCardsData = async (name: string = "") => {
    try {
      if (CategorysId) {
        const response: SingleCategory = await GetSingleCategoryCard(
          CategorysId,
          name
        );
        setCardsListData(response.array);
        //  setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    setFormData({
      cardName: "",
      price: "",
      specialPrice: "",
      purchasedPrice: "",
      image: null,
    });
    setErrors({
      cardName: "",
      price: "",
      specialPrice: "",
      purchasedPrice: "",
      image: "",
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors: any = {};

    if (!formData.cardName) {
      newErrors.cardName = "يرجى إدخال اسم الكرت";
    }
    if (!formData.price) {
      newErrors.price = "يرجى إدخال السعر";
    }
    if (!formData.specialPrice) {
      newErrors.specialPrice = "يرجى إدخال السعر الخاص";
    }
    if (!formData.image) {
      newErrors.image = "يرجى اختيار الصورة";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setIsSubmitting(true);
    try {
      // console.log("Form Data:", formData);
      await CreateSingleCategoryCard({
        name: formData.cardName,
        amount: 1, // Provide appropriate value for amount
        price: parseFloat(formData.price),
        specialPrice: formData.specialPrice,
        purchasedPrice: formData.purchasedPrice,
        categoryId: CategorysId, // Provide appropriate categoryId
        image: formData.image,
        status: "active",
      });
      handleOk();
      // Reset form fields and errors after successful submission
      setFormData({
        cardName: "",
        price: "",
        specialPrice: "",
        purchasedPrice: "",
        image: null,
      });
      setErrors({
        cardName: "",
        price: "",
        specialPrice: "",
        purchasedPrice: "",
        image: "",
      });
      // setExpanded(false);
      fetchCardsData();
    } catch (error) {
      console.error("Error creating card:", error);
      // Handle error here
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleImageUpload: UploadProps["beforeUpload"] = (file) => {
    handleChange({
      target: {
        name: "image",
        value: "",
        files: [file],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
    return false; // Prevent upload
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string; files?: File[] } }
  ) => {
    const { name, value, files } = e.target;
    const newValue = files ? files[0] : value; // If it's a file input, use files[0], otherwise use value
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    if (errors[name as keyof Errors]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof Errors]: "",
      }));
    }
  };

  return (
    <div className="p-4">
      <Card className="w-full">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-center items-center">
            <Button
              type="text"
              icon={
                expanded ? (
                  <ExpandLessIcon className="text-color1" />
                ) : (
                  <ExpandMoreIcon className="text-color1" />
                )
              }
              onClick={handleExpandClick}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                margin: 10,
              }}
            />
            <Button
              type="text"
              onClick={showModal}
              style={{
                fontSize: "16px",

                margin: 10,
              }}
              className="flex items-center justify-center w-24 h-20 text-lg ">
              <span className="text-color1 text-lg">اضافة</span>
              <AddIcon className="text-color1 ml-2 w-8" />
            </Button>
          </div>
          <div className="p-5 text-2xl">الكروت</div>
        </div>
        <Modal
          // title="انشاء كرت"
          visible={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose>
          <div className="text-lg mb-4">انشاء كرت</div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="mb-1 flex flex-col items-center">
                <div className="w-full text-lg  flex justify-end items-end">
                  <div className="p-2">اسم الكرت</div>
                </div>
                <Input
                  value={formData.cardName}
                  onChange={handleChange}
                  name="cardName"
                  placeholder="اسم الكرت"
                  style={{ direction: "rtl", textAlign: "right" }}
                  className="w-full"
                />
              </div>
              {errors.cardName && (
                <div className="text-red-500 mb-4 text-sm">
                  {errors.cardName}
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="mb-1 flex flex-col items-center justify-between">
                <div className="w-full text-lg  flex justify-end items-end">
                  <div className="p-2">سعر الشراء</div>
                </div>
                <Input
                  value={formData.purchasedPrice}
                  onChange={handleChange}
                  name="purchasedPrice"
                  placeholder="سعر شراء الكرت"
                  style={{ direction: "rtl", textAlign: "right" }}
                  className="w-full"
                />
              </div>
              {errors.purchasedPrice && (
                <div className="text-red-500 text-sm">
                  {errors.purchasedPrice}
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="mb-1 flex flex-col items-center justify-between">
                <div className="w-full text-lg  flex justify-end items-end">
                  <div className="p-2">السعر للزبون</div>
                </div>
                <Input
                  value={formData.price}
                  onChange={handleChange}
                  name="price"
                  placeholder="سعر الكرت للزبائن"
                  style={{ direction: "rtl", textAlign: "right" }}
                  className="w-full"
                />
              </div>
              {errors.price && (
                <div className="text-red-500 text-sm">{errors.price}</div>
              )}
            </div>
            <div className="mb-4">
              <div className="mb-1 flex flex-col items-center justify-between">
                <div className="w-full text-lg  flex justify-end items-end">
                  <div className="p-2">السعر للوكيل</div>
                </div>
                <Input
                  value={formData.specialPrice}
                  onChange={handleChange}
                  name="specialPrice"
                  placeholder="السعر الكارت للوكلاء"
                  style={{ direction: "rtl", textAlign: "right" }}
                  className="w-full"
                />
              </div>
              {errors.specialPrice && (
                <div className="text-red-500 text-sm">
                  {errors.specialPrice}
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="mb-1 flex flex-col items-center justify-between">
                <div className="w-full text-lg  flex justify-end items-end">
                  <div className="p-2">صورة الكرت</div>
                </div>
                <div className="flex justify-center items-center cursor-pointer w-full">
                  {/* <input
                    type="file"
                    onChange={handleChange}
                    name="image"
                    className="w-full cursor-pointer"
                  /> */}
                  <div className="flex justify-center">
                    <Upload
                      beforeUpload={handleImageUpload}
                      maxCount={1}
                      className="w-full flex flex-col items-center justify-center">
                      <div className="flex justify-center w-full">
                        <Button className="flex items-center justify-center w-full">
                          اضغط لاختيار الصورة
                          <UploadOutlined />
                        </Button>
                      </div>
                    </Upload>
                  </div>
                </div>
              </div>
              {errors.image && (
                <div className="text-red-500 mb-4 text-sm">{errors.image}</div>
              )}
            </div>
            <div className="flex justify-end">
              <Button
                htmlType="submit"
                className="text-color1"
                loading={isSubmitting}>
                ارسال
              </Button>
            </div>
          </form>
        </Modal>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div className="flex justify-center pt-6 w-full">
            <CategorysTable
              CategorysId={CategorysId}
              CardsListData={CardsListData}
              setCardsListData={setCardsListData}
            />
          </div>
        </Collapse>
      </Card>
    </div>
  );
};
