import React from "react";
import { CategorysList } from "../components/CategorysList";

export const CategorysWrapper: any = () => {
  return (
    <div className="w-full overflow-hidden">
      <CategorysList />
    </div>
  );
};
