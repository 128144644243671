import React from "react";
import { Route, Routes } from "react-router-dom";
import { ChargeSourceLayout } from "../components/chargeSourceLayout";

export const ChargeSourceRouters = () => {
  return (
    <Routes>
      <Route index element={<ChargeSourceLayout />} />
    </Routes>
  );
};
