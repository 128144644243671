import React, { useState } from "react";
import { Form, Input } from "antd";
import Button from "@mui/material/Button";
import { LoginCredentialsDTO } from "../api/login";
import { useAuth } from "../../../lib/auth";

export const LoginForm = () => {
  const { login } = useAuth();
  const [error, SetError] = useState(false);
  type FieldType = {
    phoneNumber: string;
    password?: string;
  };

  const onFinish = async (values: FieldType) => {
    const { phoneNumber, password } = values;
    const credentials: LoginCredentialsDTO = {
      phoneNumber: phoneNumber,
      password: password || "", // Assuming the field is required
    };
    try {
      // const response = await loginWithEmailAndPassword(credentials);
      await login(credentials);
      window.location.reload();
    } catch (error) {
      SetError(true);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="">
      {error == true ? (
        <div className="text-[red] pb-2">
          كلمة المرور او رقم الهاتف غير صحيح
        </div>
      ) : (
        <div></div>
      )}
      <Form
        name="basic"
        style={{ maxWidth: 600, direction: "rtl" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          className="p-2 font-default-font"
          label="رقم الهاتف"
          name="phoneNumber"
          rules={[{ required: true, message: "الرجاء إدخال رقم الهاتف!" }]}>
          <Input autoComplete="tel" />
        </Form.Item>

        <Form.Item
          className="p-2 font-default-font"
          label="كلمة السر"
          name="password"
          rules={[{ required: true, message: "الرجاء إدخال كلمة السر!" }]}>
          <Input.Password autoComplete="current-password" />
        </Form.Item>

        <Form.Item>
          <Button
            type="submit"
            variant="contained"
            className="bg-color1 font-default-font">
            تسجيل الدخول
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
