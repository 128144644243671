import React from "react";
import { ChargeTable } from "./chargeTable";

export const ChargeLayout = () => {
  return (
    <div className="">
      <ChargeTable />
    </div>
  );
};
