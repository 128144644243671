import React, { useEffect, useState } from "react";
import { Loading } from "../../../components/elements/loading";
import { GetAdmins } from "../api/getAdmins";
import { AdminCard } from "./AdminCard";
import { Admin, AdminResponse } from "../types/adminsType";
import { AdminMenu } from "./AdminMenu";
export const AdminsList = () => {
  const colors = [
    "#FC726E",
    "#4BC190",
    "#3D5AFE",
    "#FFB300",
    "#8E24AA",
    "#00ACC1",
    "#FF6F00",
    "#FFD600",
    "#43A047",
  ];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [adminsData, setAdminsData] = useState<Admin[]>([]);
  const [adminDataCount, setAdminDataCount] = useState<number>();
  const fetchAdminData = async () => {
    try {
      const response: AdminResponse = await GetAdmins();

      if (response) {
        setAdminDataCount(response.count);
        setAdminsData(response.array);
      }
    } catch (error) {
      console.error("Error fetching admin data:", error);
    } finally {
      setIsLoading(false);
      console.log("Finished fetching admin data");
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, []);

  return (
    <>
      <AdminMenu
        fetchAdminData={fetchAdminData}
        adminDataCount={adminDataCount}
        setIsLoading={setIsLoading}
      />
      <div className="pb-4">
        <div className="flex flex-wrap justify-around">
          {isLoading ? (
            <Loading />
          ) : adminsData.length === 0 ? (
            <div className="flex flex-wrap justify-center">
              <div className="mt-4">.لايتوفر مشرفين </div>
            </div>
          ) : (
            <div className="flex flex-wrap justify-around w-full">
              {adminsData.map((admin, index) => (
                <div key={index} className="mt-4 p-2 w-fit rounded-md">
                  <AdminCard
                    AdminData={admin}
                    fetchAdminData={fetchAdminData}
                    gradientColor={colors[admin.id % colors.length]}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
