import React, { useState } from "react";
import "../../users/components/style.css";
import { Card } from "@mui/material";
import {
  Avatar,
  Button,
  Input,
  Modal,
  Popconfirm,
  PopconfirmProps,
} from "antd";
import { useAuth } from "../../../lib/auth";
import { ChangePassword } from "../api/changePassword"; // Assuming this is the correct path to your ChangePassword function

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { AdminCardProps } from "../types/adminsType";
import { useAuthorization } from "../../../lib/authorization";
import { DeleteAdmin } from "../api/deleteAdmin";

export const AdminCard = ({
  gradientColor,
  AdminData,
  fetchAdminData,
}: AdminCardProps) => {
  const { id, fullName, email, phoneNumber } = AdminData;
  // console.log(AdminData);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // const [passwordVisible, setPasswordVisible] = useState(false);
  const { checkAccess } = useAuthorization();
  const [formData, setFormData] = useState<any>({
    // oldPassword: "",
    NewPassword: "",
  });
  const [errors, setErrors] = useState<any>({
    // oldPassword: "",
    NewPassword: "",
  });

  const [isPasswordChangeSubmitting, setIsPasswordChangeSubmitting] =
    useState<boolean>(false);
  const [isDeletingSubmitting, setIsDeletingSubmitting] =
    useState<boolean>(false);

  const { user } = useAuth();

  const showModal = () => {
    setFormData({
      // oldPassword: "",
      NewPassword: "",
    });
    setErrors({
      // oldPassword: "",
      NewPassword: "",
    });
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const categoryDeleteCancel: PopconfirmProps["onCancel"] = () => {};

  const categoryDeleteConfirm: PopconfirmProps["onConfirm"] = async () => {
    setIsDeletingSubmitting(true);
    try {
      const response = await DeleteAdmin(id);

      if (response.status === 200 || response.status === 204) {
        fetchAdminData();
      }
    } catch (error) {
      console.error("Error deleting admin:", error);
    } finally {
      setIsDeletingSubmitting(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(id);

    const { NewPassword } = formData;

    if (!NewPassword) {
      setErrors({
        // oldPassword: oldPassword ? "" : "يرجى ادخال كلمة السر القديمة",
        NewPassword: NewPassword ? "" : "يرجى ادخال كلمة السر الجديدة",
      });
      return;
    }
    setIsPasswordChangeSubmitting(true);
    try {
      // console.log(user?.role.roleName);

      if (user?.role.roleName == "admin") {
        await ChangePassword(user, NewPassword); // Pass the id here
        setFormData({
          // oldPassword: "",
          NewPassword: "",
        });
        setErrors({
          // oldPassword: "",
          NewPassword: "",
        });
        handleOk();
      } else if (user?.role.roleName == "superadmin") {
        await ChangePassword(user, NewPassword, id); // Pass the id here
        setFormData({
          // oldPassword: "",
          NewPassword: "",
        });
        setErrors({
          // oldPassword: "",
          NewPassword: "",
        });
        handleOk();
      }
    } catch (error: any) {
      const errorMessage = error.message || "حدث خطأ ما";
      // console.log(errorMessage);

      if (errorMessage === "كملة المرور القديمة غير صحيحة") {
        setErrors({
          // oldPassword: "كملة المرور القديمة غير صحيحة",
          NewPassword: "",
        });
      } else {
        console.error("Error changing password:", error);
        // Handle other errors here, for example setting a generic error message
        setErrors({
          // oldPassword: "",
          NewPassword: "حدث خطأ ما",
        });
      }
    } finally {
      setIsPasswordChangeSubmitting(false);
    }
  };
  // console.log(checkAccess({ allowedRoles: ["ADMIN"] }));

  const linearGradientId = `linear-gradient-${Math.random()}`;
  const patternId = `pattern-${Math.random()}`;

  return (
    <Card className="h-fit w-full">
      <Modal
        title="تغيير كلمة المرور"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose>
        <form onSubmit={handleSubmit} className="mt-6">
          {/* <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg flex justify-end items-end">
                <div className="p-4">كلمة المرور القديمة</div>
              </div>
              <Input.Password
                value={formData.oldPassword}
                onChange={handleChange}
                name="oldPassword"
                type="password"
                className="w-full"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            {errors.oldPassword && (
              <div className="text-red-500 mb-4 text-sm">
                {errors.oldPassword}
              </div>
            )}
          </div> */}
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-4">كلمة المرور الجديدة</div>
              </div>
              <Input.Password
                value={formData.NewPassword}
                onChange={handleChange}
                name="NewPassword"
                type="password"
                className="w-full"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            {errors.NewPassword && (
              <div className="text-red-500 mb-4 text-sm">
                {errors.NewPassword}
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              htmlType="submit"
              className="text-color1"
              loading={isPasswordChangeSubmitting}>
              تغيير
            </Button>
          </div>
        </form>
      </Modal>
      <div className="card">
        <div className="card__img">
          <svg xmlns="http://www.w3.org/2000/svg">
            <rect fill="#ffffff"></rect>
            <defs>
              <linearGradient
                id={linearGradientId}
                gradientUnits="userSpaceOnUse"
                x1="0"
                x2="0"
                y1="0"
                y2="100%"
                gradientTransform="rotate(222,648,379)">
                <stop offset="0" stopColor="#ffffff"></stop>
                <stop offset="1" stopColor={gradientColor}></stop>
              </linearGradient>
              <pattern
                patternUnits="userSpaceOnUse"
                id={patternId}
                width="300"
                height="250"
                x="0"
                y="0"
                viewBox="0 0 1080 900">
                <g fillOpacity="0.5">
                  <polygon fill="#444" points="90 150 0 300 180 300"></polygon>
                  <polygon points="90 150 180 0 0 0"></polygon>
                  <polygon fill="#AAA" points="270 150 360 0 180 0"></polygon>
                  <polygon
                    fill="#DDD"
                    points="450 150 360 300 540 300"></polygon>
                  <polygon fill="#999" points="450 150 540 0 360 0"></polygon>
                  <polygon points="630 150 540 300 720 300"></polygon>
                  <polygon fill="#DDD" points="630 150 720 0 540 0"></polygon>
                  <polygon
                    fill="#444"
                    points="810 150 720 300 900 300"></polygon>
                  <polygon fill="#FFF" points="810 150 900 0 720 0"></polygon>
                  <polygon
                    fill="#DDD"
                    points="990 150 900 300 1080 300"></polygon>
                  <polygon fill="#444" points="990 150 1080 0 900 0"></polygon>
                  <polygon fill="#DDD" points="90 450 0 600 180 600"></polygon>
                  <polygon points="90 450 180 300 0 300"></polygon>
                  <polygon
                    fill="#666"
                    points="270 450 180 600 360 600"></polygon>
                  <polygon
                    fill="#AAA"
                    points="270 450 360 300 180 300"></polygon>
                  <polygon
                    fill="#DDD"
                    points="450 450 360 600 540 600"></polygon>
                  <polygon
                    fill="#999"
                    points="450 450 540 300 360 300"></polygon>
                  <polygon
                    fill="#999"
                    points="630 450 540 600 720 600"></polygon>
                  <polygon
                    fill="#FFF"
                    points="630 450 720 300 540 300"></polygon>
                  <polygon points="810 450 720 600 900 600"></polygon>
                  <polygon
                    fill="#DDD"
                    points="810 450 900 300 720 300"></polygon>
                  <polygon
                    fill="#AAA"
                    points="990 450 900 600 1080 600"></polygon>
                  <polygon
                    fill="#444"
                    points="990 450 1080 300 900 300"></polygon>
                  <polygon fill="#222" points="90 750 0 900 180 900"></polygon>
                  <polygon points="270 750 180 900 360 900"></polygon>
                  <polygon
                    fill="#DDD"
                    points="270 750 360 600 180 600"></polygon>
                  <polygon points="450 750 540 600 360 600"></polygon>
                  <polygon points="630 750 540 900 720 900"></polygon>
                  <polygon
                    fill="#444"
                    points="630 750 720 600 540 600"></polygon>
                  <polygon
                    fill="#AAA"
                    points="810 750 720 900 900 900"></polygon>
                  <polygon
                    fill="#666"
                    points="810 750 900 600 720 600"></polygon>
                  <polygon
                    fill="#999"
                    points="990 750 900 900 1080 900"></polygon>
                  <polygon fill="#999" points="180 0 90 150 270 150"></polygon>
                  <polygon fill="#444" points="360 0 270 150 450 150"></polygon>
                  <polygon fill="#FFF" points="540 0 450 150 630 150"></polygon>
                  <polygon points="900 0 810 150 990 150"></polygon>
                  <polygon fill="#222" points="0 300 -90 450 90 450"></polygon>
                  <polygon fill="#FFF" points="0 300 90 150 -90 150"></polygon>
                  <polygon
                    fill="#FFF"
                    points="180 300 90 450 270 450"></polygon>
                  <polygon
                    fill="#666"
                    points="180 300 270 150 90 150"></polygon>
                  <polygon
                    fill="#222"
                    points="360 300 270 450 450 450"></polygon>
                  <polygon
                    fill="#FFF"
                    points="360 300 450 150 270 150"></polygon>
                  <polygon
                    fill="#444"
                    points="540 300 450 450 630 450"></polygon>
                  <polygon
                    fill="#222"
                    points="540 300 630 150 450 150"></polygon>
                  <polygon
                    fill="#AAA"
                    points="720 300 630 450 810 450"></polygon>
                  <polygon
                    fill="#666"
                    points="720 300 810 150 630 150"></polygon>
                  <polygon
                    fill="#FFF"
                    points="900 300 810 450 990 450"></polygon>
                  <polygon
                    fill="#999"
                    points="900 300 990 150 810 150"></polygon>
                  <polygon points="0 600 -90 750 90 750"></polygon>
                  <polygon fill="#666" points="0 600 90 450 -90 450"></polygon>
                  <polygon
                    fill="#AAA"
                    points="180 600 90 750 270 750"></polygon>
                  <polygon
                    fill="#444"
                    points="180 600 270 450 90 450"></polygon>
                  <polygon
                    fill="#444"
                    points="360 600 270 750 450 750"></polygon>
                  <polygon
                    fill="#999"
                    points="360 600 450 450 270 450"></polygon>
                  <polygon
                    fill="#666"
                    points="540 600 630 450 450 450"></polygon>
                  <polygon
                    fill="#222"
                    points="720 600 630 750 810 750"></polygon>
                  <polygon
                    fill="#FFF"
                    points="900 600 810 750 990 750"></polygon>
                  <polygon
                    fill="#222"
                    points="900 600 990 450 810 450"></polygon>
                  <polygon fill="#DDD" points="0 900 90 750 -90 750"></polygon>
                  <polygon
                    fill="#444"
                    points="180 900 270 750 90 750"></polygon>
                  <polygon
                    fill="#FFF"
                    points="360 900 450 750 270 750"></polygon>
                  <polygon
                    fill="#AAA"
                    points="540 900 630 750 450 750"></polygon>
                  <polygon
                    fill="#FFF"
                    points="720 900 810 750 630 750"></polygon>
                  <polygon
                    fill="#222"
                    points="900 900 990 750 810 750"></polygon>
                  <polygon
                    fill="#222"
                    points="1080 300 990 450 1170 450"></polygon>
                  <polygon
                    fill="#FFF"
                    points="1080 300 1170 150 990 150"></polygon>
                  <polygon points="1080 600 990 750 1170 750"></polygon>
                  <polygon
                    fill="#666"
                    points="1080 600 1170 450 990 450"></polygon>
                  <polygon
                    fill="#DDD"
                    points="1080 900 1170 750 990 750"></polygon>
                </g>
              </pattern>
            </defs>
            <rect
              x="0"
              y="0"
              fill={`url(#${linearGradientId})`}
              width="100%"
              height="100%"></rect>
            <rect
              x="0"
              y="0"
              fill={`url(#${patternId})`}
              width="100%"
              height="100%"></rect>
          </svg>
        </div>
        <div className="w-full ml-[60%]">
          <Avatar
            className={`card__avatar top-20`}
            size="large"
            style={{ backgroundColor: gradientColor }}>
            {AdminData.fullName.charAt(0)}
          </Avatar>
        </div>
        <div className="card__title">{fullName}</div>
        <div className="card__subtitle">{email}</div>{" "}
        <div className="card__subtitle">{phoneNumber}</div>
        <div className="flex justify-between items-center py-4 w-[30%]"></div>
        {checkAccess({ allowedRoles: ["ADMIN"] }) &&
          (user?.id === id ? (
            <Button type="primary" className="m-5" onClick={showModal}>
              تغيير كلمة المرور
            </Button>
          ) : (
            <div className="m-9"></div>
          ))}
        {checkAccess({ allowedRoles: ["SUPERADMIN"] }) && (
          <div className="flex">
            <Popconfirm
              title="حذف المشرف"
              description="هل انت متاكد من حذف هذه المشرف ؟"
              onConfirm={categoryDeleteConfirm}
              onCancel={categoryDeleteCancel}
              okText="نعم"
              cancelText="لا"
              disabled={isDeletingSubmitting}>
              <Button danger className="m-5" loading={isDeletingSubmitting}>
                حذف المشرف
              </Button>
            </Popconfirm>
            <Button type="primary" className="m-5" onClick={showModal}>
              تغيير كلمة المرور
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};
