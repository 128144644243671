import React, { useEffect, useState } from "react";
import { GetCategory } from "../../categorys/api/getCategorys";
import { CategoryListResponse } from "../../categorys/types";
import { Loading } from "../../../components/elements/loading";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";
import { Button, Input, Space } from "antd";
export const DashboardCategorysList = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [DashboardCategorysData, setDashboardCategorysData] = useState<any>([]);
  const [searchInput, setSearchInput] = useState(""); // State to store the search input value
  const [tempData, setTempData] = useState<any>();
  const handleCategorysClick = (userId: number) => {
    // Navigate to the user details page using the user ID
    navigate(`/categorys/${userId}`); // Assuming the route for user details is '/users/:userId'
  };
  const fetchCategorysData = async () => {
    try {
      const response: CategoryListResponse = await GetCategory();
      setDashboardCategorysData(response.array);
      setTempData(response.array);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  useEffect(() => {
    fetchCategorysData();
  }, []);
  // console.log(DashboardCategorysData);

  const handleChange = (event: any) => {
    setSearchInput(event.target.value); // Update the search input value
  };

  const handleSearch = () => {
    const filteredData = tempData.filter((category: any) => {
      return category.name.toLowerCase().includes(searchInput.toLowerCase());
    });
    setDashboardCategorysData(filteredData);
  };
  return (
    <>
      <div className="mb-4 flex flex-col w-full h-full">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="w-full h-full">
            {DashboardCategorysData.length === 0 ? (
              <div className="mt-10 flex justify-center">
                <div>No cards available.</div>
              </div>
            ) : (
              <>
                <div className="flex h-full w-full p-4">
                  <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container"
                    dotListClass=""
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 3,
                        partialVisibilityGutter: 40,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 1,
                        partialVisibilityGutter: 30,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable>
                    {DashboardCategorysData.map(
                      (data: any, index: React.Key | null | undefined) => (
                        // <div
                        //   key={index}
                        //   className="flex-shrink-0 w-40 h-40 rounded-full overflow-hidden">
                        //   <img
                        //     src={data.image}
                        //     alt={data.name}
                        //     className="w-full h-full object-cover"
                        //   />
                        // </div>
                        <Card
                          key={index}
                          variant="outlined"
                          size="sm"
                          orientation="horizontal"
                          onClick={() => handleCategorysClick(data.id)}
                          sx={{
                            margin: 1,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}>
                          {/* <AspectRatio
                          sx={{
                            flexBasis: `200px`,
                            overflow: "auto",
                          }}> */}
                          <div
                            className="card-image bg-cover bg-center h-20 w-12 rounded-r-md"
                            style={{
                              backgroundImage: `url(${API_URL}/cards/download?path=${data.imageUrl})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                            }}></div>
                          {/* <img src={data.image} alt={data.name} /> */}
                          {/* </AspectRatio> */}
                          <div>
                            <div className="w-full text-2xl">{data.name}</div>
                            <Typography level="body-sm">
                              عدد الكروت : {data.countCards}
                            </Typography>
                          </div>
                        </Card>
                      )
                    )}
                  </Carousel>
                </div>{" "}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
