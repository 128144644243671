import { axios } from "../../../lib/axios";

export const GetChartData = async (todayModified: any, sevenDaysAgo: any) => {
  // console.log(todayModified, sevenDaysAgo);

  const response = await axios.get(`/orders/daily-statistics`, {
    params: {
      startDate: todayModified,
      endDate: sevenDaysAgo,
    },
  });
  // console.log(response);

  return response.data;
};
