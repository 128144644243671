import { axios } from "../../../lib/axios";
import { AdminFormData } from "../types/adminsType";
// import { ChargeSourceData } from "../types/createChargeSource";

export const createAdmin = async (adminData: AdminFormData) => {
  try {
    // Transform the data to the required structure
    const adminDataToSend = new FormData();
    adminDataToSend.append("fullName", adminData.adminName);
    // transformedData.append("status", "active");
    adminDataToSend.append("email", adminData.adminEmail);
    adminDataToSend.append("phoneNumber", adminData.adminPhoneNumber);
    adminDataToSend.append("password", adminData.adminPassword);
    adminDataToSend.append("verificationCode", "00000000");
    const response = await axios.post(`/user/admin`, adminDataToSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Handle error here
    throw new Error("Error creating chargeSource: " + error);
  }
};
