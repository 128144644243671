import React, { useEffect, useState } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import { UserListResponse } from "../types";
import { GetUsers } from "../api/getUsers";
import { Input, Button, Space } from "antd";
import { getUserSearchFilter } from "../api/getUserSearchFilter";

import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { Menu, Dropdown } from "antd";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export const UserMenu = ({ setUserData, setIsLoading }: any) => {
  const [filterState, setFilterState] = useState("AllUsers");
  const [tempData, setTempData] = useState<any>();
  const [searchInput, setSearchInput] = useState(""); // State to store the search input value
  const [dropdownVisible, setDropdownVisible] = useState(false); // State to manage dropdown visibility

  const fetchUserData = async (filter?: any) => {
    try {
      const response: UserListResponse = await GetUsers({ filter });

      if (response) {
        // console.log(response);

        setUserData(response.array);
        setTempData(response.array);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Set dropdown visibility to false if screen size is more than 960 pixels
      if (window.innerWidth > 960) {
        setDropdownVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuItems = [
    {
      name: "جميع المستخدمين",
      icon: <PersonOutlineOutlinedIcon />,
      filter: "AllUsers",
    },
    {
      name: "المستخدمين الموثقين",
      icon: <CheckCircleOutlineOutlinedIcon />,
      filter: "Verified",
    },
    {
      name: "المستخدمين الغير موثقين",
      icon: <DangerousOutlinedIcon />,
      filter: "NotVerified",
    },
    {
      name: "الزبائن",
      icon: <PersonOutlineOutlinedIcon />,
      filter: "User",
    },
    {
      name: "الوكلاء",
      icon: <RecordVoiceOverOutlinedIcon />,
      filter: "Agent",
    },
  ];

  const handleSearch = async () => {
    const response = await getUserSearchFilter(searchInput);
    if (response) {
      setFilterState("AllUsers");
      setUserData(response.array);
      setIsLoading(false);
    }
  };

  const handleMenuItemClick = async (filter: any) => {
    setSearchInput("");
    setFilterState(filter);
    try {
      if (filter === "AllUsers") {
        setUserData(tempData);
      } else {
        const response: UserListResponse = await GetUsers({ filter });

        if (response) {
          setUserData(response.array);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching filtered users:", error);
    }
    // Close the dropdown after selecting an item
    setDropdownVisible(false);
  };

  const handleChange = (event: any) => {
    setSearchInput(event.target.value);
  };

  const menu = (
    <div className="flex bg-color2 rounded-md">
      <div>
        <div className="m-4">
          {menuItems.map((item, index) => (
            <div
              key={index}
              onClick={() => handleMenuItemClick(item.filter)}
              className={`flex justify-end m-1 p-4 items-center font-default-font rounded-md ${
                filterState === item.filter
                  ? "bg-color1 text-white"
                  : "hover:bg-white text-color1"
              }`}
              style={{
                cursor: "pointer",
                textDecoration: "none",
              }}>
              <div className="flex justify-center pr-4">{item.name}</div>
              <div className="pr-1">{item.icon}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-color2">
      <div className="mx-10 border-color1 border-x-0 border-t-0 border-2 w-[95%] rounded-md opacity-60"></div>
      <div className="flex justify-between">
        <div className="flex items-center pl-10">
          <div className="flex items-center">
            <Space.Compact style={{ width: "100%" }}>
              <Input
                value={searchInput}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <Button type="primary" onClick={() => handleSearch()}>
                بحث
              </Button>
            </Space.Compact>
          </div>
        </div>
        <div className="flex flex-row-reverse m-4">
          <div className="sm:hidden md:flex hidden flex-row-reverse m-4">
            {menuItems.map((item, index) => (
              <div
                key={index}
                onClick={() => handleMenuItemClick(item.filter)}
                className={`flex justify-end m-1 p-2 items-center font-default-font rounded-md ${
                  filterState === item.filter
                    ? "bg-color1 text-white"
                    : "hover:bg-white text-color1"
                }`}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}>
                <div className="flex justify-center pr-4 p-2">{item.name}</div>
                <div className="pr-1">{item.icon}</div>
              </div>
            ))}
          </div>
          <div className="sm:flex md:hidden flex-row-reverse m-4">
            <Dropdown
              placement="bottomRight"
              overlay={menu}
              trigger={["click"]}
              visible={dropdownVisible} // Set visibility based on state
              onVisibleChange={(visible) => setDropdownVisible(visible)} // Update state on visibility change
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => {
                  e.preventDefault();
                  setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility on click
                }}>
                <FilterAltIcon className="text-color1 text-4xl" />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};
