import { axios } from "../../../lib/axios";

export const activeChargeSource = async (id: number) => {
  try {
    const data = {
      status: "active",
    };
    const response = await axios.patch(`/chargeSource/${id}`, data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Error fetching users: " + error);
  }
};
