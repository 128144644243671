import { Button, Input, Modal, Space, Upload } from "antd";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { createChargeSource } from "../api/createChargeSource";
import { getChargeSource } from "../api/getChargeSource";
import TextArea from "antd/es/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";

interface FormData {
  sourceName: string;
  description: string;
  image: File | null;
}

interface Errors {
  sourceName: string;
  description: string;
  image: string | null;
}

export const ChargeSourceMenu = ({
  setChargeSourceData,
  chargeSourceDataCount,
  setIsLoading,
}: any) => {
  // const [searchInput, setSearchInput] = useState(""); // State to store the search input value
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [isInfoEditSubmitting, setIsInfoEditSubmitting] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    sourceName: "",
    description: "",
    image: null,
  });
  const [errors, setErrors] = useState<Errors>({
    sourceName: "",
    description: "",
    image: null,
  });
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم اضافة مصدر الحوالة بنجاح",
      description: "تم اضافة مصدر الحوالة بنجاح",
      placement,
      duration: 3,
    });
  };
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchInput(event.target.value);
  // };

  const handleSearch = async () => {
    // const response = await getUserSearchFilter(searchInput);
    // if (response) {
    //   setFilterState("AllUsers");
    //   setUserData(response.array);
    //   setIsLoading(false);
    // }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    const newValue = name === "image" ? (files ? files[0] : null) : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    if (errors[name as keyof Errors]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof Errors]: "",
      }));
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    setFormData({
      sourceName: "",
      description: "",
      image: null,
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchChargeSourceData = async () => {
    try {
      const response: any = await getChargeSource();
      // console.log(response);
      setChargeSourceData(response.array);
      // setTempData(response.array);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.sourceName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        sourceName: "يرجى إدخال اسم المصدر",
      }));
      return;
    }
    if (!formData.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "يرجى اختيار الصورة",
      }));
      return;
    }
    setIsInfoEditSubmitting(true);
    try {
      const categoryData: FormData = {
        sourceName: formData.sourceName,
        description: formData.description,
        image: formData.image,
      };
      // console.log(categoryData);

      // // Make the API request to create a new category
      const response = await createChargeSource(categoryData);
      if (response) {
        fetchChargeSourceData();
        openNotification("topRight");
        // Reset form fields and errors after successful submission
        setFormData({
          sourceName: "",
          description: "",
          image: null,
        });
        setErrors({
          sourceName: "",
          description: "",
          image: null,
        });

        handleOk();
      }
    } catch (error) {
      console.error("Error creating category:", error);
      // Handle error here
    } finally {
      setIsInfoEditSubmitting(false);
    }
  };

  useEffect(() => {
    fetchChargeSourceData();
  }, []);
  const handleImageUpload: UploadProps["beforeUpload"] = (file) => {
    handleImageChange({
      target: {
        name: "image",
        value: "",
        files: [file],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
    return false; // Prevent upload
  };
  const handleImageChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string; files?: File[] } }
  ) => {
    const { name, value, files } = e.target;
    const newValue = files ? files[0] : value; // If it's a file input, use files[0], otherwise use value
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    if (errors[name as keyof Errors]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof Errors]: "",
      }));
    }
  };
  return (
    <div className="bg-color2">
      {contextHolder}
      <div className="mx-10 border-color1 border-x-0 border-t-0 border-2 w-[95%] rounded-md opacity-60"></div>
      <Modal
        title="انشاء مصدر للحوالة"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose>
        <form onSubmit={handleSubmit} className="mt-10">
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-4">اسم المصدر</div>
              </div>
              <Input
                value={formData.sourceName}
                onChange={handleInputChange}
                style={{ direction: "rtl", textAlign: "right" }}
                name="sourceName"
                placeholder="اسم المصدر"
                className="w-full"
              />
            </div>
            {errors.sourceName && (
              <div className="text-red-500 text-sm">{errors.sourceName}</div>
            )}
          </div>
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-4">شرح طريقة التحويل</div>
              </div>
              <TextArea
                placeholder="شرح عن طريقة التحويل"
                style={{ direction: "rtl", textAlign: "right" }}
                rows={4}
                value={formData.description}
                allowClear
                onChange={handleInputChange}
                name="description"
              />
            </div>
          </div>
          {/* <div className="mb-4">
            <div className="mb-1 flex items-center justify-between">
              <label className="w-full">
                <input
                  type="file"
                  onChange={handleInputChange}
                  name="image"
                  className="w-full"
                />
              </label>
              <div className="block text-lg w-36 flex justify-end">
                <div> : رابط الصورة</div>
              </div>
            </div>
            {errors.image && (
              <div className="text-red-500 mb-4 text-sm">{errors.image}</div>
            )}
          </div> */}
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-2">صورة مصدر الحوالة</div>
              </div>
              <div className="flex justify-center items-center cursor-pointer w-full">
                <div className="flex justify-center">
                  <Upload
                    beforeUpload={handleImageUpload}
                    maxCount={1}
                    className="w-full flex flex-col items-center justify-center">
                    <div className="flex justify-center w-full">
                      <Button className="flex items-center justify-center w-full">
                        اضغط لاختيار الصورة
                        <UploadOutlined />
                      </Button>
                    </div>
                  </Upload>
                </div>
              </div>
            </div>
            {errors.image && (
              <div className="text-red-500 mb-4 text-sm">{errors.image}</div>
            )}
          </div>
          <div className="flex justify-end">
            <Button
              htmlType="submit"
              className="text-color1"
              loading={isInfoEditSubmitting}>
              ارسال
            </Button>
          </div>
        </form>
      </Modal>
      <div className="flex justify-between items-center">
        {/* <div className="flex items-center pl-10">
          <div className="flex items-center">
            <Space.Compact style={{ width: "100%" }}>
              <Input
                value={searchInput}
                onChange={handleChange}
                // style={{ direction: "rtl", textAlign: "right" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <Button type="primary" onClick={() => handleSearch()}>
                بحث
              </Button>
            </Space.Compact>
          </div>
        </div> */}

        <div className="flex flex-row-reverse m-4 pl-6">
          <Button
            type="text"
            onClick={showModal}
            className="flex items-center justify-center w-24 h-20 text-lg">
            <span className="text-color1 text-lg">اضافة</span>
            <AddIcon className="text-color1 ml-2 w-8" />
          </Button>
        </div>
        <div className="flex flex-row-reverse w-1/2 m-4">
          <div className="m-4">
            <div
              className={`flex justify-end m-1 p-2 items-center font-default-font rounded-md bg-color1 text-white`}
              style={{
                textDecoration: "none", // Remove underline
              }}>
              <div className="flex justify-center pr-4 p-2">
                &nbsp;
                <span>{chargeSourceDataCount}</span>&nbsp;
                <span>: عدد مصادر الحوالة</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
