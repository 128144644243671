import { axios } from "../../../lib/axios";

export const EditChargeSource = async (
  chargeSourceId: any,
  formData: FormData
): Promise<any> => {
  // console.log(formData);

  try {
    const response = await axios.patch(
      `/chargeSource/${chargeSourceId}`,
      formData
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error editing charge source: " + error.message);
  }
};
