// import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User, UserListResponse } from "../../users/types";
import { Avatar, Button, Input, Space } from "antd";
import { Loading } from "../../../components/elements/loading";
import { Card } from "@mui/material";
import { GetUsersForDashboard } from "../api/getUserForDashboard";
import { getUserSearchFilter } from "../../users/api/getUserSearchFilter";

export const DashboardUsersList = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [searchInput, setSearchInput] = useState(""); // State to store the search input value
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [UserData, setUserData] = useState<User[]>([]);
  const handleUserClick = (userId: number) => {
    // Navigate to the user details page using the user ID
    navigate(`/users/${userId}`); // Assuming the route for user details is '/users/:userId'
  };
  const fetchUserData = async () => {
    try {
      const response: UserListResponse = await GetUsersForDashboard();
      // const response: UserListResponse = {
      //   array: [
      //     {
      //       id: 1,
      //       createdAt: "2024-03-31T07:48:58.398Z",
      //       updatedAt: "2024-03-31T07:48:58.398Z",
      //       deletedAt: null,
      //       roleId: 1,
      //       email: "user1@example.com",
      //       fullName: "User One",
      //       phoneNumber: "1234567890",
      //       password: "password",
      //       status: "active",
      //       role: {
      //         id: 1,
      //         createdAt: "",
      //         updatedAt: "",
      //         deletedAt: null,
      //         roleName: "user",
      //       },
      //     },
      //     {
      //       id: 2,
      //       createdAt: "2024-03-31T07:48:58.398Z",
      //       updatedAt: "2024-03-31T07:48:58.398Z",
      //       deletedAt: null,
      //       roleId: 2,
      //       email: "user2@example.com",
      //       fullName: "User Two",
      //       phoneNumber: "0987654321",
      //       password: "password",
      //       status: "active",
      //       role: {
      //         id: 2,
      //         createdAt: "",
      //         updatedAt: "",
      //         deletedAt: null,
      //         roleName: "admin",
      //       },
      //     },
      //     {
      //       id: 3,
      //       createdAt: "2024-03-31T07:48:58.398Z",
      //       updatedAt: "2024-03-31T07:48:58.398Z",
      //       deletedAt: null,
      //       roleId: 1,
      //       email: "user3@example.com",
      //       fullName: "User Three",
      //       phoneNumber: "5555555555",
      //       password: "password",
      //       status: "active",
      //       role: {
      //         id: 1,
      //         createdAt: "",
      //         updatedAt: "",
      //         deletedAt: null,
      //         roleName: "user",
      //       },
      //     },
      //     {
      //       id: 4,
      //       createdAt: "2024-03-31T07:48:58.398Z",
      //       updatedAt: "2024-03-31T07:48:58.398Z",
      //       deletedAt: null,
      //       roleId: 1,
      //       email: "user4@example.com",
      //       fullName: "User Four",
      //       phoneNumber: "1111111111",
      //       password: "password",
      //       status: "active",
      //       role: {
      //         id: 1,
      //         createdAt: "",
      //         updatedAt: "",
      //         deletedAt: null,
      //         roleName: "user",
      //       },
      //     },
      //     {
      //       id: 5,
      //       createdAt: "2024-03-31T07:48:58.398Z",
      //       updatedAt: "2024-03-31T07:48:58.398Z",
      //       deletedAt: null,
      //       roleId: 2,
      //       email: "user5@example.com",
      //       fullName: "User Five",
      //       phoneNumber: "9999999999",
      //       password: "password",
      //       status: "active",
      //       role: {
      //         id: 2,
      //         createdAt: "",
      //         updatedAt: "",
      //         deletedAt: null,
      //         roleName: "admin",
      //       },
      //     },
      //   ],
      //   count: 5, // Total number of users
      // };
      // console.log(response.array);
      setUserData(response.array);
      // console.log(response.array);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };
  const handleChange = (event: any) => {
    setSearchInput(event.target.value); // Update the search input value
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const handleSearch = async () => {
    // Filter CategorysData based on the search input value
    // const filteredData = tempData.filter((category: any) => {
    //   // Implement your search logic based on the search input value
    //   // For example, if you want to search based on category name, replace the condition below with:
    //   // return category.name.toLowerCase().includes(searchInput.toLowerCase());
    //   // Replace the condition below with your actual search logic
    //   return category.name.toLowerCase().includes(searchInput.toLowerCase());
    // });
    // setDashboardCategorysData(filteredData);

    const response = await getUserSearchFilter(searchInput);
    if (response) {
      // console.log(response.array);

      setUserData(response.array);
      // setTempData(response.array);
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col p-2 h-full w-full">
      <div className="p-2 text-xl flex justify-end">
        <div>المستخدمين</div>
      </div>
      {/* <div className="flex items-center w-full p-2">
        <Space.Compact style={{ width: "100%" }}>
          <Input
            value={searchInput}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <Button type="primary" onClick={() => handleSearch()}>
            بحث
          </Button>
        </Space.Compact>
      </div> */}
      <div className="flex flex-col p-2" style={{ overflowY: "auto" }}>
        {isLoading ? (
          <Loading />
        ) : UserData.length === 0 ? (
          <div className="flex justify-center">
            <div className="">No users available.</div>
          </div>
        ) : (
          UserData.map((user, index) => (
            <div
              key={index}
              className="p-2 w-full cursor-pointer"
              onClick={() => handleUserClick(user.id)}
              style={{ maxWidth: "100%" }}>
              <Card className="p-2">
                <div className="flex w-full items-center">
                  <Avatar size="large">{user.fullName.charAt(0)}</Avatar>
                  <div className="pl-4 costim:block sm:hidden">
                    {user.fullName}
                  </div>
                </div>
              </Card>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
