import { axios } from "../../../lib/axios";
// import { UserListResponse } from "../types";

export const GetSingleUserTransactions = async (
  UserId: any,
  offset: number,
  limit: number
): Promise<any> => {
  const response = await axios.get(
    `/transactions/user/${UserId.UserId}?orderDirection=DESC&orderBy=createdAt&relations[]=order&relations[]=transactionType&offset=${offset}&limit=${limit}`
  );
  // console.log(response);

  return response.data;
};
