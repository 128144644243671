import { axios } from "../../../lib/axios";
import { CardsListResponse } from "../types";

export const GetCardsList = async (id: any): Promise<CardsListResponse> => {
  // console.log(`hello ${id}`);

  const response = await axios.get(`cards?categoryId=${id}`);
  console.log(response.data);

  return response.data;
};
