import { UserList } from "../components/UserList";

const UserWrapper: any = () => {
  return (
    <div className="w-full overflow-hidden">
      <UserList />
    </div>
  );
};

export default UserWrapper;
