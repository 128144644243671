import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SingleCategory } from "../types";
import { GetSingleCategory } from "../api/GetSingleCategory";
import { Loading } from "../../../components/elements/loading";
import { Button, Input, Popconfirm, PopconfirmProps, notification } from "antd";
import { API_URL } from "../../../config";
import { EditSingleCategory } from "../api/EditSingleCategory";
import { Image } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import { activeCategorys } from "../api/activeCategorys";
import { deactiveCategorys } from "../api/deactiveCategorys";
import { deleteSingleCategory } from "../api/deleteSingleCategory";
import { useNavigate } from "react-router-dom";
export const CategorysProfile = ({
  CategorysId,
  isCategoryExits,
  setIsCategoryExits,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [SingleCategorysData, setSingleCategorysData] = useState<any>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [newImage, setNewImage] = useState<File | null>(null);
  const [newName, setNewName] = useState<any>();
  const navigator = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  // console.log(CategorysId);
  const [isInfoEditSubmitting, setIsInfoEditSubmitting] =
    useState<boolean>(false);
  const [isDeactivateSubmitting, setIsDeactivateSubmitting] =
    useState<boolean>(false);
  const [isDeletingSubmitting, setIsDeletingSubmitting] =
    useState<boolean>(false);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: "تم تعديل المعلومات بنجاح",
      description: "تم تعديل المعلومات بنجاح",
      placement,
      duration: 3, // Duration in seconds (default is 4.5 seconds)
    });
  };
  const openActivateNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: "تم تفعيل البطاقة بنجاح",
      description: "تم تفعيل البطاقة بنجاح",
      placement,
      duration: 3, // Duration in seconds (default is 4.5 seconds)
    });
  };
  const openDeactivatedNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: "تم الغاء تفعيل البطاقة بنجاح",
      description: "تم الغاء تفعيل البطاقة بنجاح",
      placement,
      duration: 3, // Duration in seconds (default is 4.5 seconds)
    });
  };
  const openDeleteNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: "تم حذف البطاقة بنجاح",
      description: "تم حذف البطاقة بنجاح",
      placement,
      duration: 3, // Duration in seconds (default is 4.5 seconds)
    });
  };
  const fetchSingleCategorysData = async () => {
    try {
      if (CategorysId) {
        const response: any = await GetSingleCategory(CategorysId);
        // console.log("jhgferhtjfdkfler", response);
        if (response) {
          setIsCategoryExits(true);
          setNewName(response.name);
          setSingleCategorysData(response);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching class data:", error);
    }
  };

  useEffect(() => {
    fetchSingleCategorysData();
  }, []);
  // console.log(SingleCategorysData);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = async () => {
    setIsInfoEditSubmitting(true);
    try {
      // setIsLoading(true);

      // Prepare form data for updating name
      const formData: any = {};

      // Append the name to formData
      formData["name"] = newName;

      // Check if a new image is provided
      if (newImage) {
        // Ensure editing is true and a new image has been selected
        formData["image"] = newImage;
      }
      // console.log(formData);
      // console.log(formData, CategorysId);

      // Call the API to update the category
      const response = await EditSingleCategory(formData, CategorysId);
      if (response) {
        openNotification("topRight");
        setEditing(false);
        fetchSingleCategorysData();
      }
      // Update local state
      // setIsLoading(false);
    } catch (error) {
      console.error("Error saving category data:", error);
      setIsLoading(false);
    } finally {
      setIsInfoEditSubmitting(false);
    }
  };
  const categoryDeleteCancel: PopconfirmProps["onCancel"] = () => {};

  const categoryDeleteConfirm: PopconfirmProps["onConfirm"] = async () => {
    setIsDeletingSubmitting(true);
    try {
      // console.log(id);
      const response = await deleteSingleCategory(CategorysId);
      if (response.status === 200) {
        navigator("/categorys");
        openDeleteNotification("topRight");
      }
      // Add any additional logic after successfully accepting the user charge
    } catch (error) {
      // Handle any errors that occur during the process
      console.error("Error accepting user charge:", error);
    } finally {
      setIsDeletingSubmitting(false);
    }
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    // console.log(file);

    if (file) {
      setNewImage(file);
    }
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };
  // console.log(SingleCategorysData);
  const handleActiveCategory = async (e: any) => {
    e.stopPropagation();
    setIsDeactivateSubmitting(true);
    try {
      const response = await activeCategorys(SingleCategorysData.id);

      // Check if the response is successful
      if (response) {
        openActivateNotification("topRight");
        fetchSingleCategorysData();
      }
    } catch (error) {
      console.error("Error activating category:", error);
    } finally {
      setIsDeactivateSubmitting(false);
    }
  };

  const handleDeactiveCategory = async (e: any) => {
    e.stopPropagation();
    setIsDeactivateSubmitting(true);
    try {
      const response = await deactiveCategorys(SingleCategorysData.id);

      // Check if the response is successful
      if (response) {
        openDeactivatedNotification("topRight");
        fetchSingleCategorysData();
      }
    } catch (error) {
      console.error("Error deactivating category:", error);
    } finally {
      setIsDeactivateSubmitting(false);
    }
  };

  return isLoading ? (
    <Loading />
  ) : isCategoryExits ? (
    <div className="p-4">
      {contextHolder}
      <Card className="w-full">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-center items-center md:flex-row flex-col">
            <Popconfirm
              title="حذف البطاقة"
              description="هل انت متاكد من حذف هذه البطاقة ؟"
              onConfirm={() => categoryDeleteConfirm()}
              onCancel={categoryDeleteCancel}
              okText="نعم"
              cancelText="لا">
              <Button
                danger
                type="primary"
                className="m-5"
                loading={isDeletingSubmitting}>
                حذف
              </Button>
            </Popconfirm>
            {SingleCategorysData.status === "active" ? (
              <Button
                danger
                className="m-5"
                onClick={(e) => {
                  handleDeactiveCategory(e);
                }}
                loading={isDeactivateSubmitting}>
                ايقاف التفعيل
              </Button>
            ) : (
              <Button
                type="primary"
                className="m-5"
                onClick={(e) => {
                  handleActiveCategory(e);
                }}
                loading={isDeactivateSubmitting}>
                تفعيل
              </Button>
            )}
            {editing === false ? (
              <Button
                type="primary"
                // danger
                className="m-5"
                onClick={(e) => {
                  handleEditClick();
                  e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
                  // console.log("hi");
                }}>
                تعديل
              </Button>
            ) : (
              <div className="flex flex-row items-center justify-center">
                <Button
                  type="primary"
                  className="m-5"
                  onClick={() => handleSaveClick()}
                  loading={isInfoEditSubmitting}>
                  حفظ
                </Button>{" "}
                <Button
                  danger
                  type="default"
                  className="m-5"
                  onClick={() => setEditing(false)}>
                  الغاء
                </Button>
              </div>
            )}
          </div>
          <div className="flex items-center justify-center">
            {editing === true ? (
              <div>
                <Input
                  defaultValue={SingleCategorysData.name}
                  onChange={handleNameChange}
                  // size="small"
                  className="p-5 text-2xl text-center w-56"
                />
              </div>
            ) : (
              <div className="p-5 text-2xl w-56 flex justify-center">
                <div>{SingleCategorysData.name}</div>
              </div>
            )}
            <div className="flex justify-center items-center">
              {editing ? (
                <div className="flex items-center h-24 w-48 m-5">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="bg-cover bg-center rounded-r-md"
                  />
                </div>
              ) : (
                <div className="h-24 w-48 m-5 overflow-hidden">
                  <Image
                    src={
                      SingleCategorysData.imageUrl
                        ? `${API_URL}/cards/download?path=${SingleCategorysData.imageUrl}`
                        : ""
                    }
                    alt="Category Image"
                    height={"100%"}
                    width={"100%"}
                    className="object-contain w-full h-full"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : (
    <div className="w-full flex flex-col items-center pt-10 text-2xl">
      لم نتمكن من العثور على هذه البطاقة
      <Button onClick={() => navigator("/categorys")} className="mt-4">
        الرجوع الى صفحة البطاقات
      </Button>
    </div>
  );
};
