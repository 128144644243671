import React, { useEffect, useState } from "react";
import { Timeline, Button } from "antd";
import { Card } from "@mui/material";

const Home = () => {
  const [cardHeights, setCardHeights] = useState<any>([]);

  const ButtonsOfTimeline = ({ id, label, onClick }: any) => {
    const handleClick = () => {
      onClick(id); // Call onClick function with the id
    };

    return (
      <Button onClick={handleClick} type="primary">
        {label}
      </Button>
    );
  };

  const handleButtonClick = (itemId: number) => {
    console.log("Seen clicked for item with ID:", itemId);
    // Implement your logic to handle the "Seen" action (e.g., mark item as seen, update state, etc.)
  };

  const timelineItems = [
    {
      color: "green",
      label: "2015-09-01",
      children: (
        <div
          className="flex w-full justify-end items-start"
          style={{ height: cardHeights[0] }}>
          <Card className="timeline-card flex flex-col items-center justify-center p-10 w-full bg-color2 relative">
            {/* <div className="w-max">helllllllllllllllllllo</div> */}
            <ButtonsOfTimeline
              id={1}
              label="Seen"
              onClick={handleButtonClick}
            />
          </Card>
        </div>
      ),
    },
    {
      color: "red",
      label: "2015-09-01 09:12:11",
      children: (
        <div
          className="flex w-full justify-end items-start"
          style={{ height: cardHeights[1] }}>
          <Card className="timeline-card flex flex-col items-center justify-center p-10 w-full bg-color2 relative">
            {/* <div className="w-max">helllllllllllllllllllo</div> */}
            <ButtonsOfTimeline
              id={1}
              label="Seen"
              onClick={handleButtonClick}
            />
          </Card>
        </div>
      ),
    },
    {
      color: "red",
      children: (
        <div
          className="flex w-full justify-end items-start"
          style={{ height: cardHeights[2] }}>
          <Card className="timeline-card flex flex-col items-center justify-center p-10 w-full bg-color2 relative">
            {/* <div className="w-max">helllllllllllllllllllo</div> */}
            <ButtonsOfTimeline
              id={1}
              label="Seen"
              onClick={handleButtonClick}
            />
          </Card>
        </div>
      ),
    },
    {
      color: "red",
      label: "2015-09-01 09:12:11",
      children: (
        <div
          className="flex w-full justify-end items-start"
          style={{ height: cardHeights[3] }}>
          <Card className="timeline-card flex flex-col items-center justify-center p-10 w-full bg-color2 relative">
            {/* <div className="w-max">helllllllllllllllllllo</div> */}
            <ButtonsOfTimeline
              id={1}
              label="Seen"
              onClick={handleButtonClick}
            />
          </Card>
        </div>
      ),
    },
  ];

  // Function to calculate and set card heights
  useEffect(() => {
    const heights: any = [];
    document.querySelectorAll(".timeline-card").forEach((card: any, index) => {
      heights[index] = card.offsetHeight;
    });
    setCardHeights(heights);
  }, []);

  return (
    <div className="w-full">
      <Timeline mode="alternate" items={timelineItems}>
        {/* {timelineItems.map((item, index) => (
          <Timeline.Item key={index} label={item.label}>
            <div
              className="flex w-full justify-end items-start"
              style={{ height: cardHeights[index] }}>
              <Card className="timeline-card flex flex-col items-center justify-center p-10 bg-color2 relative">
 
              </Card>
            </div>
          </Timeline.Item>
        ))} */}
      </Timeline>
      <style>
        {`
          .ant-timeline-item-head,
          .ant-timeline-item-head-blue {
            margin-top: 0 !important; /* Default margin */
          }

          .ant-timeline-item-tail {
            inset-block-start: 0 !important; /* Default margin */
          }

          .ant-timeline-item-label {
            margin-top: 0 !important; /* Default margin */
          }

          ${timelineItems
            .map(
              (item, index) => `
            .ant-timeline-item:nth-child(${index + 1}) .ant-timeline-item-head,
            .ant-timeline-item:nth-child(${
              index + 1
            }) .ant-timeline-item-head-blue {
              background-color: ${
                item.color
              } !important; /* Change dot color based on item.color */
              margin-top: ${
                cardHeights[index] / 2 - 10
              }px !important; /* Center the head with -10px adjustment */
            }

            .ant-timeline-item:nth-child(${index + 1}) .ant-timeline-item-tail {
              inset-block-start: ${
                cardHeights[index] / 2
              }px !important; /* Center the tail */
            }

            .ant-timeline-item:nth-child(${
              index + 1
            }) .ant-timeline-item-label {
              margin-top: ${
                cardHeights[index] / 2 - 10
              }px !important; /* Align the label */
            }
          `
            )
            .join("")}
        `}
      </style>
    </div>
  );
};

export default Home;
