import React, { useEffect, useState } from "react";
import PaymentIcon from "@mui/icons-material/Payment";
import { GetCategory } from "../api/getCategorys";
import { Category, CategoryListResponse } from "../types";
import AddIcon from "@mui/icons-material/Add";
import { Button, Input, Modal, Space, Upload } from "antd";
import { createCategory } from "../api/createCategorys";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
interface FormData {
  name: string;
  image: any;
}

interface Errors {
  name: string;
  image: any;
}

export const CategorysMenu = ({ setCategorysData, setIsLoading }: any) => {
  const [tempData, setTempData] = useState<any>();
  const [searchInput, setSearchInput] = useState(""); // State to store the search input value
  const [filterState, setFilterState] = useState("AllCards"); // State to store the current filter state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    image: null,
  });
  const [errors, setErrors] = useState<Errors>({
    name: "",
    image: "",
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const fetchCategorysData = async () => {
    try {
      const response: CategoryListResponse = await GetCategory();
      // const response = {
      //   array: [
      //     {
      //       id: 1,
      //       name: "اشتراك نتفليكس",
      //       image: "https://picsum.photos/200/300?random=1",
      //     },
      //     {
      //       id: 2,
      //       name: "بطاقة هدايا متجر التطبيقات",
      //       image: "https://picsum.photos/200/300?random=2",
      //     },
      //     {
      //       id: 3,
      //       name: "عضوية أمازون برايم",
      //       image: "https://picsum.photos/200/300?random=3",
      //     },
      //   ],
      //   count: 3,
      // };

      // console.log(response);
      setCategorysData(response.array);
      setTempData(response.array);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  useEffect(() => {
    fetchCategorysData();
  }, []);
  // Define menu items with their corresponding icons and paths
  const menuItems = [
    {
      name: "جميع الكروت",
      icon: <PaymentIcon />,
      filter: "AllCards",
    },
    // {
    //   name: "على وشك الانتهاء",
    //   icon: <PieChartIcon />,
    //   filter: "AboutToEnd",
    // },
    // {
    //   name: "افضل المبيعات",
    //   icon: <MovingIcon />,
    //   filter: "BestSells",
    // },
  ];
  const handleMenuItemClick = async (filter: any) => {
    setSearchInput("");
    setFilterState(filter); // Set the filter state to the clicked filter
    try {
      if (filter === "AllCards") {
        setCategorysData(tempData);
        setSearchInput("");
      } else {
        // Filter the CategorysData based on the selected filter
        const filteredData = tempData.filter((category: any) => {
          return category.status === filter;
        });
        setCategorysData(filteredData);
      }
    } catch (error) {
      console.error("Error fetching filtered categories:", error);
    }
  };
  const handleChange = (event: any) => {
    setSearchInput(event.target.value); // Update the search input value
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    const newValue = name === "image" ? (files ? files[0] : null) : value; // If it's a file input, use files[0], otherwise use value
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    if (errors[name as keyof Errors]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof Errors]: "",
      }));
    }
  };

  const handleSearch = () => {
    const filteredData = tempData.filter((category: any) => {
      return category.name.toLowerCase().includes(searchInput.toLowerCase());
    });
    setCategorysData(filteredData);
  };
  const showModal = () => {
    setIsModalOpen(true);
    setFormData({
      name: "",
      image: null,
    });
    setErrors({
      name: "",
      image: "",
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.name && !formData.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "يرجى إدخال اسم الكرت",
        image: "يرجى إختيار صورة للكرت",
      }));
      return;
    }
    if (!formData.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "يرجى إدخال اسم الكرت",
      }));
      return;
    }
    if (!formData.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "يرجى إختيار صورة للكرت",
      }));
      return;
    }
    setIsSubmitting(true);
    try {
      // Prepare category data from FormData
      const categoryData: Category = {
        name: formData.name,
        image: formData.image,
        status: "active",
      };

      // Make the API request to create a new category
      await createCategory(categoryData);
      // console.log("New category created:", response);
      fetchCategorysData();
      // Reset form fields and errors after successful submission
      setFormData({
        name: "",
        image: null,
      });
      setErrors({
        name: "",
        image: "",
      });

      // Close the modal or perform any other necessary actions
      handleOk();
    } catch (error) {
      console.error("Error creating category:", error);
      // Handle error here
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleImageUpload: UploadProps["beforeUpload"] = (file) => {
    handleImageChange({
      target: {
        name: "image",
        value: "",
        files: [file],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
    return false; // Prevent upload
  };
  const handleImageChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string; files?: File[] } }
  ) => {
    const { name, value, files } = e.target;
    const newValue = files ? files[0] : value; // If it's a file input, use files[0], otherwise use value
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    if (errors[name as keyof Errors]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof Errors]: "",
      }));
    }
  };

  return (
    <div className="bg-color2">
      <div className="mx-10 border-color1 border-x-0 border-t-0 border-2 w-[95%] rounded-md opacity-60"></div>
      <Modal
        // title="انشاء نوع جديد"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose>
        <div className="text-lg mb-4">انشاء نوع جديد</div>
        <form onSubmit={handleSubmit} className="mt-10">
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-4">اسم الكرت</div>
              </div>
              <Input
                value={formData.name}
                onChange={handleInputChange}
                style={{ direction: "rtl", textAlign: "right" }}
                name="name"
                placeholder="اسم النوع"
                className="w-full"
              />
            </div>
            {errors.name && (
              <div className="text-red-500 text-sm">{errors.name}</div>
            )}
          </div>

          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-2">صورة الكرت</div>
              </div>
              <div className="flex justify-center items-center cursor-pointer w-full">
                <div className="flex justify-center">
                  <Upload
                    beforeUpload={handleImageUpload}
                    maxCount={1}
                    className="w-full flex flex-col items-center justify-center">
                    <div className="flex justify-center w-full">
                      <Button className="flex items-center justify-center w-full">
                        اضغط لاختيار الصورة
                        <UploadOutlined />
                      </Button>
                    </div>
                  </Upload>
                </div>
              </div>
            </div>
            {errors.image && (
              <div className="text-red-500 mb-4 text-sm">{errors.image}</div>
            )}
          </div>
          <div className="flex justify-end">
            <Button
              htmlType="submit"
              className="text-color1"
              loading={isSubmitting}>
              ارسال
            </Button>
          </div>
        </form>
      </Modal>
      <div className="flex">
        <div className="md:w-1/2 w-full flex items-center pl-10">
          <div className="flex items-center w-2/3">
            <Space.Compact style={{ width: "100%" }}>
              <Input
                value={searchInput}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <Button type="primary" onClick={handleSearch}>
                بحث
              </Button>
            </Space.Compact>
          </div>
          <div className="pl-4">
            <Button
              type="text"
              onClick={showModal}
              className="flex items-center justify-center w-24 h-20 text-lg">
              <span className="text-color1 text-lg">اضافة</span>
              <AddIcon className="text-color1 ml-2 w-8" />
            </Button>
          </div>
        </div>
        <div className="flex flex-row-reverse w-1/2 m-4">
          <div className="m-4">
            {/* Map through menuItems array to render menu items */}
            {menuItems.map((item, index) => (
              <div
                key={index}
                onClick={() => handleMenuItemClick(item.filter)} // Call handleMenuItemClick function on click
                className={`flex justify-end m-1 p-2 items-center font-default-font rounded-md ${
                  filterState === item.filter
                    ? "bg-color1 text-white"
                    : "hover:bg-white text-color1"
                }`}
                style={{
                  cursor: "pointer", // Add pointer cursor to indicate clickable element
                  textDecoration: "none", // Remove underline
                }}>
                <div className="flex justify-center pr-4 p-2">{item.name}</div>
                <div className="pr-1">{item.icon}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
