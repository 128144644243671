import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import CacheBuster from "react-cache-buster";
import version from "../package.json";

// Define the version and environment
// const version = "1.0.0"; // Update this version to force cache busting
const isProduction = process.env.NODE_ENV === "production";

const AppWithCacheBuster = () => (
  <CacheBuster currentVersion={version} isEnabled={isProduction}>
    <App />
  </CacheBuster>
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppWithCacheBuster />
  </React.StrictMode>
);
