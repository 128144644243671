import { Navigate } from "react-router-dom";
import React from "react";
import { AuthRoutes } from "../features/auth";
// import { lazyImport } from "../utils/lazyImport";
// const { AuthRoutes } = lazyImport(
//   () => import("../features/auth"),
//   "AuthRoutes"
// );

export const publicRoutes = [
  {
    path: "/auth/*",
    element: <AuthRoutes />,
  },
  {
    path: "*",
    element: <Navigate to="/auth" />,
  },
];
