import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import type { PopconfirmProps } from "antd";
import { Modal } from "antd";
import { Button, Image, Input, notification, Popconfirm } from "antd";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { getCharge } from "../api/getCharge";
import { API_URL } from "../../../config";
import { AcceptUserCharge } from "../api/AcceptUserCharge";

import { NotificationPlacement } from "antd/es/notification/interface";
import { Loading } from "../../../components/elements/loading";
import { useNavigate } from "react-router-dom";
import { DeactiveUserCharge } from "../api/DeactiveUserCharge";
import { ChargeMenu } from "./chargeMenu";
export const ChargeTable = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [paymentData, setPaymentData] = useState<any>([]);
  // const [searchInput, setSearchInput] = useState("");
  const [sortedColumn, setSortedColumn] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<any>({
    moneyAmount: "",
  });
  const [errors, setErrors] = useState<any>({
    moneyAmount: "",
  });
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | undefined>(
    undefined
  );
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null); // State to store the selected row id
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isAcceptPaymentSubmitting, setIsAcceptPaymentSubmitting] =
    useState<boolean>(false);

  const [
    isAcceptPaymentWithChangeSubmitting,
    setIsAcceptPaymentWithChangeSubmitting,
  ] = useState<boolean>(false);

  const [isDeletePaymentSubmitting, setIsDeletePaymentSubmitting] =
    useState<boolean>(false);

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم اضافة المبلغ بنجاح",
      description: "تم اضافة المبلغ بنجاح",
      placement,
      duration: 3,
    });
  };
  const paymentConfirm: PopconfirmProps["onConfirm"] = async (id: any) => {
    setIsAcceptPaymentSubmitting(true);
    try {
      // console.log(id);

      const response = await AcceptUserCharge(id);
      if (response) {
        fetchData();
        openNotification("topRight");
      }
      // Add any additional logic after successfully accepting the user charge
    } catch (error) {
      // Handle any errors that occur during the process
      console.error("Error accepting user charge:", error);
    } finally {
      setIsAcceptPaymentSubmitting(false);
    }
  };
  const showModal = (id: any) => {
    setFormData({
      moneyAmount: "",
    });
    setErrors({
      moneyAmount: "",
    });

    setSelectedRowId(id); // Store the selected row id in state
    setIsModalOpen(true); // Open the modal
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedRowId(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedRowId(null);
  };
  const paymentCancel: PopconfirmProps["onCancel"] = (e) => {};

  const paymentDeleteConfirm: PopconfirmProps["onConfirm"] = async (
    id: any
  ) => {
    setIsDeletePaymentSubmitting(true);
    try {
      // console.log(id);

      const response = await DeactiveUserCharge(id);
      if (response) {
        fetchData();
        // openNotification("topRight");
      }
      // Add any additional logic after successfully accepting the user charge
    } catch (error) {
      // Handle any errors that occur during the process
      console.error("Error accepting user charge:", error);
    } finally {
      setIsDeletePaymentSubmitting(false);
    }
  };

  const paymentDeleteCancel: PopconfirmProps["onCancel"] = (e) => {
    // console.log(e);
    // message.error("Click on No");
  };

  const fetchData = async () => {
    // const fakeData = [
    //   {
    //     id: 1,
    //     action: "تحويل",
    //     date: "2024-04-25",
    //     amount: "100 ريال",
    //     user: "محمد",
    //     image: "image_url_1",
    //   },
    //   {
    //     id: 2,
    //     action: "سحب",
    //     date: "2024-04-24",
    //     amount: "50 ريال",
    //     user: "علي",
    //     image: "image_url_2",
    //   },
    //   {
    //     id: 3,
    //     action: "دفع",
    //     date: "2024-04-23",
    //     amount: "75 ريال",
    //     user: "فاطمة",
    //     image: "image_url_3",
    //   },
    // ];
    const response = await getCharge();

    // console.log(fakeData);
    setPaymentData(response.array);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSortChange = (column: string) => {
    // Toggle sorting order if same column is clicked
    if (column === sortedColumn) {
      setSortOrder(sortOrder === "ascend" ? "descend" : "ascend");
    } else {
      setSortedColumn(column);
      setSortOrder("ascend");
    }
  };
  // console.log(new Date());

  const sortedData = paymentData.slice().sort((a: any, b: any) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return sortOrder === "ascend" ? dateA - dateB : dateB - dateA;
  });
  // console.log(`${API_URL}/charge/download?path=QGXDYW6qkCCd.webp`);
  // console.log(sortedData);
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      // If the input value is not numeric, don't update the state
      return;
    }

    // Update the state with the numeric value
    setFormData((prevData: any) => ({
      ...prevData,
      moneyAmount: inputValue,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(formData);

    if (!formData.moneyAmount) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        moneyAmount: "يرجى ادخال قيمة",
      }));
      return;
    }
    setIsAcceptPaymentWithChangeSubmitting(true);
    try {
      // Prepare category data from FormData
      const categoryData: any = {
        moneyAmount: formData.moneyAmount,
      };
      // console.log(categoryData);

      const response = await AcceptUserCharge(selectedRowId, categoryData);
      // const response = await CreateUserCharge(UserId, categoryData);
      setFormData({
        moneyAmount: "",
      });
      setErrors({
        moneyAmount: "",
      });
      setSelectedRowId(null);
      if (response) {
        fetchData();
        openNotification("topRight");
      }
      // Close the modal or perform any other necessary actions
      handleOk();
    } catch (error) {
      console.error("Error creating category:", error);
      // Handle error here
    } finally {
      setIsAcceptPaymentWithChangeSubmitting(false);
    }
  };
  const HandleChangeToUser = (userId: any) => {
    navigate(`/users/${userId}`);
  };
  // console.log(sortedData);

  function convertUTCDateToLocalDate(date: Date): Date {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <ChargeMenu setPaymentData={setPaymentData} setIsLoading={setIsLoading} />

      <div className="flex flex-col bg-color2 mt-4 m-4">
        {contextHolder}
        <Modal
          title="اضافة مبلغ للمستخدم"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose>
          <form onSubmit={handleSubmit} className="mt-10">
            <div className="mb-4">
              <div className="mb-4 flex items-center justify-end">
                <Input
                  value={formData.moneyAmount}
                  onChange={handlePriceChange}
                  name="moneyAmount"
                  // placeholder="اسم النوع"
                  className="w-full"
                />
                <div className="text-lg w-36 flex justify-end">
                  <div> : قيمة المبلغ</div>
                </div>
              </div>
              {errors.moneyAmount && (
                <div className="text-red-500 mb-4 text-sm">
                  {errors.moneyAmount}
                </div>
              )}
            </div>

            <div className="flex justify-end">
              <Button
                htmlType="submit"
                className="text-color1"
                loading={isAcceptPaymentWithChangeSubmitting}>
                اضافة
              </Button>
            </div>
          </form>
        </Modal>
        <TableContainer sx={{ width: "100%" }} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">الاجراءات</TableCell>
                <TableCell
                  style={{ cursor: "pointer" }}
                  align="center"
                  onClick={() => handleSortChange("date")}>
                  <div className="flex justify-center items-center">
                    التاريخ والوقت
                    <span className="p-3">
                      <span className="">
                        {sortOrder === "ascend" ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )}
                      </span>
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">المبلغ المحول</TableCell>
                <TableCell align="center">مصدر الحوالة</TableCell>
                <TableCell align="center">الحالة</TableCell>
                <TableCell align="center">رقم الحوالة</TableCell>
                <TableCell align="center">المستخدم</TableCell>
                <TableCell align="center">الصورة</TableCell>
              </TableRow>
            </TableHead>
            {sortedData.length > 0 ? (
              <TableBody>
                {sortedData.map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {row.status === "pending" ? (
                      <TableCell align="center">
                        <div className="flex justify-around">
                          <Popconfirm
                            className="m-2"
                            title="الغاء العملية"
                            description="هل انت متاكد من عدم استلام المبلغ والغاء العملية ؟"
                            onConfirm={() => paymentDeleteConfirm(row.id)}
                            onCancel={paymentDeleteCancel}
                            okText="نعم"
                            cancelText="لا"
                            disabled={isDeletePaymentSubmitting}>
                            <Button danger loading={isDeletePaymentSubmitting}>
                              رفض
                            </Button>
                          </Popconfirm>
                          <Button
                            type="primary"
                            className="m-2"
                            onClick={() => showModal(row.id)}>
                            الموافقة مع تغيير المبلغ
                          </Button>
                          <Popconfirm
                            title="اضافة المبلغ للمستخدم"
                            className="m-2"
                            description="هل انت متاكد من استلام المبلغ ؟"
                            onConfirm={() => paymentConfirm(row.id)}
                            onCancel={paymentCancel}
                            okText="نعم"
                            cancelText="لا">
                            <Button
                              type="primary"
                              loading={isAcceptPaymentSubmitting}>
                              الموافقة
                            </Button>
                          </Popconfirm>
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <div>لايوجد اي اجراء</div>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {/* {row.createdAt.split("T")[0]} */}
                      <div className="flex flex-col w-full items-center justify-center">
                        {/* {row.createdAt} */}
                        <div className="w-min">
                          {new Date(row.createdAt).toLocaleDateString("en-US", {
                            timeZone: "UTC",
                          })}
                        </div>
                        <div className="w-max">
                          {convertUTCDateToLocalDate(
                            new Date(row.createdAt)
                          ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {formatNumberWithCommas(row.moneyAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {row.chargeSource?.name}
                    </TableCell>
                    <TableCell align="center">
                      {row.status === "pending"
                        ? "قيد الانتظار"
                        : row.status === "approved"
                        ? "تم الموافقة"
                        : "تم الرفض"}
                    </TableCell>
                    <TableCell align="center">
                      {row.transactionNumber}
                    </TableCell>
                    <TableCell align="center">
                      {row.user ? (
                        <div
                          className="p-6 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            HandleChangeToUser(row.userId);
                          }}>
                          {row.user.fullName}
                        </div>
                      ) : (
                        "لايوجد معلومات"
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <div className="h-36 w-44 overflow-hidden flex justify-center">
                        <Image
                          className="object-cover h-full w-full"
                          src={`${API_URL}/cards/download?path=${row.imageUrl}`}
                          height={"100%"}
                          width={"100%"}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
          {sortedData.length === 0 ? (
            <div className="w-full flex justify-center items-center p-4">
              <div className="flex w-full justify-center">
                لايوجد اي مدفوعات
              </div>
            </div>
          ) : (
            ""
          )}
        </TableContainer>
      </div>
    </>
  );
};
