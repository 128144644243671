import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { TransactionsLayout } from "../components/TransactionsLayout";
// import UserWrapper from "./UserWrapper";
// import UserProfileWrapper from "../../userProfile/routes/UserProfileWrapper";

export const TransactionsRouter = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Routes>
      <Route index element={<TransactionsLayout />} />
      {/* <Route path="/:UserId" element={<UserProfileWrapper />} /> */}
    </Routes>
  );
};
