import { axios } from "../../../lib/axios";

export const CreateUserCharge = async (id: any, categoryData: any) => {
  const { price } = categoryData;
  //   console.log(price);
  const data = {
    userId: id,
    amount: price,
  };
  const response = await axios.patch(`/wallet/charge`, data);

  return response.data;
};
