import { axios } from "../../../lib/axios";
import { SingleCategory } from "../types";

interface FormData {
  name: string;
  price: string;
  purchasedPrice: any;
  specialPrice: any;
  categoryId: string;
  image: File | null;
}

export const EditCategoryType = async (
  formData: FormData,
  rowId: any
): Promise<SingleCategory> => {
  const { name, price, purchasedPrice, specialPrice, categoryId, image } =
    formData;
  //   console.log(formData);

  const formDataToSend: any = {};
  formDataToSend["name"] = name;
  formDataToSend["purchasedPrice"] = purchasedPrice;
  formDataToSend["categoryId"] = categoryId;
  formDataToSend["specialPrice"] = specialPrice;
  formDataToSend["price"] = String(price);
  formDataToSend["image"] = image!;

  // console.log("formDataToSend", formDataToSend);
  // console.log(rowId);

  const response = await axios.patch(`/cards/${rowId}`, formDataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  //   console.log(response.data);
  return response.data;
};
