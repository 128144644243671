import { axios } from "../../../lib/axios";

export const GetTransferTransaction = async () => {
  try {
    const response = await axios.get("/transactions?transactionType=transfer");
    console.log(response.data.array);
    return response.data.array;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};
