import React from "react";
import { ChargeSourceTable } from "./chargeSourceTable";

export const ChargeSourceLayout = () => {
  return (
    <div className="">
      <ChargeSourceTable />
    </div>
  );
};
