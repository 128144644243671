import React, { useEffect, useState } from "react";
import { CardsListResponse } from "../types";
import { GetCardsList } from "../api/getCardsList";
import { Loading } from "../../../components/elements/loading";
import { CardTable } from "./CardTable";

export const CardsList = ({ id }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [CardsListData, setCardsListData] = useState<any>([]);

  const fetchCardsData = async () => {
    try {
      const response: CardsListResponse = await GetCardsList(id);
      setCardsListData(response.array);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };
  useEffect(() => {
    fetchCardsData(); // Call fetchCardsData inside useEffect
  }, [id]); // Include id in the dependency array

  return (
    <>
      <div className="mb-4 w-full flex flex-col">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="w-[100%] px-4 pb-4 flex justify-center">
            {CardsListData.length === 0 ? (
              <div className="mt-10">No cards available.</div>
            ) : (
              <CardTable CardsListData={CardsListData} />
            )}
          </div>
        )}
      </div>
    </>
  );
};
