import React from "react";
import { TransactionsTable } from "./TransactionsTable";

export const TransactionsLayout = () => {
  return (
    <div className="p-4">
      <TransactionsTable />
    </div>
  );
};
