import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
export const CardTable = ({ CardsListData }: any) => {
  // // Check if CardsListData is an array
  // if (!Array.isArray(CardsListData)) {
  //   return <div>No data available</div>;
  // }
  // console.log(CardsListData);

  const rows = CardsListData.map((item: any) => ({
    name: item.name,
    amount: item.amount,
    price: item.price,
    countItems: item.countItems,
  }));
  // console.log(CardsListData);

  return (
    <TableContainer sx={{ width: "100%" }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">العدد المتوفر</TableCell>
            <TableCell align="center">سعر الكرت</TableCell>
            <TableCell align="center">نوع الكرت</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any, index: number) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="center">{row.countItems}</TableCell>
              <TableCell align="center">
                {formatNumberWithCommas(row.price)}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {row.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
