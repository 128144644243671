import { axios } from "../../../lib/axios";
import { SingleCategory } from "../types";

export const GetSingleCategory = async (id: any): Promise<SingleCategory> => {
  // console.log(id);

  const response = await axios.get(`/category/${id}`);
  // console.log(response.data);

  return response.data;
};
