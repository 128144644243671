import * as React from "react";

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <div className="h-screen bg-color4 flex flex-col">
        <div className="flex justify-end pr-20 pt-10">
          {" "}
          {/* Adjust padding and use justify-end */}
          <img
            className="w-16 h-auto"
            src={require("../../../assets/Logo.png")}
            alt=""
          />
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="text-center mb-4">
              <div className="text-2xl font-bold mb-4 font-default-font">
                تسجيل الدخول
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
