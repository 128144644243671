import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import UserWrapper from "./UserWrapper";
import UserProfileWrapper from "../../userProfile/routes/UserProfileWrapper";

export const UserRouters = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Routes>
      <Route index element={<UserWrapper />} />
      <Route path="/:UserId" element={<UserProfileWrapper />} />
    </Routes>
  );
};
