import { axios } from "../../../lib/axios";
import { SingleCategory } from "../types";

interface FormData {
  name: string;
  image?: File | null;
}

export const EditSingleCategory = async (
  formData: FormData,
  CategorysId: any
): Promise<SingleCategory> => {
  // // Create a plain object to hold the form data
  // const formDataToSend: any = {
  //   name,
  // };

  // // If an image is provided, add it to the formDataToSend
  // if (image) {
  //   formDataToSend.imageUrl = image;
  // }
  // console.log(formData);

  // console.log(`/category/${CategorysId}`);
  // Send the modified data to the server
  const response = await axios.patch(`/category/${CategorysId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  // console.log(response.data);
  return response.data;
};
