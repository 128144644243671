import React, { useEffect, useState } from "react";
import { UserProfile } from "../components/UserProfile";
import { useParams } from "react-router-dom";
import { UserHeader } from "../components/UserHeader";
import { UserDocument } from "../components/UserDocument";
import { UserHistory } from "../components/UserHistory";

const UserProfileWrapper = () => {
  const { UserId } = useParams();
  const [isUserExits, setIsUserExits] = useState(false);

  useEffect(() => {
    // Smooth scroll animation
    const element = document.querySelector(".gb-color2");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [UserId]);

  return (
    <div className="gb-color2 transition-all duration-300">
      <UserHeader
        UserId={UserId}
        isUserExits={isUserExits}
        setIsUserExits={setIsUserExits}
      />
      {isUserExits ? (
        <div>
          {" "}
          <UserDocument UserId={UserId} />
          <UserHistory UserId={UserId} />{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserProfileWrapper;
