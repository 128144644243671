import { axios } from "../../../lib/axios";
// import { UserListResponse } from "../types";

export const GetSingleUsers = async (id: any): Promise<any> => {
  // console.log(id);

  const response = await axios.get(
    `/user/${id}?relations[]=wallet&relations[]=userMedia&relations[]=account`
  );
  // console.log(id, response.data);

  return response.data;
};
