import React, { useState } from "react";
import { Button, Input, Modal, Space } from "antd";
import AddIcon from "@mui/icons-material/Add";
import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import { createAdmin } from "../api/createAdmin";
import { AdminErrors, AdminFormData } from "../types/adminsType";

export const AdminMenu = ({
  fetchAdminData,
  adminDataCount,
  setIsLoading,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [formData, setFormData] = useState<AdminFormData>({
    adminName: "",
    adminEmail: "",
    adminPhoneNumber: "",
    adminPassword: "",
    adminPhoneNumberPrefix: "+964", // Default prefix
  });
  const [errors, setErrors] = useState<AdminErrors>({
    adminName: "",
    adminEmail: "",
    adminPhoneNumber: "",
    adminPassword: "",
  });

  const [isInfoSubmitting, setIsInfoSubmitting] = useState<boolean>(false);

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم انشاء المشرف بنجاح",
      description: "تم انشاء المشرف بنجاح",
      placement,
      duration: 3,
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the error message for the current field if any
    if (errors[name as keyof AdminErrors]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof AdminErrors]: "",
      }));
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    // Reset form fields and errors when modal opens
    setFormData({
      adminName: "",
      adminEmail: "",
      adminPhoneNumber: "",
      adminPassword: "",
      adminPhoneNumberPrefix: "+964", // Reset prefix on modal open
    });
    setErrors({
      adminName: "",
      adminEmail: "",
      adminPhoneNumber: "",
      adminPassword: "",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const validateEmail = (email: string): boolean => {
    // Regex pattern for basic email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate form fields
    let hasError = false;
    const {
      adminName,
      adminEmail,
      adminPhoneNumber,
      adminPassword,
      adminPhoneNumberPrefix,
    } = formData;
    const newErrors: AdminErrors = {
      adminName: "",
      adminEmail: "",
      adminPhoneNumber: "",
      adminPassword: "",
    };

    if (!adminName) {
      newErrors.adminName = "يرجى إدخال اسم المشرف";
      hasError = true;
    }
    if (!adminPhoneNumber) {
      newErrors.adminPhoneNumber = "يرجى إدخال رقم الهاتف";
      hasError = true;
    } else if (adminPhoneNumber.length !== 10) {
      newErrors.adminPhoneNumber = "رقم الهاتف يجب ان يتكون من 10 ارقام ";
      hasError = true;
    }
    if (!adminEmail) {
      newErrors.adminEmail = "يرجى إدخال البريد الإلكتروني";
      hasError = true;
    } else if (!validateEmail(adminEmail)) {
      newErrors.adminEmail = "يرجى إدخال بريد إلكتروني صحيح";
      hasError = true;
    }

    if (!adminPassword) {
      newErrors.adminPassword = "يرجى إدخال كلمة السر";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }
    setIsInfoSubmitting(true);
    // If no errors, proceed with form submission
    try {
      let formattedPhoneNumber = adminPhoneNumber;
      // Check if adminPhoneNumber starts with + to preserve user's prefix
      if (!adminPhoneNumber.startsWith("+")) {
        formattedPhoneNumber = `${adminPhoneNumberPrefix}${adminPhoneNumber}`;
      }

      const adminData: AdminFormData = {
        adminName,
        adminEmail,
        adminPhoneNumber: formattedPhoneNumber,
        adminPassword,
      };

      // Make the API request to create a new admin
      const response = await createAdmin(adminData);
      if (response) {
        fetchAdminData();
        openNotification("topRight");
        // Reset form fields and errors after successful submission
        setFormData({
          adminName: "",
          adminEmail: "",
          adminPhoneNumber: "",
          adminPassword: "",
          adminPhoneNumberPrefix: "+964", // Reset prefix after submission
        });
        setErrors({
          adminName: "",
          adminEmail: "",
          adminPhoneNumber: "",
          adminPassword: "",
        });

        handleOk();
      }
    } catch (error) {
      console.error("Error creating admin:", error);
      // Handle error here
    } finally {
      setIsInfoSubmitting(false);
    }
  };

  return (
    <div className="bg-color2">
      {contextHolder}
      <div className="mx-10 border-color1 border-x-0 border-t-0 border-2 w-[95%] rounded-md opacity-60"></div>
      <Modal
        title="انشاء مشرف جديد"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose>
        <form onSubmit={handleSubmit} className="mt-10">
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-4">الاسم الكامل</div>
              </div>
              <Input
                value={formData.adminName}
                onChange={handleInputChange}
                style={{ direction: "rtl", textAlign: "right" }}
                name="adminName"
                placeholder="اسم المشرف"
                className="w-full"
              />
            </div>
            {errors.adminName && (
              <div className="text-red-500 text-sm">{errors.adminName}</div>
            )}
          </div>
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-4">الايميل</div>
              </div>
              <Input
                value={formData.adminEmail}
                onChange={handleInputChange}
                style={{ direction: "rtl", textAlign: "right" }}
                name="adminEmail"
                placeholder="البريد الإلكتروني"
                className="w-full"
              />
            </div>
            {errors.adminEmail && (
              <div className="text-red-500 text-sm">{errors.adminEmail}</div>
            )}
          </div>
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg  flex justify-end items-end">
                <div className="p-4">رقم الهاتف</div>
              </div>
              <div className="w-full">
                <Space.Compact className="w-full">
                  <Input
                    style={{ width: "20%" }}
                    defaultValue={formData.adminPhoneNumberPrefix}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        adminPhoneNumberPrefix: e.target.value,
                      }))
                    }
                    className="rounded-r-none rounded-l-md"
                  />
                  <Input
                    value={formData.adminPhoneNumber}
                    onChange={handleInputChange}
                    style={{
                      direction: "rtl",
                      textAlign: "right",
                      width: "80%",
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                    }}
                    name="adminPhoneNumber"
                    placeholder="رقم الهاتف"
                    className="w-full rounded-l-none"
                  />
                </Space.Compact>
              </div>

              {errors.adminPhoneNumber && (
                <div className="text-red-500 text-sm">
                  {errors.adminPhoneNumber}
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-1 flex flex-col items-center justify-between">
              <div className="w-full text-lg flex justify-end items-end">
                <div className="p-4">كلمة السر</div>
              </div>
              <Input.Password
                value={formData.adminPassword}
                onChange={handleInputChange}
                style={{ direction: "rtl", textAlign: "right" }}
                name="adminPassword"
                placeholder="كلمة السر"
                className="w-full"
              />
            </div>
            {errors.adminPassword && (
              <div className="text-red-500 text-sm">{errors.adminPassword}</div>
            )}
          </div>
          <div className="flex justify-end">
            <Button
              htmlType="submit"
              className="text-color1"
              loading={isInfoSubmitting}>
              انشاء
            </Button>
          </div>
        </form>
      </Modal>
      <div className="flex items-center justify-between ">
        <div className="flex items-center justify-between flex-row-reverse m-4 pl-6">
          <Button
            type="text"
            onClick={showModal}
            className="flex items-center justify-center w-24 h-20 text-lg">
            <span className="text-color1 text-lg">اضافة</span>
            <AddIcon className="text-color1 ml-2 w-8" />
          </Button>
        </div>
        <div className="flex flex-row-reverse w-1/2 m-4">
          <div className="m-4">
            <div
              className={`flex justify-end m-1 p-2 items-center font-default-font rounded-md bg-color1 text-white`}
              style={{
                textDecoration: "none", // Remove underline
              }}>
              <div className="flex justify-center pr-4 p-2">
                &nbsp;
                <span>{adminDataCount}</span>&nbsp;
                <span>: عدد المشرفين</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
