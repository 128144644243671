import React, { useEffect, useState } from "react";
import { Image } from "antd";
import { UserResponse } from "../types";
import { GetSingleUsers } from "../api/getSingleUser";
import { Loading } from "../../../components/elements/loading";
import { Card } from "@mui/material";
import { Button } from "antd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
export const UserDocument = ({ UserId }: any) => {
  const [SingleUserData, setSingleUserData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = React.useState(false);
  const fetchSingleUserData = async () => {
    try {
      const response: UserResponse = await GetSingleUsers(UserId);
      setSingleUserData(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    fetchSingleUserData();
  }, []);
  // SingleUserData.userMedia.map((media: any) => {
  //   console.log(media);
  // });
  return isLoading ? (
    <Loading />
  ) : (
    <div className="w-full p-4 min-h-full flex-grow">
      <Card sx={{ height: "100%" }}>
        <div className="bg-color2 rounded-md flex flex-wrap">
          <div className="flex w-full justify-between">
            <div
              className="flex m-10 items-center"
              onClick={(e) => {
                e.stopPropagation(); // This prevents the click event from bubbling up to parent elements
              }}>
              <Button
                type="text"
                icon={
                  expanded ? (
                    <ExpandLessIcon className="text-color1" />
                  ) : (
                    <ExpandMoreIcon className="text-color1" />
                  )
                }
                onClick={handleExpandClick}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
            </div>

            <div className="flex flex-col" style={{ direction: "rtl" }}>
              <div className="text-3xl p-4 font-semibold flex justify-center items-center h-full">
                <div className="p-4">مستندات</div>
              </div>
            </div>
          </div>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            className="w-full">
            <div className="flex justify-center pt-6 w-full">
              <div className="flex flex-wrap -m-2">
                <Image.PreviewGroup>
                  {SingleUserData.userMedia.length > 0 ? (
                    SingleUserData.userMedia.map((media: any) => (
                      <div
                        key={media.id}
                        className="w-full md:w-1/2 py-10 flex justify-center items-center">
                        <Image
                          style={{
                            height: "350px",
                            width: "350px",
                            objectFit: "contain",
                          }}
                          src={`https://thiqa.shaalan.dev/cards/download?path=${media["file"]}`}
                          preview={{
                            title: media.previewTitle,
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center items-center text-2xl w-full h-full p-10">
                      <div>لايوجد مستندات لعرضها</div>
                    </div>
                  )}
                </Image.PreviewGroup>
              </div>
            </div>
          </Collapse>
        </div>
      </Card>
    </div>
  );
};
