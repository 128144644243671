import { axios } from "../../../lib/axios";

export const EditTransferFee = async (
  transferId: any,
  fee: any
): Promise<any> => {
  // console.log(formData);

  try {
    const response = await axios.patch(`/transaction-types/${transferId}`, {
      tax: fee,
    });
    // return response.data;
  } catch (error: any) {
    throw new Error("Error editing charge source: " + error.message);
  }
};
