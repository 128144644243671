import { axios } from "../../../lib/axios";

export const AcceptUserCharge = async (id: any, data?: any) => {
  try {
    let response;
    if (data) {
      // If data is provided, include it in the PATCH request body
      response = await axios.patch(`/charge/activate/${id}`, data);
    } else {
      // If data is not provided, send the PATCH request without any additional data
      response = await axios.patch(`/charge/activate/${id}`);
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error accepting user charge:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};
