import { Route, Routes } from "react-router-dom";

// import { Login } from "./Login";
import React from "react";
import { AdminsList } from "../components/AdminsList";

export const AdminRouters = () => {
  return (
    <Routes>
      <Route index element={<AdminsList />} />
    </Routes>
  );
};
