import { axios } from "../../../lib/axios";

export const GetDashboardProfileInfo = async () => {
  try {
    const response = await axios.get("/auth/me");
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Error fetching users: " + error);
  }
};
