import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GetSingleCategoryCard } from "../api/GetSingleCategoryCard";
import { SingleCategory } from "../types";
import { Loading } from "../../../components/elements/loading";
import {
  Button,
  Image,
  Input,
  notification,
  Space,
  Upload,
  Modal,
  Popconfirm,
} from "antd";
import * as XLSX from "xlsx";
import { formatNumberWithCommas } from "../../../lib/formatNumberWithCommas";
import { API_URL } from "../../../config";
import { EditCategoryType } from "../api/EditCategoryType";
import { UploadFullCard } from "../api/UploadFullCard";
import type { NotificationArgsProps, PopconfirmProps } from "antd";
import { AddSingleCard } from "../api/AddSingleCard";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message } from "antd";
import { activeCard } from "../api/activeCard";
import { deactiveCard } from "../api/deactiveCard";
import { deleteSingleCard } from "../api/deleteSingleCard";
import { useNavigate } from "react-router-dom";

type NotificationPlacement = NotificationArgsProps["placement"];

export const CategorysTable = ({
  CategorysId,
  CardsListData,
  setCardsListData,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [newImage, setNewImage] = useState<File | null>(null);
  const [editedName, setEditedName] = useState<string>("");
  const [editPurchasedPrice, setEditPurchasedPrice] = useState("");
  const [editedPrice, setEditedPrice] = useState<number>(0);
  const [editSpecialPrice, setEditSpecialPrice] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);

  const [isDeletingSubmitting, setIsDeletingSubmitting] =
    useState<boolean>(false);
  const [isUploadingFileSubmitting, setIsUploadingFileSubmitting] =
    useState<boolean>(false);

  const [isOneCardNumberSubmitting, setIsOneCardNumberSubmitting] =
    useState<boolean>(false);
  const [isInfoEditSubmitting, setIsInfoEditSubmitting] =
    useState<boolean>(false);
  const [isDeactivateSubmitting, setIsDeactivateSubmitting] =
    useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [uploading, setUploading] = useState(false); // Add state for tracking upload status
  const [uploadingRows, setUploadingRows] = useState<{
    [key: number]: boolean;
  }>({});
  const [uploadError, setUploadError] = useState<any>();

  const [dataList, setDataList] = useState<any>([]);
  // const navigator = useNavigate();
  const [formData, setFormData] = useState<any>({
    code: "",
    serialNumber: "",
  });
  const [errors, setErrors] = useState<any>({
    code: "",
    serialNumber: "",
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم تعديل المعلومات بنجاح",
      description: "تم تعديل المعلومات بنجاح",
      placement,
      duration: 3,
    });
  };
  const openUploadNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم اضافة الملف بنجاح",
      description: "تم اضافة الملف بنجاح",
      placement,
      duration: 3,
    });
  };
  const openDeleteNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: "تم حذف الكارت بنجاح",
      description: "تم حذف الكارت بنجاح",
      placement,
      duration: 3, // Duration in seconds (default is 4.5 seconds)
    });
  };
  const openActivateNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: "تم تفعيل البطاقة بنجاح",
      description: "تم تفعيل البطاقة بنجاح",
      placement,
      duration: 3, // Duration in seconds (default is 4.5 seconds)
    });
  };
  const openDeactivatedNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: "تم الغاء تفعيل البطاقة بنجاح",
      description: "تم الغاء تفعيل البطاقة بنجاح",
      placement,
      duration: 3, // Duration in seconds (default is 4.5 seconds)
    });
  };
  const openDuplicateSingleCardNotification = (
    placement: NotificationPlacement
  ) => {
    api.error({
      message: "عذرا رقم البطاقة موجود مسبقا",
      description: "عذرا رقم البطاقة موجود مسبقا",
      placement,
      duration: 3,
    });
  };

  const close = () => {};

  const downloadDuplicateCardsCSV = () => {
    // Simulate API call to destroy item (replace with actual implementation)

    // Prepare data for CSV export
    const data = uploadError.map((card: any) => ({
      code: card,
    }));

    // Convert data to CSV format
    const csvData = convertToCSV(data);

    // Create a Blob object containing the CSV file
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a temporary link element to trigger the download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "doublicatedCard.csv"; // Set filename here
    document.body.appendChild(a);
    a.click();

    // Cleanup
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };

  // Utility function to convert JSON to CSV format
  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",") + "\n";
    const csv = data.map((row: any) => Object.values(row).join(",")).join("\n");
    return header + csv;
  };

  // Utility function to convert string to ArrayBuffer

  // const openUploadFileNotificationError = () => {
  //   const key = `open${Date.now()}`;
  //   const btn = (
  //     <Space>
  //       <Button type="primary" size="small" onClick={() => api.destroy(key)}>
  //         موافق
  //       </Button>
  //       <Button
  //         type="primary"
  //         size="small"
  //         onClick={() => downloadDuplicateCardsCSV(key)}>
  //         تحميل الكرتات المكررة
  //       </Button>
  //     </Space>
  //   );
  //   api.error({
  //     message: "عناصر متوفرة سابقا",
  //     description: (
  //       <div>
  //         {uploadError.map((item: any, index: any) => (
  //           <div key={index}>{item}</div>
  //         ))}
  //       </div>
  //     ),
  //     placement: "top",
  //     btn,
  //     key,
  //     onClose: close,
  //   });
  // };

  const handleFileUpload = async (file: File, cardId: any) => {
    // Check if the uploaded file is a CSV file
    if (file.type !== "text/csv") {
      message.error("فقط CSV يرجى تحميل ملف");
      return false; // Prevent upload
    }
    setIsUploadingFileSubmitting(true);
    try {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });

          // Assuming the first sheet is the one we want to read
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          // Convert the worksheet to JSON
          const json: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });

          // Extract headers
          const headers = json[0];
          const codeIndex = headers.findIndex(
            (header) => header.toLowerCase() === "code"
          );
          const serialNumberIndex = headers.findIndex(
            (header) => header.toLowerCase() === "serialnumber"
          );

          // Transform the JSON array into a list of objects
          const FulldataList = json.slice(1).map((row) => ({
            code: codeIndex !== -1 ? row[codeIndex] : null,
            serialNumber:
              serialNumberIndex !== -1 ? row[serialNumberIndex] : null,
          }));

          // Update state with the data list
          setDataList(FulldataList);

          // Call UploadFullCard API function with the dataList
          handleUpload(FulldataList, cardId);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error reading file:", error);
      message.error("خطأ في قراءة الملف");
    } finally {
      setIsUploadingFileSubmitting(false);
    }

    return false; // Prevent auto upload
  };
  useEffect(() => {
    if (uploadError) {
      // openUploadFileNotificationError();
      setIsDuplicateModalOpen(true);
    }
  }, [uploadError]);

  const handleUpload = async (dataList: any[], cardId: any) => {
    try {
      if (dataList && dataList.length > 0) {
        const response = await UploadFullCard(dataList, cardId, setUploadError);
        if (response) {
          openUploadNotification("topRight");
          fetchCardsData();
        }
      } else {
        // console.log("DataList is empty or undefined");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // if (uploadError) {
      //   openUploadFileNotificationError(); // Handle error notification
      // }
    } finally {
      setUploadingRows((prev) => ({ ...prev, [cardId]: false }));
      setUploading(false);
    }
  };

  const openNotification2 = (placement: NotificationPlacement) => {
    api.success({
      message: "تم اضافة الكرت",
      description: "تم اضافة الكرت",
      placement,
      duration: 3,
    });
  };
  const fetchCardsData = async (name: string = "") => {
    try {
      if (CategorysId) {
        const response: SingleCategory = await GetSingleCategoryCard(
          CategorysId,
          name
        );
        setCardsListData(response.array);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  useEffect(() => {
    setSearchInput("");
    fetchCardsData();
  }, []);

  // const showModal = () => {
  //   setFormData({
  //     price: "",
  //   });
  //   setErrors({
  //     price: "",
  //   });
  //   setIsModalOpen(true);
  // };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDuplicateCancel = () => {
    setIsDuplicateModalOpen(false);
  };
  const handleEditClick = (
    rowId: number,
    name: string,
    purchasedPrice: any,
    price: number,
    specialPrice: number
  ) => {
    setEditingRowId(rowId);
    setEditedName(name);
    setEditedPrice(price);
    setEditPurchasedPrice(purchasedPrice);
    setEditSpecialPrice(specialPrice);
  };

  const handleSearch = () => {
    fetchCardsData(searchInput);
  };

  const handleSaveClick = async (
    rowId: number,
    newName: string,
    newPrice: number,
    newPurchasedPrice: any,
    newSpecialPrice: number,
    categoryId: string
  ) => {
    setIsInfoEditSubmitting(true);
    try {
      const saveFormData: any = new FormData();
      saveFormData["name"] = newName;
      saveFormData["categoryId"] = categoryId;
      saveFormData["price"] = newPrice;
      saveFormData["purchasedPrice"] = newPurchasedPrice;
      saveFormData["specialPrice"] = newSpecialPrice;
      // console.log(formData);

      if (newImage) {
        saveFormData["image"] = newImage;
      }

      const response = await EditCategoryType(saveFormData, rowId);

      if (response) {
        openNotification("topRight");
        setEditingRowId(null);
        fetchCardsData();
      }

      // setEditingRowId(null);
      // fetchCardsData();
    } catch (error) {
      console.error("Error saving category data:", error);
    } finally {
      setIsInfoEditSubmitting(false);
    }
  };
  const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value, // Use computed property name to set state dynamically
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(formData);
    if (!formData.code) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        code: "يرجى ادخال قيمة",
      }));
      return;
    } else {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        code: "",
      }));
    }
    setIsOneCardNumberSubmitting(true);
    try {
      // Prepare category data from FormData
      const AddSingleCardData: any = {
        code: formData.code,
        serialNumber: formData.serialNumber || undefined,
        cardId: selectedCardId,
      };
      const response = await AddSingleCard(AddSingleCardData);
      // console.log(response);
      if (response.status == 200 || response.status == 201) {
        openNotification2("topRight");
        fetchCardsData();
      }
      setFormData({
        code: "",
        serialNumber: "",
      });
      setErrors({
        code: "",
        serialNumber: "",
      });
      // if (response) {
      //   // fetchSingleUserData();
      //   openNotification("topRight");
      // }
      // console.log(AddSingleCardData);

      // Close the modal or perform any other necessary actions
      handleOk();
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        openDuplicateSingleCardNotification("topRight");
        fetchCardsData();
        //  handleOk();
      } else {
        console.error("Error creating category:", error);
        // Handle other errors here if needed
      }
    } finally {
      setIsOneCardNumberSubmitting(false);
    }
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setNewImage(file);
    }
  };
  const cardDeleteCancel: PopconfirmProps["onCancel"] = () => {};

  // Wrapper function to pass the card ID to the actual handler
  const cardDeleteConfirm = (cardId: number) => async () => {
    setIsDeletingSubmitting(true);
    try {
      const response = await deleteSingleCard(cardId);
      if (response.status === 200) {
        // navigator(`/categorys/${CategorysId}`);
        fetchCardsData();
        openDeleteNotification("topRight");
      }
    } catch (error) {
      console.error("Error deleting card:", error);
    } finally {
      setIsDeletingSubmitting(false);
    }
  };
  // console.log(CardsListData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };
  const handleAddSingleItem = (id: any) => {
    setSelectedCardId(id);
    setFormData({
      code: "",
      serialNumber: "",
    });
    setErrors({
      code: "",
      serialNumber: "",
    });
    setIsModalOpen(true);
  };
  // console.log(CardsListData);
  const handleDeactiveCard = async (cardId: any) => {
    setIsDeactivateSubmitting(true);
    try {
      const response = await deactiveCard(cardId);

      if (response) {
        openDeactivatedNotification("topRight");
        fetchCardsData();
      }
    } catch (error) {
      console.error("Error deactivating card:", error);
    } finally {
      setIsDeactivateSubmitting(false);
    }
  };

  const handleactiveCard = async (cardId: any) => {
    setIsDeactivateSubmitting(true);
    try {
      const response = await activeCard(cardId);
      if (response) {
        openActivateNotification("topRight");
        fetchCardsData();
      }
    } catch (error) {
      console.error("Error deactivating card:", error);
    } finally {
      setIsDeactivateSubmitting(false);
    }
  };
  // console.log(CardsListData);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="w-[100%] px-10 pb-10 flex justify-center">
      {contextHolder}
      <Modal
        title="عناصر مكررة"
        visible={isDuplicateModalOpen}
        onCancel={handleDuplicateCancel}
        className="p-28"
        footer={[
          <Button key="cancel" onClick={handleDuplicateCancel}>
            موافق
          </Button>,
          <Button
            key="download"
            type="primary"
            onClick={() => downloadDuplicateCardsCSV()}>
            تحميل الكرتات المكررة
          </Button>,
        ]}
        destroyOnClose>
        <div>
          {uploadError &&
            uploadError.map((item: any, index: any) => (
              <div key={index}>{item}</div>
            ))}
        </div>
      </Modal>
      <Modal
        title="اضافة كرت"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="mb-1 flex items-center justify-end">
              <Input
                value={formData.code}
                onChange={handleModelChange}
                name="code"
                placeholder="PIN"
                className="w-full"
              />
              <div className="text-lg w-36 flex justify-end">
                <div> : رقم البطاقة</div>
              </div>
            </div>
            {errors.code && (
              <div className="text-red-500 mb-4 text-sm">{errors.code}</div>
            )}
          </div>
          <div className="mb-4">
            <div className="mb-1 flex items-center justify-between">
              <Input
                value={formData.serialNumber}
                onChange={handleModelChange}
                name="serialNumber"
                placeholder="Serial Number"
                className="w-full"
              />
              <div className="text-lg w-36 flex justify-end">
                <div> : رقم التسلسل</div>
              </div>
            </div>
            {errors.serialNumber && (
              <div className="text-red-500 text-sm">{errors.serialNumber}</div>
            )}
          </div>
          <div className="flex justify-end">
            <Button
              htmlType="submit"
              className="text-color1"
              loading={isOneCardNumberSubmitting}>
              ارسال
            </Button>
          </div>
        </form>
      </Modal>
      <div className="flex flex-col bg-color2 w-[100%]">
        <div className="w-[100%] h-20 flex items-center justify-end py-10">
          <Space.Compact>
            <Input
              value={searchInput}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <Button type="primary" onClick={handleSearch}>
              بحث
            </Button>
          </Space.Compact>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">الاجراءات</TableCell>
                <TableCell align="center">العدد المتوفر</TableCell>
                <TableCell align="center">السعر للوكيل</TableCell>
                <TableCell align="center">السعر للزبون</TableCell>
                <TableCell align="center">سعر الشراء</TableCell>
                <TableCell align="center">نوع الكرت</TableCell>
                <TableCell align="center">الصورة</TableCell>
              </TableRow>
            </TableHead>
            {CardsListData.length > 0 ? (
              <TableBody>
                {CardsListData.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      <div className="flex flex-col justify-between space-y-4 items-center">
                        <div className="flex flex-col space-y-2">
                          <Button
                            className="m-2"
                            type="primary"
                            onClick={() => handleAddSingleItem(row.id)}>
                            اضافة كرت واحد
                          </Button>
                          <Upload
                            beforeUpload={(file) =>
                              handleFileUpload(file, row.id)
                            }
                            className="m-2"
                            showUploadList={false}
                            accept=".csv"
                            disabled={isUploadingFileSubmitting} // Disable the button when uploading
                          >
                            <Button
                              icon={<UploadOutlined />}
                              loading={isUploadingFileSubmitting}>
                              {uploadingRows[row.id] ? "جارٍ الرفع" : "رفع ملف"}
                            </Button>
                          </Upload>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex p-2">
                            <Popconfirm
                              title="حذف البطاقة"
                              description="هل انت متاكد من حذف هذه البطاقة ؟"
                              onConfirm={cardDeleteConfirm(row.id)}
                              onCancel={cardDeleteCancel}
                              okText="نعم"
                              cancelText="لا"
                              className="m-2"
                              disabled={isDeletingSubmitting}>
                              <Button
                                danger
                                type="primary"
                                loading={isDeletingSubmitting}>
                                حذف
                              </Button>
                            </Popconfirm>
                            {row.status === "active" ? (
                              <Button
                                danger
                                className="m-2"
                                loading={isDeactivateSubmitting}
                                onClick={() => handleDeactiveCard(row.id)}>
                                ايقاف التفعيل
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                className="m-2"
                                loading={isDeactivateSubmitting}
                                onClick={() => handleactiveCard(row.id)}>
                                تفعيل
                              </Button>
                            )}
                          </div>
                          {editingRowId === row.id ? (
                            <div className="w-full flex items-center justify-center">
                              <Button
                                type="primary"
                                className="m-2"
                                loading={isInfoEditSubmitting}
                                onClick={() =>
                                  handleSaveClick(
                                    row.id,
                                    editedName,
                                    editedPrice,
                                    editPurchasedPrice,
                                    editSpecialPrice,
                                    row.categoryId
                                  )
                                }>
                                حفظ
                              </Button>
                              <Button
                                danger
                                type="default"
                                className="m-2"
                                onClick={() => setEditingRowId(null)}>
                                الغاء
                              </Button>
                            </div>
                          ) : (
                            <div className="w-full flex items-center justify-center">
                              <Button
                                type="primary"
                                className="m-2"
                                onClick={() =>
                                  handleEditClick(
                                    row.id,
                                    row.name,
                                    row.purchasedPrice,
                                    row.price,
                                    row.specialPrice
                                  )
                                }>
                                تعديل
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{row.countItems}</TableCell>
                    <TableCell className="w-40 flex justify-center">
                      {editingRowId === row.id ? (
                        <div className="flex justify-center">
                          <Input
                            value={editSpecialPrice}
                            onChange={(e) =>
                              setEditSpecialPrice(Number(e.target.value))
                            }
                            className="w-full text-center"
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          {formatNumberWithCommas(row.specialPrice)}
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="w-40 flex justify-center">
                      {editingRowId === row.id ? (
                        <div className="flex justify-center">
                          <Input
                            value={editedPrice}
                            onChange={(e) =>
                              setEditedPrice(Number(e.target.value))
                            }
                            className="w-full text-center"
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          {formatNumberWithCommas(row.price)}
                        </div>
                      )}
                    </TableCell>{" "}
                    <TableCell className="w-48 flex justify-center">
                      {editingRowId === row.id ? (
                        <div className="flex justify-center">
                          <Input
                            value={editPurchasedPrice}
                            onChange={(e) =>
                              setEditPurchasedPrice(e.target.value)
                            }
                            className="w-full text-center"
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          {row.purchasedPrice}
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="w-48 flex justify-center">
                      {editingRowId === row.id ? (
                        <div className="flex justify-center">
                          <Input
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                            className="w-full text-center"
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center">{row.name}</div>
                      )}
                    </TableCell>
                    <TableCell className="h-44 w-44 overflow-hidden flex justify-center items-center">
                      {editingRowId === row.id ? (
                        <div className="h-44 w-44 overflow-hidden flex justify-center items-center">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="bg-cover bg-center rounded-r-md"
                            title="اضغط لاختيار الصورة"
                          />
                        </div>
                      ) : (
                        <div className="h-44 w-44 overflow-hidden">
                          <Image
                            src={`${API_URL}/cards/download?path=${row.imageUrl}`}
                            height={"100%"}
                            width={"100%"}
                            alt=""
                          />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
          {CardsListData.length == 0 ? (
            <div className="w-full flex justify-center items-center p-4">
              <div className="flex w-full justify-center">لايوجد كروت</div>
            </div>
          ) : (
            ""
          )}
        </TableContainer>
      </div>
    </div>
  );
};
