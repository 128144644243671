import { useRoutes, RouteObject } from "react-router-dom";
import { Loading } from "../components/elements/loading";
import { useAuth } from "../lib/auth";

import { protectedSuperAdminRoutes, protectedAdminRoutes } from "./protected";
import { publicRoutes } from "./public";
import React from "react";

export const AppRoutes = () => {
  const { user, isLoading } = useAuth();

  let routes: RouteObject[] = [];

  if (!isLoading) {
    // console.log(user);

    switch (user?.role.roleName) {
      case "superadmin":
        routes = protectedSuperAdminRoutes;
        break;
      case "admin":
        routes = protectedAdminRoutes;
        break;
      default:
        routes = publicRoutes;
    }
  }
  // console.log(routes);

  const commonRoutes = [{ path: "*", element: <Loading /> }];

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
