const storagePrefix = "Bearer";

const storage = {
  getToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}_token`) as string
    );
  },
  getFirebaseToken: () => {
    try {
      return JSON.parse(
        window.localStorage.getItem(`firebase_token`) as string
      );
    } catch {
      return null;
    }
  },
  setToken: (token: string) => {
    window.localStorage.setItem(
      `${storagePrefix}_token`,
      JSON.stringify(token)
    );
  },
  setFirebaseToken: (token: string) => {
    window.localStorage.setItem(`firebase_token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}_token`);
  },
  clearFirebaseToken: () => {
    window.localStorage.removeItem(`firebase_token`);
  },
};

export default storage;
