import React, { useState, useEffect } from "react";
import { ChargeSourceMenu } from "./chargeSourceMenu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import type { PopconfirmProps } from "antd";
import { Button, Image, Input, notification, Popconfirm } from "antd";
import { API_URL } from "../../../config";
import { NotificationPlacement } from "antd/es/notification/interface";
import { Loading } from "../../../components/elements/loading";
import { getChargeSource } from "../api/getChargeSource";
import { deleteChargeSource } from "../api/deleteChargeSource";
import { EditChargeSource } from "../api/editChargeSource";
import TextArea from "antd/es/input/TextArea";
import { DataStructure, Item } from "../types/getChargeSource"; // Adjust the import path as necessary
import { DeactiveChargeSource } from "../api/deactiveChargeSource";
import { activeChargeSource } from "../api/activeChargeSource";

export const ChargeSourceTable = () => {
  const [chargeSourceData, setChargeSourceData] = useState<Item[]>([]);
  const [chargeSourceDataCount, setChargeSourceDataCount] = useState<number>();
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [chargeSourceName, setChargeSourceName] = useState<string>("");
  const [newImage, setNewImage] = useState<File | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chargeSourceDescription, setChargeSourceDescription] =
    useState<string>("");

  const [isDeactivateSubmitting, setIsDeactivateSubmitting] =
    useState<boolean>(false);

  const [isDeletingSubmitting, setIsDeletingSubmitting] =
    useState<boolean>(false);
  const [isInfoEditSubmitting, setIsInfoEditSubmitting] =
    useState<boolean>(false);
  const openEditNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم التعديل بنجاح",
      description: "تم التعديل بنجاح",
      placement,
      duration: 3,
    });
  };
  const openNotification2 = (placement: NotificationPlacement) => {
    api.success({
      message: "تم حذف مصدر الحوالة بنجاح",
      description: "تم حذف مصدر الحوالة بنجاح",
      placement,
      duration: 3,
    });
  };
  const openDeactiveNotification = (placement: NotificationPlacement) => {
    api.success({
      message: "تم الغاء تفعيل مصدر الحوالة بنجاح",
      description: "تم الغاء تفعيل مصدر الحوالة بنجاح",
      placement,
      duration: 3,
    });
  };
  const openActivateNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: "تم تفعيل مصدر الحوالة بنجاح",
      description: "تم تفعيل مصدر الحوالة بنجاح",
      placement,
      duration: 3, // Duration in seconds (default is 4.5 seconds)
    });
  };
  const handleEditClick = (
    rowId: number,
    name: string,
    description: string
  ) => {
    setEditingRowId(rowId);
    setChargeSourceName(name);
    setChargeSourceDescription(description);
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setNewImage(file);
    }
  };
  const paymentCancel: PopconfirmProps["onCancel"] = (e) => {};

  const chargeSourceDeleteConfirm: PopconfirmProps["onConfirm"] = async (
    id: any
  ) => {
    setIsDeletingSubmitting(true);
    try {
      const response = await deleteChargeSource(id);
      // console.log(response);
      if (response.status === 200) {
        openNotification2("topRight");
        fetchData();
      }
      // console.log(id);
      // Add any additional logic after successfully accepting the user charge
    } catch (error) {
      // Handle any errors that occur during the process
      console.error("Error accepting user charge:", error);
    } finally {
      setIsDeletingSubmitting(false);
    }
  };

  // const handleSaveClick = async (
  //   rowId: number,
  //   newChargeSourceName: string,
  //   chargeSourceId: string
  // ) => {
  //   try {
  //     // console.log(formData);
  //     const data = {};
  //     if (newImage) {
  //       // saveFormData["image"] = newImage;
  //     }

  //     const response = await EditChargeSource(rowId, data);

  //     // if (response) {
  //     //   openNotification("topRight");
  //     //   setEditingRowId(null);
  //     //   fetchCardsData();
  //     // }

  //     setEditingRowId(null);
  //     // fetchCardsData();
  //   } catch (error) {
  //     console.error("Error saving category data:", error);
  //   }
  // };

  const handleSaveClick = async (
    rowId: number,
    newChargeSourceName: string,
    newChargeSourceDescription: string
  ) => {
    setIsInfoEditSubmitting(true);
    try {
      const formData = new FormData();

      // Add newChargeSourceName to formData if provided
      if (newChargeSourceName) {
        formData.append("name", newChargeSourceName);
      }

      formData.append("description", newChargeSourceDescription);

      // Add newImage to formData if provided
      if (newImage) {
        formData.append("image", newImage);
      }

      // Make the API call to edit the charge source
      const response = await EditChargeSource(rowId, formData);

      // Handle the response
      if (response) {
        openEditNotification("topRight");
        setEditingRowId(null);
        fetchData();
      }
    } catch (error) {
      console.error("Error saving category data:", error);
    } finally {
      setIsInfoEditSubmitting(false);
    }
  };
  const chargeSourceDeleteCancel: PopconfirmProps["onCancel"] = (e) => {
    // console.log(e);
    // message.error("Click on No");
  };

  const fetchData = async () => {
    const response: DataStructure = await getChargeSource();

    // console.log(fakeData);
    setChargeSourceDataCount(response.count);
    setChargeSourceData(response.array);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const chargeSourceDeactiveConfirm = async (id: number) => {
    setIsDeactivateSubmitting(true);
    try {
      const response = await DeactiveChargeSource(id);
      // console.log(response);
      if (response.status === 200) {
        openDeactiveNotification("topRight");
        fetchData();
      }
      // console.log(id);
      // Add any additional logic after successfully accepting the user charge
    } catch (error) {
      // Handle any errors that occur during the process
      console.error("Error accepting user charge:", error);
    } finally {
      setIsDeactivateSubmitting(false);
    }
  };
  const chargeSourceِِActivetConfirm = async (id: number) => {
    setIsDeactivateSubmitting(true);
    try {
      const response = await activeChargeSource(id);
      // console.log(response);
      if (response.status === 200) {
        openActivateNotification("topRight");

        fetchData();
      }
      // console.log(id);
      // Add any additional logic after successfully accepting the user charge
    } catch (error) {
      // Handle any errors that occur during the process
      console.error("Error accepting user charge:", error);
    } finally {
      setIsDeactivateSubmitting(false);
    }
  };
  // console.log(`${API_URL}/charge/download?path=QGXDYW6qkCCd.webp`);
  // console.log(sortedData);

  // console.log(chargeSourceData);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <ChargeSourceMenu
        setChargeSourceData={setChargeSourceData}
        chargeSourceDataCount={chargeSourceDataCount}
        setIsLoading={setIsLoading}
      />

      <div className="flex flex-col bg-color2 mt-4 m-4">
        {contextHolder}

        <TableContainer sx={{ width: "100%" }} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">الاجراءات</TableCell>
                <TableCell align="center">الشرح</TableCell>{" "}
                <TableCell align="center">الحالة</TableCell>
                <TableCell align="center">اسم مصدر الحوالة</TableCell>
                <TableCell align="center">الصورة</TableCell>
              </TableRow>
            </TableHead>
            {chargeSourceData.length > 0 ? (
              <TableBody>
                {chargeSourceData.map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center">
                      <div className="flex flex-col">
                        <div className="flex justify-center p-2">
                          <Popconfirm
                            title="حذف مصدر الحوالة"
                            description="هل انت متاكد من حذف هذا المصدر"
                            onConfirm={() => chargeSourceDeleteConfirm(row.id)}
                            onCancel={chargeSourceDeleteCancel}
                            okText="نعم"
                            cancelText="لا"
                            disabled={isDeletingSubmitting}>
                            <Button
                              danger
                              loading={isDeletingSubmitting}
                              className="m-2">
                              حذف
                            </Button>
                          </Popconfirm>
                          {row.status === "active" ? (
                            <Button
                              loading={isDeactivateSubmitting}
                              danger
                              type="primary"
                              className="m-2"
                              onClick={() =>
                                chargeSourceDeactiveConfirm(row.id)
                              }>
                              الغاء التفعيل
                            </Button>
                          ) : (
                            <Button
                              loading={isDeactivateSubmitting}
                              type="primary"
                              className="m-2"
                              onClick={() =>
                                chargeSourceِِActivetConfirm(row.id)
                              }>
                              تفعيل
                            </Button>
                          )}
                        </div>
                        {editingRowId === row.id ? (
                          <div className="w-full flex items-center justify-center">
                            <Button
                              danger
                              type="default"
                              className="m-2"
                              onClick={() => setEditingRowId(null)}>
                              الغاء
                            </Button>
                            <Button
                              type="primary"
                              className="m-2"
                              loading={isInfoEditSubmitting}
                              onClick={() =>
                                handleSaveClick(
                                  row.id,
                                  chargeSourceName,
                                  chargeSourceDescription
                                )
                              }>
                              حفظ
                            </Button>
                          </div>
                        ) : (
                          <div className="w-full flex items-center justify-center">
                            <Button
                              type="primary"
                              className="m-2"
                              onClick={() =>
                                handleEditClick(
                                  row.id,
                                  row.name,
                                  row.description
                                )
                              }>
                              تعديل
                            </Button>
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="w-48 flex justify-center">
                      {editingRowId === row.id ? (
                        <div className="flex justify-center">
                          <TextArea
                            placeholder="شرح عن طريقة التحويل"
                            style={{ direction: "rtl", textAlign: "right" }}
                            rows={10}
                            value={chargeSourceDescription}
                            allowClear
                            onChange={(e) =>
                              setChargeSourceDescription(e.target.value)
                            }
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center text-right">
                          {row.description === null ||
                          row.description === "null" ||
                          row.description === "" ? (
                            <div>لايوجد شرح</div>
                          ) : (
                            row.description
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="w-48 flex justify-center">
                      <div className="flex justify-center ">
                        {row.status === "active" ? "فعال" : "غير فعال"}
                      </div>
                    </TableCell>
                    <TableCell className="w-48 flex justify-center">
                      {editingRowId === row.id ? (
                        <div className="flex justify-center">
                          <Input
                            value={chargeSourceName}
                            style={{ direction: "rtl", textAlign: "right" }}
                            onChange={(e) =>
                              setChargeSourceName(e.target.value)
                            }
                            className="w-full text-center"
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center ">{row.name}</div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <div className="h-full flex justify-center">
                        {editingRowId === row.id ? (
                          <div className="h-fit w-52 overflow-hidden flex justify-center items-center">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className="bg-cover bg-center rounded-r-md"
                            />
                          </div>
                        ) : (
                          <Image.PreviewGroup>
                            <div className=" w-52 overflow-hidden flex justify-center items-center">
                              <Image
                                style={{
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                                src={`${API_URL}/cards/download?path=${row.imageUrl}`}
                              />
                            </div>
                          </Image.PreviewGroup>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
          {chargeSourceData.length === 0 ? (
            <div className="w-full flex justify-center items-center p-4">
              <div className="flex w-full justify-center">
                لايوجد اي مصادر للحوالات
              </div>
            </div>
          ) : (
            ""
          )}
        </TableContainer>
      </div>
    </>
  );
};
